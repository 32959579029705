// eslint-disable-next-line


import React from 'react'
import PropTypes from 'prop-types'
import SimpleTable from '../../SimpleTable/SimpleTable'
import SimplePopUp from '../../SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../SimpleDeletePopUp/SimpleDeletePopUp'
import AccountsForm from './AccountsForm'
import SettingsSystemDaydreamOutlinedIcon from '@material-ui/icons/SettingsSystemDaydreamOutlined';
import axios from 'axios'
import AlertMessage from "../../Notify/AlertMessage";
import ProgressBar from "../../ProgressBar/ProgressBar";
import {withRouter} from 'react-router-dom'
import * as CommonFunction from "../../Custom/CommonFunctions"

export const Component = withRouter(() => {
})
import AccountsSearch from './AccountsSearch'

class Accounts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Account',
      accounts: [],
      updateAccount: {},
      openPopup: false,
      openDeletePopup: false,
      account: { status: 'active' },
      initialAccounts: {
        id: '',
        code: '',
        name: '',
        name_type: '',
        type: '',
        status: 'active'
      },
      title: '',
      searchParams: {type: '', status: ''},
      withResultsLabel: false,
      withPagination: true,
      load: false,
      tableHead: ['code', 'name', 'status', 'type'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      totalDigit: 9,
      totalResults: 0,
      zeroFill:'',
      currentPage: 1,
      totalPages: 1,
      perPage: '20',
      searchUrl: '/v1/accounts/search'
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handlePerPageChange = this.handlePerPageChange.bind(this)
    this.loadAccounts = this.loadAccounts.bind(this)
  }


  componentDidMount() {
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = {}
    const params = new URLSearchParams(this.props.location.search)
    // This is to load all params based on URLSearchParams
    params.forEach(function(value, key) {
      if (key !== 'page' && key !== 'per_page') {
        if(key === 'code') {
          searchParams[key] = value.replace(/\*/g,'')
        }else {
          searchParams[key] = value
        }
      }
    })
    if(params.get('per_page')) {
      this.setState({perPage: params.get('per_page')})
    }
    
    this.setState((prevParams) => ({ searchParams: { ...prevParams.searchParams, ...searchParams} }))
    this.loadAccounts(searchUrl)
  }

  loadAccounts(url) {
    axios({
      method: 'get',
      url: url.includes('?') ? url+'&sort_by[code]=asc': url+'?sort_by[code]=asc',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({accounts: resp.data.accounts})
      this.setState({ totalPages: resp.data.meta.total_pages, currentPage: resp.data.meta.current_page, totalResults: resp.data.meta.total_count, withResultsLabel: true })
      this.setState({load: true})
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleSubmit() {
    const item = {...this.state.account}
    item['code']=this.state.account.code+this.state.zeroFill
    item.type = this.state.account.type ? this.state.account.type.replace('Caes::', '') : this.state.account.type
    item['name_type'] = this.state.account.name + '(' +this.state.account.type +')'
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = '/v1/accounts/'
    } else {
      method = 'put'
      url = '/v1/accounts/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {

      const newData = resp.data
      this.setState({
        accounts: this.state.accounts.filter(account => account.id !== item.id)
      })
      this.setState({accounts: [...this.state.accounts, newData]})
      this.setState({openPopup: false})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        const fieldWithError = Object.keys(error.response.data.errors[0].detail.errors)[0]
        this.setState({
          isOpen: true,
          message: `${fieldWithError.charAt(0).toUpperCase() + fieldWithError.slice(1)} ${error.response.data.errors[0].detail.errors[fieldWithError][0]}`,
          type: 'error'
        })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.account.id
    axios({
      method: 'delete',
      url: '/v1/accounts/' + DeleteItemId,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        accounts: this.state.accounts.filter(item => item.id !== DeleteItemId)
      })
      this.setState({openDeletePopup: false})
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleClose() {
    this.setState({openPopup: false})
    this.setState({openDeletePopup: false})
    this.setState({error_messages: ''})
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    var zeroFill = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    if(item.code.length>0){
      if(item.code.toString().length >0) {
        for (let i = 0; i < this.state.totalDigit- item.code.length; i++) {
          zeroFill = zeroFill + '0'
        }
      }
    }else{
      for (let i = 0; i < this.state.totalDigit-1; i++) {
        zeroFill = zeroFill + '0'
      }
    }
    this.setState({zeroFill: zeroFill , updateAccount: item.account, account: item, title: textTitle})
    this.setState({openPopup: true})
  }


  handleInputChange(e) {
    var zeroFill = ''
    if(e.target.name==="code"){
      if(e.target.value.length>0){
        if(e.target.value.toString().length >0) {
          for (let i = 0; i < this.state.totalDigit- e.target.value.length; i++) {
            zeroFill = zeroFill + '0'
          }
        }
      }else{
        for (let i = 0; i < this.state.totalDigit-1; i++) {
          zeroFill = zeroFill + '0'
        }
      }
      this.setState({zeroFill: zeroFill})
    }
    this.setState({
      account: {...this.state.account,[e.target.name]: e.target.value}})
  }

  handleOpenDeletePopup(item, model) {
    this.setState({model: model})
    this.setState({account: item})
    this.setState({openDeletePopup: true})
  }

  handleDeleteClose() {
    this.setState({openDeletePopup: false})
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  getSearchParams() {
    var searchParams = []
    // This is to load all params based on searchParams state.
    Object.entries(this.state.searchParams).map(([key, value]) => {
      if (value != '' && value != undefined && value != null) {
        searchParams.push([key, value].join("="))
      }
    })

    searchParams.push(["per_page", this.state.perPage].join("="))
    return searchParams
  }

  handleSearch() {
    const { history } = this.props
    var searchParams = this.getSearchParams().join("&")
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.loadAccounts([this.state.searchUrl, searchParams].join("?"))
  }

  handleSearchInputChange(e) {
    this.setState({ searchParams: {
        ...this.state.searchParams, [e.target.name]: e.target.value } })
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') { this.handleSearch()}
  }

  clearSearch() {
    const { history } = this.props
    history.push({ search: 'per_page='+ this.state.perPage })
    this.setState({ withResultsLabel: false})
    this.loadAccounts(this.state.searchUrl+ '?per_page='+this.state.perPage)
    this.setState({searchParams: {name: '', type: '', code: '', status: ''}})
  }

  handlePageChange(event, value) {
    var searchParams = this.getSearchParams()
    searchParams.push(["page", value].join("="))
    searchParams = searchParams.join("&")

    const { history } = this.props
    const params = new URLSearchParams()

    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ currentPage: value })
    this.loadAccounts([this.state.searchUrl, searchParams].join("?"))
  }

  handlePerPageChange(e){
    var searchParams = this.getSearchParams()
    searchParams.map((value, index) => {
      if(value.includes("per_page")) {
        searchParams.splice(index, 1)
      }
    })
    searchParams.push(["per_page", e.target.value].join("="))
    searchParams = searchParams.join("&")

    const { history } = this.props
    const params = new URLSearchParams()

    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ perPage: e.target.value })
    this.loadAccounts([this.state.searchUrl, searchParams].join("?"))
  }

  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>

              <AccountsSearch
                clearSearch={this.clearSearch}
                item={this.state.searchParams}
                search={this.handleSearch}
                handleChange={this.handleSearchInputChange}
                handleKeyPress={this.handleKeyPress}
              />

              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialAccounts}
                items={this.state.accounts}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                totalPages={this.state.totalPages}
                currentPage={this.state.currentPage}
                withPagination={this.state.withPagination}
                handlePageChange={this.handlePageChange}
                withResultsLabel={this.state.withResultsLabel}
                totalResults={this.state.totalResults}
                perPage={this.state.perPage}
                handlePerPageChange={this.handlePerPageChange}
                icon={<SettingsSystemDaydreamOutlinedIcon fontSize="large"/>}
                headerTitle="Accounts"
                createUsingSimplePopup={true}
                hasDeleteButton={true}
              />

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <AccountsForm
                  error={this.state.error_messages}
                  item={this.state.account}
                  accounts={this.state.accounts}
                  zeroFill={this.state.zeroFill}
                  submit={this.handleSubmit}
                  onchange={this.handleInputChange}/>
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.account}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default withRouter(Accounts)
Accounts.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
