import React from 'react';

import Cookies from 'js-cookie';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import axios from 'axios'
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types'
import styles from './Sessions.module.css'
import ForgotPassword from "./ForgotPassword";
import UnlockInstruction from "./UnlockInstruction"
import Tooltip from '@material-ui/core/Tooltip';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import logo from '../../images/lexLogo.png'
import CompanyBG from '../../images/background-new-flip.png'

import { setupAxios } from '../../../helpers/axios';

import { setCookies } from '../../../helpers/cookies';

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      redirect: false,
      apiKey: '',
      errors: '',
      isRemember: false,
      forgot: false,
      unlock: false
    }
    this.handleLogin = this.handleLogin.bind(this)
    this.forgot = this.forgot.bind(this)
    this.unlock = this.unlock.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.saveToken = this.saveToken.bind(this)
    this.handleRemember = this.handleRemember.bind(this)
    this._handleKeyPress = this._handleKeyPress.bind(this)
  }

  componentDidMount() {
    if (localStorage.getItem('username')) {
      this.setState({user: {username: localStorage.getItem('username'), password: localStorage.getItem('password')}})
    }
  }

  forgot() {
    this.setState({forgot: true})
  }

  unlock() {
    this.setState({unlock: true})
  }

  saveToken(token) {
    localStorage.setItem('api_key', token)
  }

  handleChange(e) {
    e.preventDefault()
    this.setState({
      user: {
        ...this.state.user,
        [e.target.name]: e.target.value
      }
    })
  }

  handleRemember(event) {
    const value = event.target.checked
    this.setState({isRemember: value})
  }

  _handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleLogin()
    }
  }

  handleLogin() {
    setupAxios(this.state.user.username)

    this.props.setLoad(false)

    localStorage.setItem('username', this.state.user.username)

    axios.post('/v1/users/login',
      {username: this.state.user.username, password: this.state.user.password}
    ).then(response => {
      if(response.is_employee) {
        this.setState({errors: 'You are not authorized to access this page.'})
      } else {
        if (this.state.isRemember) {
          localStorage.setItem('password', this.state.user.password)
        }
        setCookies('token', response.data.api_key);
        const current_user = response.data
        setCookies("current_user", JSON.stringify({ name: current_user.name, email: current_user.email, id: current_user.uid,
          branch_id: current_user.branch_id, is_active: current_user.is_active, is_from_cdasia: current_user.is_from_cdasia, username: this.state.user.username  }))
        var token = Cookies.get('token')
        this.props.setToken(token)
        this.saveToken(token)
        this.setState({ apiKey: token })
        if(response.data.settings){
          if(response.data.settings.prefixes){
          setCookies('checkvoucher_prefix', response.data.settings.prefixes.check_voucher)
          setCookies('cashvoucher_prefix', response.data.settings.prefixes.cash_voucher)
          }
        }
        this.props.loadAttribute()
        this.props.setLoad(true)
      }
    }).catch(error => {
      if (error.response.status && error.response.status === 404) {
        this.setState({errors: 'Invalid Username and/or Password'})
      }
    })
  }

  render() {
    return (
      <>
        {this.state.forgot === false && this.state.unlock === false &&
        <Grid container component="main" className={styles.root}>
          <CssBaseline/>
          <Grid item xs={false} sm={4} md={7}>
            <img src={CompanyBG} alt="Company Background" className={styles.image}/>
          </Grid>
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={styles.paper}>
              <div className={styles.headerName}> {process.env.REACT_APP_TITLE_COMPANY} Enterprise System</div>
              <div>
                <img src={logo}
                     alt="Company Logo" className={styles.avatar}/>
              </div>
              <Typography component="h1" variant="h5">
                Login to your Account
              </Typography>
              <form className={styles.form} noValidate>
                <Grid container>
                  <Grid item xs>
                  </Grid>
                  <Grid item className={styles.forgotIcons}>
                    <Tooltip title="Forgot password" arrow>
                      <IconButton variant="body2" >
                        <HelpOutlineOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Unlock your Account" arrow>
                      <IconButton variant="body2"
                      >
                        <LockOpenOutlinedIcon/>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  onChange={this.handleChange}
                  value={this.state.user.username}
                  autoFocus
                  onKeyPress={this._handleKeyPress}
                />
                <TextField
                  autoComplete="off"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={this.state.user.password}
                  onChange={this.handleChange}
                  onKeyPress={this._handleKeyPress}
                />
                <div className={styles.errorText}>{this.state.errors}</div>
                <FormControlLabel
                  control={<Checkbox
                    value={this.state.isRemember}
                    name="isRemember"
                    onChange={this.handleRemember}
                    color="primary"/>}
                  label="Remember me"
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={styles.submit}
                  onClick={this.handleLogin}
                  disabled={!this.state.user.username}
                >
                  Sign In
                </Button>
              </form>
            </div>
          </Grid>
        </Grid>
        }
        {this.state.forgot === true && <ForgotPassword/>}
        {this.state.unlock === true && <UnlockInstruction/>}
      </>
    )
  }
}

export default SignIn

SignIn.propTypes = {
  setToken: PropTypes.func,
  loadAttribute: PropTypes.func,
  setLoad: PropTypes.func
}
