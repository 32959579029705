import React from 'react'
import styles from './Invoices.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  MenuItem, Grid, IconButton, Tooltip
} from '@material-ui/core'

import AddBoxIcon from '@material-ui/icons/AddBox'
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import NumberFormat from "react-number-format";
import CloseIcon from '@material-ui/icons/Close'

class InvoicesFormPopUpPopUp extends React.Component {

  render() {
    return (
      <div>
        <form>
          <Grid container spacing={1}>

            <Grid item xs={12}>
              <Autocomplete
                name="client_id"
                id="client_id"
                key="client"
                size="small"
                loading={this.props.clientsAutoCompleteLoading}
                options={(this.props.clients).sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                  return 0;
                })}
                getOptionLabel={(option) => option.name || ''}
                getOptionSelected={(option, value) => option.id === value}
                onChange={this.props.handleAutocomplete}
                onInputChange={(event, value, reason) => this.props.handleAutocompleteOnInputChange(event, value, reason)}
                fullWidth={true}
                value={this.props.selectedClient}
                closeIcon={<CloseIcon fontSize="small" id="clear_client_autocomplete" />}
                renderInput={(params) => <TextField {...params} label="Client Name" variant="outlined" required />}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={styles.addressBody}>
                <div>
                  Address :
                </div>
                <div>
                { this.props.selectedClient.company_address || this.props.selectedClient.home_address || '' }
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="off"
                variant="outlined"
                type="date"
                className={styles.textFields}
                size="small"
                id="date_of_invoice"
                name="date_of_invoice"
                fullWidth
                label="Date of Invoice"
                onChange={this.props.onchange}
                value={this.props.item.date_of_invoice}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="off"
                select
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="contact_person_id"
                fullWidth={true}
                label="Contact Person"
                onChange={this.props.onchange}
                value={this.props.item.contact_person_id}
              >

                {this.props.contactPersons.length > 0 ? (
                  this.props.contactPersons.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    return 0;
                  }).map((contact) => (
                    <MenuItem key={contact.id} value={contact.id}>{contact.name}</MenuItem>
                  ))
                ) : <MenuItem value="">No Option</MenuItem>}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="project_name"
                fullWidth="true"
                label="Project Name"
                onChange={this.props.onchange}
                value={this.props.item.project_name || ''}
                id="project_name"
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                value={this.props.branch}
                id="branch"
                className={styles.textFields}
                name="branch"
                key="branch"
                fullWidth
                size="small"
                options={(this.props.branches).sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                  return 0;
                })}
                getOptionLabel={(option) => option.name || ''}
                getOptionSelected={(option, value) => option.name === value.name}
                onChange={this.props.handleAutocomplete}
                closeIcon={<CloseIcon fontSize="small" id="clear_branch_autocomplete" />}
                renderInput={(params) => (
                  <TextField {...params} name="branch" variant="outlined" label="Branch Name" required/>
                )}
              />
            </Grid>


            <Grid item xs={6}>
              <TextField
                autoComplete="off"
                required
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="reference_no"
                fullWidth={true}
                label="Billing Invoice Number"
                onChange={this.props.onchange}
                value={this.props.item.reference_no}
                id="short_name"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="po_no"
                fullWidth="true"
                label="PO No."
                onChange={this.props.onchange}
                value={this.props.item.po_no}
                id="po_no"
              />
            </Grid>
            <Grid item xs={8}>
              <div className={styles.termsBody}>
                <TextField
                  autoComplete="off"
                  select
                  id="payment_term_id"
                  className={styles.textFields}
                  variant="outlined"
                  size="small"
                  name="payment_term_id"
                  fullWidth={true}
                  label="Terms of Payment"
                  onChange={this.props.onchange}
                  value={this.props.item.payment_term.id}
                >
                  {this.props.paymentTerms.length > 0 ? (
                    this.props.paymentTerms.sort(function (a, b) {
                      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                      return 0;
                    }).map((term) => (
                      <MenuItem key={term.id} value={term.id}>{term.name}</MenuItem>
                    ))
                  ) : <MenuItem value="">No Option</MenuItem>}
                </TextField>

                <div>
                  <Tooltip title="Add Terms of Payment" arrow>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={this.props.openTermsForm}
                      style={{padding: '14px'}}
                      name="add_paymentTerms"
                    >
                      <AddBoxIcon/>
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.termsBody}>
                <TextField
                  autoComplete="off"
                  select
                  id="cwt_type_id"
                  className={styles.textFields}
                  variant="outlined"
                  size="small"
                  name="cwt_type_id"
                  fullWidth={true}
                  label="CWT"
                  onChange={this.props.onchange}
                  value={this.props.item.cwt_type_id}
                >
                  <MenuItem value="" > Please Select </MenuItem>
                  {this.props.cwtTypes.length > 0 ? (
                    this.props.cwtTypes.sort(function (a, b) {
                      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                      return 0;
                    }).map((cwtType) => (
                      <MenuItem key={cwtType.id} value={cwtType.id}>{cwtType.name + "(" + parseFloat(cwtType.multiplier) * 100 + "%)"}</MenuItem>
                    ))
                  ) : <MenuItem value="">No Option</MenuItem>}
                </TextField>
              </div>
            </Grid>

            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                required
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="remarks"
                fullWidth="true"
                label="Remarks"
                onChange={this.props.onchange}
                value={this.props.item.remarks}
                id="remarks"
              />
            </Grid>

            {this.props.isInstallment ? (
              <>
                <Grid item xs={6}>
                  <NumberFormat
                    customInput={TextField}
                    autoComplete="off"
                    variant="outlined"
                    className={styles.textFieldsInstallment}
                    size="small"
                    name="down_payment"
                    fullWidth
                    id="down_payment"
                    label="Down Payment"
                    thousandSeparator={true}
                    onValueChange={this.props.donwPaymentChange}
                    value={parseFloat(this.props.item.down_payment).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                    decimalSeparator="."
                    fixedDecimalScale={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    autoComplete="off"
                    required
                    type="number"
                    className={styles.textFieldsInstallment}
                    variant="outlined"
                    size="small"
                    name="no_of_months"
                    id="no_of_months"
                    fullWidth="true"
                    label="Number of Month"
                    onChange={this.props.onchange}
                    value={this.props.item.no_of_months}
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberFormat
                    customInput={TextField}
                    autoComplete="off"
                    variant="outlined"
                    className={styles.textFieldsInstallment}
                    size="small"
                    name="monthly_payment"
                    fullWidth
                    label="Monthly Payment"
                    thousandSeparator={true}
                    onValueChange={this.props.amountChange}
                    value={parseFloat(this.props.item.monthly_payment).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                    decimalSeparator="."
                    fixedDecimalScale={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    autoComplete="off"
                    variant="outlined"
                    type="date"
                    className={styles.textFields}
                    size="small"
                    id="date_of_maturity"
                    name="date_of_maturity"
                    fullWidth
                    label="Due Date"
                    onChange={this.props.onchange}
                    value={this.props.item.date_of_maturity}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </>
            ) : null}

          </Grid>

        </form>

        <div className={styles.actionButton}>
          <Button
            variant="outlined"
            disabled={this.props.selectedClient === undefined}
            name="submit_invoice" color="primary"
            onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default InvoicesFormPopUpPopUp

InvoicesFormPopUpPopUp.propTypes = {
  onchange: PropTypes.func,
  handleAutocomplete: PropTypes.func,
  handleSwitch: PropTypes.func,
  clients: PropTypes.array,
  contactPersons: PropTypes.array,
  branches: PropTypes.array,
  invoiceDetails: PropTypes.array,
  item: PropTypes.object,
  branch: PropTypes.object,
  selectedClient: PropTypes.object,
  updateAccount: PropTypes.object,
  amountChange: PropTypes.func,
  openTermsForm: PropTypes.func,
  paymentTerms: PropTypes.string,
  submit: PropTypes.func,
  removeInvoiceDetailsContent: PropTypes.func,
  handleAddInvoiceDetails: PropTypes.func,
  openInvoiceDetailsForm: PropTypes.func,
  donwPaymentChange: PropTypes.func,
  isInstallment: PropTypes.bool,
  clientsAutoCompleteLoading: PropTypes.bool,
  handleAutocompleteOnInputChange: PropTypes.func,
  cwtTypes: PropTypes.array
}
