// eslint-disable-next-line


import React from 'react'
import PropTypes from 'prop-types'
import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import ProductsForm from './ProductsForm'
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import { withRouter } from 'react-router-dom'
export const Component = withRouter(() => {
})
import ProductsSearch from './ProductsSearch'
import * as CommonFunction from "../../shared/Custom/CommonFunctions"
class Products extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Product',
      products: [],
      accounts: [],
      productTypes: [],
      updateAccount: {},
      openPopup: false,
      openDeletePopup: false,
      product: {},
      initialProduct: {
        id: '',
        account_id: '',
        name: '',
        short_name: '',
        amount: '',
        unit: '',
        product_type_id: '',
        expirable: false,
        tax_exempt: false,
      },
      title: '',
      searchParams: {},
      withResultsLabel: false,
      withPagination: true,
      load: false,
      tableHead: ['Name', 'tax_exempt', 'unit', 'product_type.name', 'account.name', 'amount'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      totalResults: 0,
      perPage: '20',
      searchUrl: '/v1/products/search',
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.amountChange = this.amountChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handlePerPageChange = this.handlePerPageChange.bind(this)
    this.loadProducts = this.loadProducts.bind(this)
  }


  componentDidMount() {
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = {}
    const params = new URLSearchParams(this.props.location.search)
    // This is to load all params based on URLSearchParams
    params.forEach(function(value, key) {
      if (key !== 'page' && key !== 'per_page') {
        if(key === 'name') {
          searchParams[key] = value.replace(/\*/g,'')
        }else {
          searchParams[key] = value
        }
      }
    })
    if(params.get('per_page')) {
      this.setState({perPage: params.get('per_page')})
    }
    
    this.setState({ searchParams: searchParams })
    this.loadProducts(searchUrl)
  }

  loadProducts(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({products: resp.data.products, totalPages: resp.data.meta.total_pages, currentPage: resp.data.meta.current_page, totalResults: resp.data.meta.total_count, withResultsLabel: true})
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
    axios({
      method: 'get',
      url: '/v1/accounts',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({accounts: resp.data})
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
    axios({
      method: 'get',
      url: '/v1/product_types',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({productTypes: resp.data})
      this.setState({load: true})
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleSubmit() {
    const item = this.state.product
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = '/v1/products/'
    } else {
      method = 'put'
      url = '/v1/products/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {

      const newData = resp.data
      this.setState({
        products: this.state.products.filter(product => product.id !== item.id)
      })
      this.setState({products: [...this.state.products, newData]})
      this.setState({openPopup: false})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({error_messages: 'Name ' + error.response.data.errors.name[0]})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.product.id
    axios({
      method: 'delete',
      url: '/v1/products/' + DeleteItemId,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        products: this.state.products.filter(item => item.id !== DeleteItemId)
      })
      this.setState({openDeletePopup: false})
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleClose() {
    this.setState({openPopup: false})
    this.setState({openDeletePopup: false})
    this.setState({error_messages: ''})
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({updateAccount: item.account})
    this.setState({openPopup: true})
    this.setState({product: item})
    this.setState({title: textTitle})
  }

  handleInputChange(e) {
    this.setState({
      product: {
        ...this.state.product,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({model: model})
    this.setState({product: item})
    this.setState({openDeletePopup: true})
  }

  handleDeleteClose() {
    this.setState({openDeletePopup: false})
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  handleSwitch(event) {
    const value = event.target.checked
    this.setState({
      product: {
        ...this.state.product,
        [event.target.name]: value
      }
    })
  }

  handleAutocomplete(event, values) {
    if (values !== null) {
      this.setState({updateAccount: values})
      this.setState({
        product: {
          ...this.state.product,
          account_id: values.id
        }
      })
    }
  }

  amountChange(e) {
    this.setState({
      product: {
        ...this.state.product,
        amount: e.floatValue
      }
    })
  }

  getSearchParams() {
    var searchParams = []
    // This is to load all params based on searchParams state.
    Object.entries(this.state.searchParams).map(([key, value]) => {
      if (value != '' && value != undefined && value != null) {
        searchParams.push([key, value].join("="))
      }
    })
    searchParams.push(["per_page", this.state.perPage].join("="))
    return searchParams
  }

  handleSearch() {
    const { history } = this.props
    var searchParams = this.getSearchParams().join("&")
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.loadProducts([this.state.searchUrl, searchParams].join("?"))
  }

  handleSearchInputChange(e) {
    this.setState({ searchParams: { ...this.state.searchParams, [e.target.name]: e.target.value } })
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSearch()
    }
  }

  clearSearch() {
    const { history } = this.props
    history.push({ search: 'per_page='+ this.state.perPage })
    this.setState({ withResultsLabel: false})
    this.loadProducts(this.state.searchUrl+ '?per_page='+this.state.perPage)
    this.setState({ searchParams: { name: '', product_type_id: '' } })
  }

  handlePageChange(event, value) {
    var searchParams = this.getSearchParams()
    searchParams.push(["page", value].join("="))
    searchParams = searchParams.join("&")

    const { history } = this.props
    const params = new URLSearchParams()

    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ currentPage: value })
    this.loadProducts([this.state.searchUrl, searchParams].join("?"))
  }

  handlePerPageChange(e){
    var searchParams = this.getSearchParams()
    searchParams.map((value, index) => {
      if(value.includes("per_page")) {
        searchParams.splice(index, 1)
      }
    })
    searchParams.push(["per_page", e.target.value].join("="))
    searchParams = searchParams.join("&")

    const { history } = this.props
    const params = new URLSearchParams()

    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ perPage: e.target.value })
    this.loadProducts([this.state.searchUrl, searchParams].join("?"))
  }

  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>

              <ProductsSearch
                clearSearch={this.clearSearch}
                item={this.state.searchParams}
                search={this.handleSearch}
                handleChange={this.handleSearchInputChange}
                handleKeyPress={this.handleKeyPress}
                productTypes={this.state.productTypes}
              />

              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialProduct}
                items={this.state.products}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                totalPages={this.state.totalPages}
                currentPage={this.state.currentPage}
                withPagination={this.state.withPagination}
                handlePageChange={this.handlePageChange}
                withResultsLabel={this.state.withResultsLabel}
                totalResults={this.state.totalResults}
                perPage={this.state.perPage}
                handlePerPageChange={this.handlePerPageChange}
                icon={<WorkOutlineIcon fontSize="large"/>}
                headerTitle="Products"
                defaultModelRoute="products"
                createUsingSimplePopup={true}
                hasDeleteButton={true}
              />

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <ProductsForm
                  error={this.state.error_messages}
                  item={this.state.product}
                  accounts={this.state.accounts}
                  updateAccount={this.state.updateAccount}
                  productTypes={this.state.productTypes}
                  handleSwitch={this.handleSwitch}
                  handleAutocomplete={this.handleAutocomplete}
                  amountChange={this.amountChange}
                  submit={this.handleSubmit}
                  onchange={this.handleInputChange}/>
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.product}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default  withRouter(Products)
Products.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
