import React from 'react'
import PropTypes from 'prop-types'
import styles from './GeneralLedgers.module.css'
import Scroll from './../../Scroll/Scroll'
import {
  Table, TableHead, TableBody, TableCell, TableRow, MenuItem, TextField,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Link } from 'react-router-dom'
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CompanyHeader from '../../CompanyHeader/CompanyHeader'
import moment from 'moment'

class GeneralLedgersTable extends React.Component {
  getBalance(journals, idx, balance) {
    var total = parseFloat(balance)
    if (journals.length > 0) {
      journals.map((journal, index) => {
        if (index <= idx) {
          total = total + parseFloat(journal["amount_debit"]) - parseFloat(journal["amount_credit"])
        }
      })
    }
    total ? (
      total > 0 ? (
        total = parseFloat(total).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      ) : (
        total = " (" + parseFloat(total * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      )
    ) : total = "0.00"
    return total
  }
  getTotalBalance(journals, balance) {
    var total = parseFloat(balance)
    if (journals.length > 0) {
      journals.map((journal) => {
        total = total + parseFloat(journal["amount_debit"]) - parseFloat(journal["amount_credit"])
      })
    }
    total ? (
      total > 0 ? (
        total = parseFloat(total).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      ) : (
        total = " (" + parseFloat(total * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      )
    ) : total = "0.00"
    return total
  }
  getTotalDebit(journals) {
    var total = 0
    if (journals.length > 0) {
      journals.map((journal) => {
        total = total + parseFloat(journal["amount_debit"])
      })
    }
    total ? (
      total > 0 ? (
        total = parseFloat(total).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      ) : (
        total = " (" + parseFloat(total * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      )
    ) : total = "0.00"
    return total
  }
  getTotalCredit(journals) {
    var total = 0
    if (journals.length > 0) {
      journals.map((journal) => {
        total = total + parseFloat(journal["amount_credit"])
      })
    }
    total ? (
      total > 0 ? (
        total = parseFloat(total).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      ) : (
        total = " (" + parseFloat(total * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      )
    ) : total = "0.00"
    return total
  }

  render() {
    return (
      <div>
        <CompanyHeader forPrint={this.props.forPrint} />
        <div className={styles.checkDetailsHeader}>
          <div>
            <h2 className={styles.detailList}>
              {this.props.forPrint ? null : this.props.icon} General Ledger
            </h2>
          </div>

          <div className={this.props.forPrint ? styles.noDisplay : styles.actionButton}>
            <TextField
              select
              size="small"
              label="Sort Journal Entries by"
              value={this.props.withSort}
              onChange={this.props.toggleCheckbox}
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              style={{ width: '220px' }}
            >
              <MenuItem value={true}>Account Type</MenuItem>
              <MenuItem value={false}>Date</MenuItem>
            </TextField>
            {this.props.urlPrint.export_excel_url !== undefined ? (
              <Tooltip title="Close Journals" arrow>
                <IconButton color="secondary" onClick={this.props.openJournalsForClose}>
                  <DoneAllIcon />
                </IconButton>
              </Tooltip>
            ) : null}
            {this.props.urlPrint.export_excel_url !== undefined ? (
              <Tooltip title={`Print General Ledger`} arrow>
                {this.props.print}
              </Tooltip>
            ) : null}
            {
              this.props.withXlsxDownload && this.props.items.length
              && !this.props.forPrint ?
              <Tooltip 
                title="Download to .xlsx">
                <IconButton
                  color="primary" 
                  aria-label='download'
                  style={{ pointerEvents: 'auto' }}
                  onClick={this.props.handleXlsxDownloadClick}
                >
                  <CloudDownloadIcon fontSize='small'/>
                </IconButton>
              </Tooltip> :
              null
            }
          </div>
        </div>
        <Table className={this.props.forPrint ? styles.table : null} size="small" stickyHeader={false}>
          <TableHead >
            <TableRow >
              <TableCell className={styles.cell}>
                <b> Account Code</b>
              </TableCell>
              <TableCell className={styles.cell}>
                <b>Account Title</b>
              </TableCell>
              <TableCell className={styles.cell}>
                <b>Description</b>
              </TableCell>
              <TableCell className={styles.date}>
                <b>Date</b>
              </TableCell>
              <TableCell className={styles.cell}>
                <b>Reference No</b>
              </TableCell>
              <TableCell className={styles.cell}>
                <b>Debit</b>
              </TableCell>
              <TableCell className={styles.cell}>
                <b>Credit</b>
              </TableCell>
              <TableCell className={styles.cell}>
                <b>Balance</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              this.props.items.length > 0 ? (
                this.props.items.sort(function (a, b) {
                  if (a.code.toLowerCase() < b.code.toLowerCase()) return -1;
                  if (a.code.toLowerCase() > b.code.toLowerCase()) return 1;
                  return 0;
                }).map((item, idx) => (
                  <>
                    <TableRow key={idx}>
                      <TableCell className={styles.cell}>
                        {item.code}
                      </TableCell>
                      <TableCell className={styles.cell}>
                        {item.name}
                      </TableCell>
                      <TableCell className={styles.cell} colSpan={4}>
                        <span>Beginning Balance</span>
                      </TableCell>
                      <TableCell className={styles.cell}>
                      </TableCell>
                      <TableCell className={styles.amount}>
                        {item.account_balance ? ((item.account_balance > 0 ? (item.account_balance) : ("(" + (item.account_balance * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")")).toLocaleString(navigator.language, { minimumFractionDigits: 2 })) : ("0.00")}
                      </TableCell>
                    </TableRow>
                    {
                      item.journal_entries !== undefined && item.journal_entries.length > 0 ? (
                        <>
                          {
                            this.props.withSort ?
                              ['amount_credit', 'amount_debit'].sort(function (a, b) {
                                return new Date(b.date_of_journal) - new Date(a.date_of_journal);
                              }).map((key) => (
                                item.journal_entries.map((journal, idx) => (
                                  journal[key] === "0.0" &&
                                  <TableRow key={idx}>
                                    <TableCell className={styles.cell}>
                                    </TableCell>
                                    <TableCell className={styles.cell}>
                                    </TableCell>
                                    <TableCell className={styles.description}>
                                      {journal.description}
                                    </TableCell>
                                    <TableCell className={styles.date}>
                                      {moment(journal.date_of_journal).format("MM/DD/YY")}
                                    </TableCell>
                                    <TableCell className={styles.reference}>
                                      <Link to={{ pathname: journal.resource_url ? (journal.resource_url) : "/" }} target="_blank" >  {journal.prefix + journal.reference_no}</Link>
                                    </TableCell>
                                    <TableCell className={styles.amount}>
                                      {journal.amount_debit ? (parseFloat(journal.amount_debit).toLocaleString(navigator.language, { minimumFractionDigits: 2 })) : ("0.00")}
                                    </TableCell>
                                    <TableCell className={styles.amount}>
                                      {journal.amount_credit ? (parseFloat(journal.amount_credit).toLocaleString(navigator.language, { minimumFractionDigits: 2 })) : ("0.00")}
                                    </TableCell>

                                    <TableCell className={styles.amount}>
                                      {this.getBalance(item.journal_entries, idx, item.account_balance)}
                                    </TableCell>
                                  </TableRow>
                                ))))
                              :
                              item.journal_entries.sort(function (a, b) {
                                return new Date(a.date_of_journal) - new Date(b.date_of_journal);
                              }).map((journal, idx) => (
                                <TableRow key={idx}>
                                  <TableCell className={styles.cell}>
                                  </TableCell>
                                  <TableCell className={styles.cell}>
                                  </TableCell>
                                  <TableCell className={styles.description}>
                                    {journal.description}
                                  </TableCell>
                                  <TableCell className={styles.date}>
                                    {moment(journal.date_of_journal).format("MM/DD/YY")}
                                  </TableCell>
                                  <TableCell className={styles.reference}>
                                    <Link to={{ pathname: journal.resource_url ? (journal.resource_url) : "/" }} target="_blank" >  {journal.prefix + journal.reference_no}</Link>
                                  </TableCell>
                                  <TableCell className={styles.amount}>
                                    {journal.amount_debit ? (parseFloat(journal.amount_debit).toLocaleString(navigator.language, { minimumFractionDigits: 2 })) : ("0.00")}
                                  </TableCell>
                                  <TableCell className={styles.amount}>
                                    {journal.amount_credit ? (parseFloat(journal.amount_credit).toLocaleString(navigator.language, { minimumFractionDigits: 2 })) : ("0.00")}
                                  </TableCell>

                                  <TableCell className={styles.amount}>
                                    {this.getBalance(item.journal_entries, idx, item.account_balance)}
                                  </TableCell>
                                </TableRow>
                              ))
                          }
                          <TableRow key={idx}>
                            <TableCell className={styles.cell}>
                            </TableCell>
                            <TableCell className={styles.cell}>
                            </TableCell>
                            <TableCell className={styles.cell}>
                            </TableCell>
                            <TableCell className={styles.cell}>
                            </TableCell>
                            <TableCell className={styles.totalcell}>
                              <b>Total</b>
                            </TableCell>
                            <TableCell className={styles.totalcellAmount}>
                              <b>{this.getTotalDebit(item.journal_entries)}</b>
                            </TableCell>
                            <TableCell className={styles.totalcellAmount}>
                              <b>{this.getTotalCredit(item.journal_entries)}</b>
                            </TableCell>
                            <TableCell className={styles.totalcellAmount}>
                              <b>{this.getTotalBalance(item.journal_entries, item.account_balance)}</b>
                            </TableCell>
                          </TableRow>
                        </>
                      ) : null
                    }
                  </>
                ))

              ) : <TableRow>
                <TableCell colSpan={8} align="center">No Records Found</TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
        <Scroll />
      </div >
    )
  }
}

export default GeneralLedgersTable

GeneralLedgersTable.propTypes = {
  classes: PropTypes.object,
  items: PropTypes.array,
  urlPrint: PropTypes.object,
  model: PropTypes.string,
  icon: PropTypes.object,
  openJournalsForClose: PropTypes.func,
  forPrint: PropTypes.bool,
  print: PropTypes.object,
  withSort: PropTypes.bool,
  toggleCheckbox: PropTypes.func,
  withXlsxDownload: PropTypes.bool,
  handleXlsxDownloadClick: PropTypes.func
}
