// eslint-disable-next-line


import React from 'react'
import PropTypes from 'prop-types'
import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import axios from 'axios'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import {withRouter} from 'react-router-dom'

export const Component = withRouter(() => {
})
import InvoicesSearch from './InvoicesSearch'
import AlertMessage from "../../shared/Notify/AlertMessage";
import * as CommonFunction from "../../shared/Custom/CommonFunctions"

class Invoices extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Invoice',
      invoices: [],
      accounts: [],
      clients: [],
      branches: [],
      updateAccount: {},
      openPopup: false,
      openDeletePopup: false,
      invoice: {},
      initialInvoice: {
        id: '',
        account_id: '',
        name: '',
        short_name: '',
        amount: '',
        unit: '',
        invoice_type_id: '',
        expirable: false,
        tax_exempt: false,
      },
      title: '',
      searchParams: {},
      withResultsLabel: false,
      withPagination: true,
      clickableTableRow: true,
      load: false,
      tableHead: ['date_of_invoice', 'reference_no', 'client.name', 'payment_term.name', 'payment_status', 'total', 'status'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      totalPages: 1,
      currentPage: 1,
      searchUrl: '/v1/invoices/search',
      type: '',
      totalResults: 0,
      perPage: '20'
    }
    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleSearchAutocomplete = this.handleSearchAutocomplete.bind(this)
    this.handleRefInputChange = this.handleRefInputChange.bind(this)
    this.handlePerPageChange = this.handlePerPageChange.bind(this)
  }

  componentDidMount() {
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = {}
    const params = new URLSearchParams(this.props.location.search)
    // This is to load all params based on URLSearchParams
    params.forEach(function(value, key) {
      if (key !== 'page' && key !== 'per_page') {
        if(key === 'reference_no') {
          searchParams[key] = value.replace(/\*/g,'')
        }else {
        searchParams[key] = value
        }
      }
    })
    if(params.get('per_page')) {
      this.setState({perPage: params.get('per_page')})
    }
    if (searchParams.branch_id !== null && searchParams.branch_id !== undefined && searchParams.branch_id !== '') {
      axios({
        method: 'get',
        url: '/v1/branches/' + searchParams.branch_id,
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ searchParams: { ...this.state.searchParams, branch_id: resp.data } })
      })
    }
  
    this.setState({ searchParams: searchParams })
    this.loadInvoices(searchUrl)
  }

  loadInvoices(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({invoices: resp.data.invoices, totalPages: resp.data.meta.total_pages, currentPage: resp.data.meta.current_page, totalResults: resp.data.meta.total_count, withResultsLabel: true })
      axios({
        method: 'get',
        url: '/v1/accounts',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({accounts: resp.data})
        axios({
          method: 'get',
          url: '/v1/branches',
          headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
        }).then(resp => {
          this.setState({branches: resp.data})
          this.setState({load: true})
        }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
      }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
    }).catch(error => {
      this.setState({
        isOpen: true,
        message: error.response.status.toString() +  error.response.data.errors[0].detail,
        type: 'error'
      })
    })
  }


  handleSwitch(event) {
    const value = event.target.checked
    this.setState({
      invoice: {
        ...this.state.invoice,
        [event.target.name]: value
      }
    })
  }

  handleSearch() {
    const { history } = this.props
    var searchParams = this.getSearchParams().join("&")
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.loadInvoices([this.state.searchUrl, searchParams].join("?"))
  }

  getSearchParams() {
    var searchParams = []
    Object.entries(this.state.searchParams).map(([key, value]) => {
      if (key!=='per_page' &&value != '' && value != undefined && value != null) {
        if(key === "branch_id") {
          searchParams.push([key, value.id].join("="))
        }else {
          searchParams.push([key, value].join("="))
        }
      }
    })
    searchParams.push('per_page='+this.state.perPage)
    return searchParams
  }

  handleRefInputChange(e) {
    this.setState({
      searchParams: {
        ...this.state.searchParams,
        [e.target.name]: e.target.value
      }
    })
  }

  handleSearchInputChange(e) {
    var dateStart = new Date(e.target.value).toLocaleDateString('fr-Ca')
    var lastDay = new Date(new Date(dateStart).getFullYear(), new Date(dateStart).getMonth() + 1, 0).toLocaleDateString('fr-Ca')
    if(e.target.name === "starts_on_from") {
    this.setState({ searchParams: {...this.state.searchParams, [e.target.name]: e.target.value, starts_on_to: lastDay }})
    } else {
      this.setState({ searchParams: {...this.state.searchParams, [e.target.name]: e.target.value }})
      }
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSearch()
    }
  }

  clearSearch() {
    const {history} = this.props
    history.push({ search: 'per_page='+ this.state.perPage })
    this.setState({withResultsLabel: false})
    this.loadInvoices('/v1/invoices/search'+ '?per_page='+this.state.perPage )
    this.setState({ searchParams: { status: '', client_name: '', reference_no: '', starts_on_from: '', starts_on_to: '', branch_id: ''} })
  }


  handlePageChange(value) {
    var searchParams = this.getSearchParams()
    searchParams.push(["page", value].join("="))
    searchParams = searchParams.join("&")

    const { history } = this.props
    const params = new URLSearchParams()

    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ currentPage: value })
    this.loadInvoices([this.state.searchUrl, searchParams].join("?"))
  }

  handleSearchAutocomplete(event, values) {
    if (values !== null) {
      this.setState({ searchParams: { ...this.state.searchParams, branch_id: values } })
    }
  }

  handlePerPageChange(e){
    var searchParams = this.getSearchParams()
    searchParams.map((value, index) => {
      if(value.includes("per_page")) {
        searchParams.splice(index, 1)
      }
    })
    searchParams.push(["per_page", e.target.value].join("="))
    searchParams = searchParams.join("&")

    const { history } = this.props
    const params = new URLSearchParams()

    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ perPage: e.target.value })
    this.loadInvoices([this.state.searchUrl, searchParams].join("?"))
  }


  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>
              <InvoicesSearch
                clearSearch={this.clearSearch}
                item={this.state.searchParams}
                search={this.handleSearch}
                handleChange={this.handleSearchInputChange}
                handleSearchAutocomplete={this.handleSearchAutocomplete}
                handleKeyPress={this.handleKeyPress}
                handleRefInputChange={this.handleRefInputChange}
                clients={this.state.clients}
                branches={this.state.branches}
              />

              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialInvoice}
                clickableTableRow={this.state.clickableTableRow}
                items={this.state.invoices}
                model={this.state.model}
                headers={this.state.tableHead}
                currentPage={this.state.currentPage}
                totalPages={this.state.totalPages}
                withPagination={this.state.withPagination}
                withResultsLabel={this.state.withResultsLabel}
                totalResults={this.state.totalResults}
                perPage={this.state.perPage}
                handlePageChange={this.handlePageChange}
                handlePerPageChange={this.handlePerPageChange}
                icon={<DescriptionOutlinedIcon fontSize="large"/>}
                headerTitle="Invoices"
                defaultNewRoutesPath="/invoices/new"
                defaultModelRoute="/invoices/"
                createUsingSimplePopup={false}
              />
            </div>
          ) : (<> <ProgressBar model={this.state.model}/>
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              /></>
          )}
      </>
    )
  }
}

export default withRouter(Invoices)
Invoices.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
