import React from 'react'

import PropTypes from 'prop-types'
import moment from 'moment';
import 'moment-timezone';
import styles from './../../Invoices/Invoices.module.css'

import {
  Table, TableHead, TableBody, TableCell, TableRow
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import PrintIcon from '@material-ui/icons/Print';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

class JournalEntriesDetailTab extends React.Component {
  getTotalDebit () {
    var totalDebit = 0
    if(this.props.journalEntries.length>0){
      this.props.journalEntries.map((content)=>{
        totalDebit = parseFloat(totalDebit) + parseFloat(content.amount_debit)
      })
    }
    return totalDebit
  }

  getTotalCredit () {
    var totalCredit = 0
    if(this.props.journalEntries.length>0){
      this.props.journalEntries.map((content)=>{
        totalCredit = parseFloat(totalCredit) + parseFloat(content.amount_credit)
      })
    }
    return totalCredit
  }
  render() {
    return (
      <div>
        <div className={styles.checkDetailsHeader}>
          <div>
            {
              this.props.journal.can_unpost ? (
                <Tooltip title="Unpost Journal">
                  <IconButton
                    onClick={() => this.props.handleOpenPostAndUnpostSimplePopUp({ openUnpostReceiptJournalPopup: true })}
                  >
                    <CancelOutlinedIcon/>
                  </IconButton>
                </Tooltip>
              ) : null
            }

            {
              this.props.journal.can_post ? (
                <Tooltip title="Post Journal">
                  <IconButton
                    onClick={() => this.props.handleOpenPostAndUnpostSimplePopUp({ openPostReceiptJournalPopup: true })}
                  >
                    <CheckCircleOutlineOutlinedIcon/>
                  </IconButton>
                </Tooltip>
              ) : null
            }
            <Tooltip title="Print Journal" arrow>
              <IconButton>
                <a href={ Object.entries(this.props.invoice).length > 0 ? `/invoices/${this.props.invoice.id}/journal.pdf` : `/receipts/${this.props.receipt.id}/journal.pdf` } target="_blank"
                   rel="noopener noreferrer">
                  <PrintIcon/>
                </a>
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <div className={styles.journalBody}>
          <div  className={styles.journalList}>
            <div><b>Reference number: </b></div>
            <div name='journal_reference_number'>{this.props.journal.reference_no? this.props.journal.reference_no: ''}</div>
          </div>
          <div className={styles.journalList}>
            <div><b>Date of Journal :</b></div>
            <div name='date_of_journal'>{moment(this.props.journal.date_of_journal).format("MM/DD/yyyy")}</div>
          </div>
        </div>

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
               <b> Account Code</b>
              </TableCell>
              <TableCell>
                <b>Account Title</b>
              </TableCell>
              <TableCell align="right">
                <b>Debit</b>
              </TableCell>
              <TableCell align="right">
                <b>Credit</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.props.journalEntries.sort(function (a, b) {
              if (a.account.code.toLowerCase() < b.account.code.toLowerCase()) return -1;
              if (a.account.code.toLowerCase() > b.account.code.toLowerCase()) return 1;
              return 0;
            }).map((journal) => (
                journal.amount_credit==="0.0" &&
            <TableRow  key={journal.id}>
              <TableCell>
                {journal.account.code}
              </TableCell>

              <TableCell>
                {journal.account.name}
              </TableCell>
              <TableCell align="right">
                {parseFloat(journal.amount_debit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
              </TableCell>
              <TableCell align="right">
                {parseFloat(journal.amount_credit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
              </TableCell>

            </TableRow>
            ))}
            {this.props.journalEntries.sort(function (a, b) {
              if (a.account.code.toLowerCase() < b.account.code.toLowerCase()) return -1;
              if (a.account.code.toLowerCase() > b.account.code.toLowerCase()) return 1;
              return 0;
            }).map((journal) => (
              journal.amount_credit!=="0.0"&&
              <TableRow  key={journal.id}>
                <TableCell>
                  {journal.account.code}
                </TableCell>
                <TableCell>
                  {journal.account.name}
                </TableCell>
                <TableCell align="right">
                  {parseFloat(journal.amount_debit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                </TableCell>
                <TableCell align="right">
                  {parseFloat(journal.amount_credit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                </TableCell>

              </TableRow>
            ))}
            <TableRow>
            <TableCell></TableCell>
            <TableCell align="right"><b> Total :</b></TableCell>
            <TableCell align="right"><b>{this.getTotalDebit().toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b></TableCell>
            <TableCell align="right"><b>{this.getTotalCredit().toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b></TableCell>
          </TableRow>

          </TableBody>
        </Table>
      </div>
    )
  }
}

export default JournalEntriesDetailTab

JournalEntriesDetailTab.propTypes = {
  receipt: PropTypes.object,
  journal: PropTypes.object,
  invoice: PropTypes.object,
  journalEntries: PropTypes.array,
  handleOpenPostAndUnpostSimplePopUp: PropTypes.func
}
