import React from 'react'
import styles from './Products.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  MenuItem,
  Grid
} from '@material-ui/core'

import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import NumberFormat from 'react-number-format'

class ProductsForm extends React.Component {
  render() {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              required
              
              variant="outlined"
              size="small"
              name="name"
              fullWidth="true"
              label="Name"
              onChange={this.props.onchange}
              value={this.props.item.name}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              
              value={this.props.updateAccount}
              id="account_id"
              size="small"
              fullWidth
              name="account_id"
              options={this.props.accounts.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              })}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleAutocomplete}
              renderInput={(params) => (
                <TextField {...params} name="user_ids" variant="outlined" label="Account Name" required />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <TextField
              autoComplete="off"
              
              variant="outlined"
              size="small"
              name="short_name"
              fullWidth="true"
              label="Short Name"
              onChange={this.props.onchange}
              value={this.props.item.short_name}
              id="short_name"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <FormControlLabel
              control={<Switch
                checked={this.props.item.expirable}
                onChange={this.props.handleSwitch}
                name="expirable" />}
              label="Expirable ?"
              labelPlacement="bottom"
            />
            <FormControlLabel
              control={<Switch
                checked={this.props.item.tax_exempt}
                onChange={this.props.handleSwitch}
                name="tax_exempt" />}
              label="Tax Exempted ?"
              labelPlacement="bottom"
              aria-setsize="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <TextField
              autoComplete="off"
              select
              
              variant="outlined"
              size="small"
              name="unit"
              fullWidth={true}
              label="Unit"
              onChange={this.props.onchange}
              value={this.props.item.unit}
            >
              <MenuItem value="License">License</MenuItem>
              <MenuItem value="Lot">Lot</MenuItem>
              <MenuItem value="Set">Set</MenuItem>
              <MenuItem value="Subscription">Subscription</MenuItem>
            </TextField>
          </Grid>
          
          <Grid item xs={12} sm={6} lg={6}>
            <NumberFormat
              customInput={TextField}
              autoComplete="off"
              variant="outlined"
              
              size="small"
              name="amount"
              fullWidth
              label="Amount"
              thousandSeparator={true}
              onValueChange={this.props.amountChange}
              value={parseFloat(this.props.item.amount).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
              decimalSeparator="."
              fixedDecimalScale={true}
              prefix={'₱'}
            />
          </Grid>
        </Grid>

        <div className={styles.actionButton}>
          <Button variant="outlined"
            disabled={!this.props.item.name && !this.props.item.rate && !this.props.item.account_id && !this.props.item.registry_type}
            id="product-submit" color="primary"
            onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default ProductsForm

ProductsForm.propTypes = {
  onchange: PropTypes.func,
  handleAutocomplete: PropTypes.func,
  handleSwitch: PropTypes.func,
  accounts: PropTypes.array,
  productTypes: PropTypes.array,
  item: PropTypes.object,
  updateAccount: PropTypes.object,
  amountChange: PropTypes.func,
  submit: PropTypes.func,
}
