// eslint-disable-next-line

import React from 'react'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import styles from './Clients.module.css'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import AttachmentDetailTab from "./Attachments/AttachmentDetailTab";
import ContactPersonDetailTab from "./ClientsTab/ContactPersonDetailTab";
import EmailAddresses from "./EmailAddress/EmailAddresses";
import ContactNumbers from "./ContactNumber/ContactNumbers";
import NestedForm from "./NestedForm";
import ContactPersonForm from "./ClientsTab/ContactPersonForm";
import AttachmentForm from "../Clients/Attachments/AttachmentForm"
import SimpleTable from "../../shared/SimpleTable/SimpleTable";
import * as CommonFunction from "../../shared/Custom/CommonFunctions"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Tooltip from '@material-ui/core/Tooltip'
import AuditLog from '../../shared/AuditLog/AuditLog'
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

class ClientsShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Client',
      value: 0,
      clients: [],
      clientContents: [],
      contactPersons: [],
      contactNumbers: [],
      emailAddresses: [],
      attachments: [],
      invoices: [],
      receipts: [],
      deliveries: [],
      nestedModel: '',
      current_user: JSON.parse(localStorage.getItem("current_user")),
      openPopup: false,
      openAttachmentPopup: false,
      cancelCheckVoucherPopup: false,
      activeCheckVoucherPopup: false,
      activeJournalPopup: false,
      cancelJournalPopup: false,
      openDeletePopup: false,
      redirectToIndex: false,
      openNestedForm: false,
      openDeleteNestedPopup: false,
      openContactPerson: false,
      openDeleteDocumentPopup: false,
      client: {},
      nestedItem: {},
      contactItem: {},
      initialNested: { id: '', content: '', category: 0 },
      initialCheckVoucher: { id: '', name: '' },
      attachment: { title: '', file: '', description: '', category_name: '' },
      emailAddress: { content: '', category: 0 },
      contactNumber: { content: '', category: 0 },
      contactPerson: {},
      nestedContactNumbers: [{ content: '', category: 0 }],
      nestedEmailAddresses: [{ content: '', category: 0 }],
      initialContactPerson: { id: '', name: '', designation: '', department: '', remarks: '' },
      title: '',
      load: false,
      tableHead: ['Name', 'Actions'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      isOpen: false,
      message: '',
      type: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.openAttachment = this.openAttachment.bind(this)
    this.handleAttacthmentSubmit = this.handleAttacthmentSubmit.bind(this)
    this.handleAttactmentInputChange = this.handleAttactmentInputChange.bind(this)
    this.handleCreateOrUpdateNested = this.handleCreateOrUpdateNested.bind(this)
    this.handleNestedChange = this.handleNestedChange.bind(this)
    this.submitNested = this.submitNested.bind(this)
    this.handleOpenDelete = this.handleOpenDelete.bind(this)
    this.handleNestedDeleteItem = this.handleNestedDeleteItem.bind(this)
    this.handleContactPerson = this.handleContactPerson.bind(this)
    this.handleContactPersonChange = this.handleContactPersonChange.bind(this)
    this.handleContactNumberChange = this.handleContactNumberChange.bind(this)
    this.removeContactNumber = this.removeContactNumber.bind(this)
    this.addContactNumber = this.addContactNumber.bind(this)
    this.handleNumberChange = this.handleNumberChange.bind(this)
    this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this)
    this.addEmailAddress = this.addEmailAddress.bind(this)
    this.removeEmail = this.removeEmail.bind(this)
    this.submitContact = this.submitContact.bind(this)
  }

  componentDidMount() {

    this.loadClientInformation('/v1/clients/' + this.props.match.params.id)
  }

  loadClientInformation(url) {
    const id = this.props.match.params.id
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ client: resp.data, clientContents: resp.data })
      this.handleLoadData('/v1/clients/' + id + '/email_addresses', "emailAddresses")
      this.handleLoadData('/v1/clients/' + id + '/contact_persons', "contactPersons")
      this.handleLoadData('/v1/clients/' + id + '/contact_numbers', "contactNumbers")
      this.handleLoadData('/v1/clients/' + id + '/invoices', "invoices", true)
      this.handleLoadData('/v1/clients/' + id + '/receipts', "receipts", true)
      this.handleLoadData('/v1/clients/' + id + '/deliveries', "deliveries", true)
      this.handleLoadData('/v1/clients/' + id + '/attachments', "attachments")
      this.setState({ load: true })
    })
  }

  handleLoadData(url, dataVariable, sortByDate = false) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      if (sortByDate) {
        if (dataVariable === "invoices") {
          this.setState({
            [dataVariable]: resp.data.sort(function (a, b) {
              if (a.date_of_invoice > b.date_of_invoice) return -1;
              if (a.date_of_invoice < b.date_of_invoice) return 1;
              return 0;
            })
          })
        } else if (dataVariable === "deliveries") {
          this.setState({
            [dataVariable]: resp.data.sort(function (a, b) {
              if (a.date_of_delivery > b.date_of_delivery) return -1;
              if (a.date_of_delivery < b.date_of_delivery) return 1;
              return 0;
            })
          })
        } else {
          this.setState({
            [dataVariable]: resp.data.sort(function (a, b) {
              if (a.date_of_receipt > b.date_of_receipt) return -1;
              if (a.date_of_receipt < b.date_of_receipt) return 1;
              return 0;
            })
          })
        }
      } else {
        this.setState({ [dataVariable]: resp.data })
      }
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleSubmit() {
    const item = this.state.client
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = '/v1/clients/'
    } else {
      method = 'put'
      url = '/v1/clients/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      this.setState({
        clients: this.state.clients.filter(client => client.id !== item.id)
      })
      this.setState({ clients: [...this.state.clients, newData] })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({ openPopup: false })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.client.id
    axios({
      method: 'delete',
      url: '/v1/clients/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        clients: this.state.clients.filter(item => item.id !== DeleteItemId)
      })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
      this.setState({ openDeletePopup: false })
      this.setState({ redirectToIndex: true })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({
          isOpen: true,
          message: 'Client ' + error.response.data.errors.base[0].toLowerCase(),
          type: 'error'
        })
      }
      this.setState({ openDeletePopup: false })
    })
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ error_messages: '' })
    this.setState({ openAttachmentPopup: false })
    this.setState({ openNestedForm: false })
    this.setState({ openDeleteNestedPopup: false })
    this.setState({ openContactPerson: false })
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({ openPopup: true })
    this.setState({ client: item })
    this.setState({ title: textTitle })
  }

  handleInputChange(e) {
    this.setState({
      client: {
        ...this.state.client,
        [e.target.name]: e.target.value
      }
    })
  }

  handleChange(event, newValue) {
    this.setState({ value: newValue })
  }

  handleOpenDeletePopup(model) {
    this.setState({ model: model })
    this.setState({ client: this.state.client })
    this.setState({ openDeletePopup: true })
  }

  openAttachment() {
    this.setState({ openAttachmentPopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      isOpen: false
    })
  }

  onFileChange(e) {
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      attachment: {
        ...this.state.attachment,
        file: e.target.files[0],
        title: e.target.files[0].name
      }
    })
  }

  handleAttacthmentSubmit() {
    const id = this.props.match.params.id
    const item = this.state.attachment
    const file = item.file
    const title = item.file.name
    const description = item.description
    const category_name = item.category_name
    const formdata = new FormData()
    formdata.append('file', file)
    formdata.append('title', title)
    formdata.append('description', description)
    formdata.append('category_name', category_name)
    axios({
      method: 'post',
      url: '/v1/clients/' + id + '/attachments',
      data: (formdata),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      this.setState({
        attachments: this.state.attachments.filter(attach => attach.id !== item.id)
      })
      this.setState({ attachments: [...this.state.attachments, newData] })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({ openAttachmentPopup: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleAttactmentInputChange(e) {
    this.setState({
      attachment: {
        ...this.state.attachment,
        [e.target.name]: e.target.value
      }
    })
  }

  handleCreateOrUpdateNested(title, model, item) {
    this.setState({ nestedTitle: title })
    this.setState({ nestedItem: item })
    this.setState({ nestedModel: model })
    this.setState({ openNestedForm: true })
    if (model.toLowerCase() === 'address') {
      this.setState({ options: ["Permanent Address", "Current Address"] })
    } else if (model.toLowerCase() === 'contact number') {
      this.setState({ options: ["Primary", "Home Phone Number", "Phone Number", "Mobile Number", "Fax Number", "Others"] })
    } else {
      this.setState({ options: ["Personal", "Work", "Others"] })
    }

  }

  handleNestedChange(e) {
    this.setState({
      nestedItem: {
        ...this.state.nestedItem,
        [e.target.name]: e.target.value
      }
    })
  }


  submitNested() {
    const id = this.props.match.params.id
    var method = ''
    var url = ''
    var item = this.state.nestedItem
    if (this.state.nestedModel.toLowerCase() === 'contact number') {
      if (item.id === '') {
        method = 'post'
        url = '/v1/clients/' + id + '/contact_numbers/'
      } else {
        method = 'put'
        url = '/v1/clients/' + id + '/contact_numbers/' + item.id
      }
      axios({
        method: method,
        url: url,
        data: (item),
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {

        const newData = resp.data
        this.setState({
          contactNumbers: this.state.contactNumbers.filter(contact => contact.id !== item.id)
        })
        this.setState({ contactNumbers: [...this.state.contactNumbers, newData] })
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
        this.setState({ openNestedForm: false })
      }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
    } else {
      if (item.id === '') {
        method = 'post'
        url = '/v1/clients/' + id + '/email_addresses'
      } else {
        method = 'put'
        url = '/v1/clients/' + id + '/email_addresses/' + item.id
      }
      axios({
        method: method,
        url: url,
        data: (item),
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {

        const newData = resp.data
        this.setState({
          emailAddresses: this.state.emailAddresses.filter(address => address.id !== item.id)
        })
        this.setState({ emailAddresses: [...this.state.emailAddresses, newData] })
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
        this.setState({ openNestedForm: false })
      }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
    }
  }

  handleOpenDelete(model, item) {
    this.setState({ nestedItem: item })
    this.setState({ nestedModel: model })
    this.setState({ openDeleteNestedPopup: true })
  }


  handleNestedDeleteItem() {
    const id = this.props.match.params.id
    const DeleteItemId = this.state.nestedItem.id
    if (this.state.nestedModel.toLowerCase() === 'contact number') {
      axios({
        method: 'delete',
        url: '/v1/clients/' + id + '/contact_numbers/' + DeleteItemId,
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(() => {
        this.setState({
          contactNumbers: this.state.contactNumbers.filter(item => item.id !== DeleteItemId)
        })
        this.setState({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'error'
        })
        this.setState({ openDeleteNestedPopup: false })
      }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))

    } else if (this.state.nestedModel.toLowerCase() === 'email address') {
      axios({
        method: 'delete',
        url: '/v1/clients/' + id + '/email_addresses/' + DeleteItemId,
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(() => {
        this.setState({
          emailAddresses: this.state.emailAddresses.filter(item => item.id !== DeleteItemId)
        })
        this.setState({ openDeleteNestedPopup: false })
      }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
    } else {
      axios({
        method: 'delete',
        url: '/v1/clients/' + id + '/contact_persons/' + DeleteItemId,
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(() => {
        this.setState({
          contactPersons: this.state.contactPersons.filter(item => item.id !== DeleteItemId)
        })
        this.setState({ isOpen: true, message: 'Deleted Successfully', type: 'error' })
        this.setState({ openDeleteNestedPopup: false })
      }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
    }
  }

  handleContactPerson(title, item, addState) {
    var nestedContacts = []
    var nestedEmails = []
    if (addState === false) {
      axios({
        method: 'get',
        url: '/v1/contact_persons/' + item.id + '/contact_numbers',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        nestedContacts = resp.data
        axios({
          method: 'get',
          url: '/v1/contact_persons/' + item.id + '/email_addresses',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          nestedEmails = resp.data
          this.setState({ nestedContactNumbers: nestedContacts })
          this.setState({ nestedEmailAddresses: nestedEmails })
          this.setState({ openContactPerson: true })
          this.setState({ title: title })
          this.setState({ contactItem: item })
          this.setState({ contactState: true })
        }).catch(resp => this.setState(CommonFunction.handleApiRequestsErrors(resp)))
      }).catch(resp => this.setState(CommonFunction.handleApiRequestsErrors(resp)))
    } else {
      this.setState({ nestedContactNumbers: nestedContacts })
      this.setState({ nestedEmailAddresses: nestedEmails })
      this.setState({ openContactPerson: true })
      this.setState({ title: title })
      this.setState({ contactItem: item })
      this.setState({ contactState: true })
    }

  }

  handleContactPersonChange(e) {

    this.setState({
      contactItem: {
        ...this.state.contactItem,
        [e.target.name]: e.target.value
      }
    })
  }

  handleContactNumberChange(e) {
    const updatedContactNumbers = [...this.state.nestedContactNumbers]
    updatedContactNumbers[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
    this.setState({ nestedContactNumbers: updatedContactNumbers })

  }

  removeContactNumber(idx) {
    if (this.state.nestedContactNumbers[idx].id !== undefined) {
      const DeleteItemId = this.state.nestedContactNumbers[idx].id
      axios({
        method: 'delete',
        url: '/v1/contact_persons/' + this.state.contactItem.id + '/contact_numbers/' + DeleteItemId,
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(() => {

      }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
    }

    this.state.nestedContactNumbers.splice(idx, 1)
    this.setState({ nestedContactNumbers: [...this.state.nestedContactNumbers] })
  }

  addContactNumber() {
    this.setState({ nestedContactNumbers: [...this.state.nestedContactNumbers, { ...this.state.contactNumber }] })
  }

  handleNumberChange(e) {
    const re = /^[0-9\b]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      const updatedContactNumbers = [...this.state.nestedContactNumbers]
      updatedContactNumbers[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
      this.setState({ nestedContactNumbers: updatedContactNumbers })

    }
  }

  handleEmailAddressChange(e) {
    const updatedEmailAddress = [...this.state.nestedEmailAddresses]
    updatedEmailAddress[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
    this.setState({ nestedEmailAddresses: updatedEmailAddress })

  }

  addEmailAddress() {
    this.setState({ nestedEmailAddresses: [...this.state.nestedEmailAddresses, { ...this.state.emailAddress }] })
  }

  removeEmail(idx) {
    if (this.state.nestedEmailAddresses[idx].id !== undefined) {
      const DeleteItemId = this.state.nestedEmailAddresses[idx].id
      axios({
        method: 'delete',
        url: '/v1/contact_persons/' + this.state.contactItem.id + '/email_addresses/' + DeleteItemId,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(() => {

      }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
    }
    this.state.nestedEmailAddresses.splice(idx, 1)
    this.setState({ nestedEmailAddresses: [...this.state.nestedEmailAddresses] })
  }

  submitContact() {
    var item = this.state.contactItem
    var method = ''
    var url = ''
    if (item.id === '') {
      method = "post"
      url = '/v1/clients/' + this.state.client.id + '/contact_persons'
    } else {
      method = "put"
      url = '/v1/clients/' + this.state.client.id + '/contact_persons/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      const id = resp.data.id
      var method = ''
      var url = ''

      if (this.state.nestedContactNumbers.length > 0) {
        this.state.nestedContactNumbers.map((contact) => {
          if (contact.id === undefined) {
            method = "post"
            url = '/v1/contact_persons/' + id + '/contact_numbers'
          } else {
            method = "put"
            url = '/v1/contact_persons/' + id + '/contact_numbers/' + contact.id
          }
          axios({
            method: method,
            url: url,
            data: (contact),
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(() => {
            if (this.state.nestedEmailAddresses.length > 0) {
              this.state.nestedEmailAddresses.map((emailAddress) => {
                if (emailAddress.id === undefined) {
                  method = "post"
                  url = '/v1/contact_persons/' + id + '/email_addresses'
                } else {
                  method = "put"
                  url = '/v1/contact_persons/' + id + '/email_addresses/' + emailAddress.id
                }
                axios({
                  method: method,
                  url: url,
                  data: (emailAddress),
                  headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
                }).then(() => {
                  this.setState({
                    contactPersons: this.state.contactPersons.filter(contactPerson => contactPerson.id !== item.id)
                  })
                  this.setState({ contactPersons: [...this.state.contactPersons, newData] })
                  this.setState({ openContactPerson: false })
                })
              })

            } else {
              this.setState({
                contactPersons: this.state.contactPersons.filter(contactPerson => contactPerson.id !== item.id)
              })
              this.setState({ contactPersons: [...this.state.contactPersons, newData] })
              this.setState({ openContactPerson: false })
            }
          }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
        })
      } else if (this.state.nestedEmailAddresses.length > 0) {
        this.state.nestedEmailAddresses.map((emailAddress) => {
          if (emailAddress.id === undefined) {
            method = "post"
            url = '/v1/contact_persons/' + id + '/email_addresses'
          } else {
            method = "put"
            url = '/v1/contact_persons/' + id + '/email_addresses/' + emailAddress.id
          }
          axios({
            method: method,
            url: url,
            data: (emailAddress),
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(() => {
            if (this.state.nestedContactNumbers.length > 0) {
              this.state.nestedContactNumbers.map((contact) => {
                if (contact.id === undefined) {
                  method = "post"
                  url = '/v1/contact_persons/' + id + '/contact_numbers'
                } else {
                  method = "put"
                  url = '/v1/contact_persons/' + id + '/contact_numbers/' + contact.id
                }
                axios({
                  method: method,
                  url: url,
                  data: (contact),
                  headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
                }).then(() => {
                  this.setState({
                    contactPersons: this.state.contactPersons.filter(contactPerson => contactPerson.id !== item.id)
                  })
                  this.setState({ contactPersons: [...this.state.contactPersons, newData] })
                  this.setState({ openContactPerson: false })
                }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
              })
            } else {
              this.setState({
                contactPersons: this.state.contactPersons.filter(contactPerson => contactPerson.id !== item.id)
              })
              this.setState({ contactPersons: [...this.state.contactPersons, newData] })
              this.setState({ openContactPerson: false })
            }

          })
        })
      } else {
        this.setState({
          contactPersons: this.state.contactPersons.filter(contactPerson => contactPerson.id !== item.id)
        })
        this.setState({ contactPersons: [...this.state.contactPersons, newData] })
        this.setState({ openContactPerson: false })
      }

    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))

  }


  render() {
    const { redirectToIndex } = this.state
    if (redirectToIndex)
      return (<Redirect to={'/clients'} />)
    return (
      <>
        {
          this.state.load ? (
            <div>
              <Grid container spacing={1}>
                <Grid item xs={6}><h2>{this.state.client.name}</h2></Grid>
                <Grid item xs={6}>
                <div className={styles.groupButtonShow}>
                  <Link to="/clients">
                    <Tooltip title="Back" arrow>
                      <IconButton>
                        <ArrowBackIosIcon />
                      </IconButton>
                    </Tooltip>
                  </Link>
                  <Tooltip title="Edit" arrow>
                    <IconButton
                      color="primary"
                    >
                      <Link to={{
                        pathname: `${this.state.client.id}/edit`,
                        state: { item: this.state.client, }
                      }}>
                        <EditIcon />
                      </Link>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" arrow>
                    <IconButton
                      color="secondary"
                      onClick={this.handleOpenDeletePopup}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={3} className={styles.detailsText}><b> Classification </b></Grid>
                <Grid item xs={6} sm={3} className={styles.detailsText}>:&emsp; {this.state.client.classification ? this.state.client.classification.name : null} </Grid>
                <Grid item xs={6} sm={3} className={styles.detailsText}><b> Tax Exempted </b></Grid>
                <Grid item xs={6} sm={3} className={styles.detailsText}> :&emsp; {this.state.client.tax_exempted === true ? 'Yes' : 'No'} </Grid>
                <Grid item xs={6} sm={3} className={styles.detailsText}><b> TIN No. </b></Grid>
                <Grid item xs={6} sm={3} className={styles.detailsText}> :&emsp; {this.state.client.tin_no ? this.state.client.tin_no : ''} </Grid>
                <Grid item xs={6} sm={3} className={styles.detailsText}><b> Business Style </b></Grid>
                <Grid item xs={6} sm={3} className={styles.detailsText}> :&emsp; {this.state.client.business_type ? this.state.client.business_type : ''} </Grid>
                <Grid item xs={6} sm={3} className={styles.detailsText}><b> Home Address </b></Grid>
                <Grid item xs={6} sm={3} className={styles.detailsText}> :&emsp; {this.state.client.home_address} </Grid>
                <Grid item xs={6} sm={3} className={styles.detailsText}><b> Company Address </b></Grid>
                <Grid item xs={6} sm={3} className={styles.detailsText}> :&emsp; {this.state.client.company_address} </Grid>
                <Grid item xs={6} sm={3} className={styles.detailsText}><b> Remarks </b></Grid>
                <Grid item xs={6} sm={9} className={styles.detailsText}> :&emsp; {this.state.client.remarks} </Grid>
              </Grid>
              <div>
                <Tabs variant="scrollable" value={this.state.value} onChange={this.handleChange} indicatorColor="secondary" aria-label="simple tabs example" className={styles.tabBody}>
                  <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                    {/*<PersonOutlineIcon /> */}
                    <span className={styles.tabLabel}>CONTACT INFORMATION</span></div>}{...a11yProps(0)} />
                  <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                    {/*<MenuBookIcon />*/}
                    <span className={styles.tabLabel}>ATTACHMENTS</span></div>}{...a11yProps(1)} />
                  <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                    {/*<CalendarTodayIcon />*/}
                    <span className={styles.tabLabel}>INVOICES</span></div>} {...a11yProps(2)} />
                  <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                    {/*<CalendarTodayIcon />*/}
                    <span className={styles.tabLabel}>COLLECTION RECEIPTS</span></div>} {...a11yProps(3)} />
                  {
                    this.state.current_user.is_from_cdasia ? (
                      <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                        {/*<CalendarTodayIcon />*/}
                        <span className={styles.tabLabel}>DELIVERY RECEIPTS</span></div>} {...a11yProps(4)} />
                    ) : null
                  }
                  <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                    <span className={styles.tabLabel}>AUDIT LOGS</span></div>} {...a11yProps(5)} />
                </Tabs>
                {/* {this.state.value === 5 ? null : <hr />}  */}
                <TabPanel value={this.state.value} index={0}>

                  <Grid container spacing={1} className={styles.nestedBody}>
                    <Grid item xs={12} sm={6}>
                      <ContactNumbers
                        contactNumbers={this.state.contactNumbers}
                        handleClick={this.handleCreateOrUpdateNested}
                        initialContactNumber={this.state.initialNested}
                        handleOpenDelete={this.handleOpenDelete}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <EmailAddresses
                        emailAddresses={this.state.emailAddresses}
                        handleClick={this.handleCreateOrUpdateNested}
                        initialEmailAddress={this.state.initialNested}
                        handleOpenDelete={this.handleOpenDelete}
                      />
                    </Grid>
                  </Grid>

                  <ContactPersonDetailTab
                    contactPersons={this.state.contactPersons}
                    handleClick={this.handleContactPerson}
                    initialContactPerson={this.state.initialContactPerson}
                    handleOpenDelete={this.handleOpenDelete}
                  />
                </TabPanel>
                <TabPanel value={this.state.value} index={1} className={styles.tabListBody}>
                  <AttachmentDetailTab
                    attachments={this.state.attachments}
                    openAttachment={this.openAttachment}
                  />
                </TabPanel>
                <TabPanel value={this.state.value} index={2} className={styles.tabListBody}>
                  <SimpleTable
                    model="Invoice"
                    headers={['date_of_invoice', 'reference_no', 'payment_status', 'total_receipt', 'total', 'creator_name']}
                    items={this.state.invoices}
                    clickableTableRow={true}
                    insideTab={true}
                    newThruClient={true}
                    defaultNewRoutesPath={'/clients/' + this.state.client.id + '/invoices/new'}
                    defaultModelRoute='/invoices/'
                    noLabel={true}
                    headerTitle="Invoices"
                  />
                </TabPanel>
                <TabPanel value={this.state.value} index={3} className={styles.tabListBody}>
                  <SimpleTable
                    model="Collection Receipt"
                    headers={['date_of_receipt', 'reference_no', 'total_amount', 'creator_name']}
                    items={this.state.receipts}
                    clickableTableRow={true}
                    insideTab={true}
                    newThruClient={true}
                    defaultNewRoutesPath={'/clients/' + this.state.client.id + '/receipts/new'}
                    defaultModelRoute="/receipts/"
                    hasDeleteButton={false}
                    noLabel={true}
                    headerTitle="Collection Receipts"
                  />
                </TabPanel>
                {
                  this.state.current_user.is_from_cdasia ? (
                    <TabPanel value={this.state.value} index={4} className={styles.tabListBody}>
                      <SimpleTable
                        model="Delivery Receipt"
                        headers={['date_of_delivery', 'reference_no', 'description', 'deliverer.name', 'creator_name']}
                        items={this.state.deliveries}
                        clickableTableRow={true}
                        newThruClient={true}
                        insideTab={true}
                        defaultNewRoutesPath={'/clients/' + this.state.client.id + '/deliveries/new'}
                        defaultModelRoute='/deliveries/'
                        hasDeleteButton={false}
                      />
                    </TabPanel>
                  ) : null
                }
                <TabPanel value={this.state.value} index={this.state.current_user.is_from_cdasia ? 5 : 4} >
                  <AuditLog gid={this.state.client.gid} />
                </TabPanel>
              </div>

              <SimplePopUp
                openPopup={this.state.openNestedForm}
                title={this.state.nestedTitle}
                handleClose={this.handleClose}
                maxWidth='sm'
              >
                <NestedForm
                  handleSubmit={this.submitNested} handleChange={this.handleNestedChange}
                  item={this.state.nestedItem} options={this.state.options}
                  nestedModel={this.state.nestedModel} />
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeleteNestedPopup}
                item={this.state.nestedItem}
                delete={this.handleNestedDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.nestedModel}
              />

              <SimplePopUp
                openPopup={this.state.openContactPerson}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth='sm'
              >
                <ContactPersonForm
                  contactPerson={this.state.contactItem} handleChange={this.handleContactPersonChange}
                  contactNumbers={this.state.nestedContactNumbers}
                  handleContactNumberChange={this.handleContactNumberChange}
                  removeContactNumber={this.removeContactNumber} addContactNumber={this.addContactNumber}
                  handleNumberChange={this.handleNumberChange}
                  emailAddresses={this.state.nestedEmailAddresses}
                  handleEmailAddressChange={this.handleEmailAddressChange}
                  removeEmail={this.removeEmail} addEmailAddress={this.addEmailAddress}
                  submitContact={this.submitContact} contactState={this.state.contactState}
                />
              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.openAttachmentPopup}
                title="Upload Attachments"
                items={this.state.attachments}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <AttachmentForm
                  attachment={this.state.attachment}
                  error={this.state.error_messages} item={this.state.client}
                  onFileChange={this.onFileChange}
                  submit={this.handleAttacthmentSubmit} onchange={this.handleAttactmentInputChange} />
              </SimplePopUp>
              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.client}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model="Clients"
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model} />
          )}
      </>
    )
  }
}

export default ClientsShow
ClientsShow.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  })
}
