import React from 'react'
import styles from './Invoices.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  MenuItem,
  Grid
} from '@material-ui/core'
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

class InvoicesSearch extends React.Component {
	render() {
		return (
			<div>
				<Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <TextField
              autoComplete="off"
              type="date"
              variant="outlined"
              size="small"
              name="starts_on_from"
              fullWidth={true}
              label="Start Date"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.item.starts_on_from}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              autoComplete="off"
              variant="outlined"
              type="date"
              size="small"
              name="starts_on_to"
              fullWidth={true}
              label="End Date"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.item.starts_on_to}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
					<Grid item xs={12} sm={3}>
						<TextField
							autoComplete="off"
							variant="outlined"
							size="small"
							name="reference_no"
							fullWidth={true}
							label="Invoice Number"
							onChange={this.props.handleRefInputChange}
							onKeyPress={this.props.handleKeyPress}
							value={this.props.item.reference_no}
							id="reference_no"
						/>
					</Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              name="branch_id"
              size="small"
              options={this.props.branches}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleSearchAutocomplete}
              fullWidth="true"
              // onInputChange={}
              value={this.props.item.branch_id}
              renderInput={(params) => <TextField {...params} label="Branch Name" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              autoComplete="off"
              variant="outlined"
              size="small"
              name="client_name"
              fullWidth={true}
              label="Client Name"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.item.client_name}
              id="client_name"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              autoComplete="off"
              select
              variant="outlined"
              size="small"
              name="status"
              fullWidth={true}
              label="Status"
              onChange={this.props.handleChange}
              value={this.props.item.status || ''}
            >
              <MenuItem value=""> </MenuItem>
              <MenuItem value="for_posting">For Posting</MenuItem>
              <MenuItem value="for_approval">For Approval</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
              <MenuItem value="posted">Posted</MenuItem>
            </TextField>
          </Grid>
        </Grid>
				<div className={styles.actionButton}>
					<Button variant="outlined" id="product-search" color="primary" onClick={() => this.props.search()} >Search</Button>
					<Button variant="outlined" id="product-clearSearch" color="secondary" onClick={() => this.props.clearSearch()} >Clear</Button>
				</div>

			</div>
		)
	}
}
export default InvoicesSearch

InvoicesSearch.propTypes = {
	handleChange: PropTypes.func,
	item: PropTypes.object,
  branches: PropTypes.array,
	search: PropTypes.func,
	clearSearch: PropTypes.func,
  handleRefInputChange: PropTypes.func,
  handleSearchAutocomplete: PropTypes.func,
	handleKeyPress: PropTypes.func
}
