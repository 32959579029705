import React from 'react'
import styles from './CwtTypes.module.css'

import PropTypes from 'prop-types'

import { TextField, Button, Grid } from '@material-ui/core'

class CwtTypesForm extends React.Component {
  render() {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} lg={6}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="name"
              fullWidth
              label="Name"
              onChange={this.props.onchange}
              value={this.props.item.name}
              id="expenseType-name"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="multiplier"
              fullWidth
              label="Multiplier"
              onChange={this.props.onchange}
              value={this.props.item.multiplier}
              id="expenseType-multiplier"
            />

          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="description"
              fullWidth
              label="Description"
              onChange={this.props.onchange}
              value={this.props.item.description}
              id="expenseType-description"
            />
          </Grid>
        </Grid>
        <div className={styles.actionButton}>
          <Button variant="outlined" disabled={!this.props.item.name} id="cwt-submit" color="primary" onClick={() => this.props.submit()} >Save</Button>
        </div>
      </div>
    )
  }
}
export default CwtTypesForm

CwtTypesForm.propTypes = {
  onchange: PropTypes.func,
  item: PropTypes.object,
  submit: PropTypes.func
}
