// eslint-disable-next-line

import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import {Redirect} from 'react-router'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Box, Button, IconButton, Tab, Tabs, Typography, Table, TableHead, TableCell, TableRow,
         TableBody, Tooltip, TableContainer } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import styles from './Delivery.module.css'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import DeliveryForm from './DeliveryForm'
import DeliveryDetailsForm from './DeliveryDetailsForm'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import tableStyles from './../../shared/SimpleTable/SimpleTable.module.css'
import ProductTransactionsTab from "./../ProductTransactions/ProductTransactionsTab.js"
import * as CommonFunction from "../../shared/Custom/CommonFunctions"
import {Link} from "react-router-dom";
import DeliveryProductTransactionForm from "./../ProductTransactions/DeliveryProductTransactionForm.js"
import moment from 'moment'
import AuditLog from '../../shared/AuditLog/AuditLog'
function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

class DeliveryShow extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      model: 'Deliveries',
      popupHeader: '',
      title: '',
      error_messages: '',
      alertMessage: '',
      type: '',
      selectedDeliveryDetailsIndex: 0,
      selectedProductTransactionsIndex: 0,
      value: 0,
      invoices: [],
      products: [],
      deliveryDetails: [],
      clients: [],
      branches: [],
      employees: [],
      productTransactions: [],
      clientsAutoCompleteLoading: false,
      branchesAutoCompleteLoading: false,
      openNewAndEditDeliveryDetailPopup: false,
      openDeleteDeliveryPopup: false,
      openDeleteDeliveryDetailsPopup: false,
      invoicesAutoCompleteLoading: false,
      productsAutoCompleteLoading: false,
      openPostDeliveryPopup: false,
      openUnpostDeliveryPopup: false,
      redirectToIndex: false,
      openNewAndEditProductTransactionPopup: false,
      load: false,
      delivery: {},
      initialDeliveryDetail: {},
      initialDelivery: {},
      deliveredProducts: [],
      isAddProductTransaction: false,
      initialProductTransaction: { option: "Delivery" },
      current_user: JSON.parse(localStorage.getItem("current_user"))
    }

    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.handleAutocompleteOnInputChange = this.handleAutocompleteOnInputChange.bind(this)
    this.handleDeliveryDetailsFormInputChange = this.handleDeliveryDetailsFormInputChange.bind(this)
    this.handleCloseSimplePopup = this.handleCloseSimplePopup.bind(this)
    this.handleOpenDeleteDePopup = this.handleOpenDeletePopup.bind(this)
    this.handleOpenDeliveryDetailSimplePopup = this.handleOpenDeliveryDetailSimplePopup.bind(this)
    this.handleDeleteDelivery = this.handleDeleteDelivery.bind(this)
    this.handleDeleteDeliveryDetails = this.handleDeleteDeliveryDetails.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSaveDeliveryDetails = this.handleSaveDeliveryDetails.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleDeliveryFormInputChange = this.handleDeliveryFormInputChange.bind(this)
    this.handleEditDeliverySimplePopup = this.handleEditDeliverySimplePopup.bind(this)
    this.handleSaveDelivery = this.handleSaveDelivery.bind(this)
    this.handleOpenPostAndUnpostSimplePopUp = this.handleOpenPostAndUnpostSimplePopUp.bind(this)
    this.handlePostDelivery = this.handlePostDelivery.bind(this)
    this.handleUnpostDelivery = this.handleUnpostDelivery.bind(this)
    this.handleChangeTabs = this.handleChangeTabs.bind(this)
    this.handleOpenProductTransactionSimplePopup = this.handleOpenProductTransactionSimplePopup.bind(this)
    this.handleProductTransactionFormInputChange = this.handleProductTransactionFormInputChange.bind(this)
    this.handleProductTransactionAutocomplete = this.handleProductTransactionAutocomplete.bind(this)
    this.handleSaveProductTransaction = this.handleSaveProductTransaction.bind(this)
  }

  componentDidMount() {
    this.loadDelivery()
  }

  loadDelivery() {
    axios({
      method: 'get',
      url: '/v1/deliveries/' + this.props.match.params.id,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ delivery: resp.data, initialDelivery: resp.data, load: true,
                      initialProductTransaction: { ...this.state.initialProductTransaction, delivery_no: resp.data.reference_no, date_of_password: resp.data.date_of_delivery } })
      this.loadInvoicesAutoCompleteOptions(this.state.delivery.client_id)
      this.loadDeliveryDetails()
      this.loadProductTransactions({ delivery_no: resp.data.reference_no, per_page: 1000000 })
      resp.data.invoice_nos.map(invoice_no => this.loadProductTransactions({ invoice_no: invoice_no, per_page: 1000000 }, false))
      axios({
        method: 'get',
        url: '/v1/branches',
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ branches: resp.data })
      }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
      axios({
        method: 'get',
        url: '/v1/deliveries/employees',
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ employees: resp.data })
      }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadProductTransactions (opts, isDeliveryProductTransaction = true) {
    axios({
      method: 'get',
      url: '/v1/product_transactions/search',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')},
      params: opts
    }).then(resp => {
      isDeliveryProductTransaction ? this.setState({ productTransactions: resp.data.product_transactions }) : (
        resp.data.product_transactions.map((data) => {
          if (this.state.productTransactions.filter(product_transaction => product_transaction.id !== data.id).length > 0) {
            this.state.productTransactions.push(data)
          }
        })
      )
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadDeliveryDetails() {
    axios({
      method: 'get',
      url: '/v1/deliveries/' + this.props.match.params.id + '/delivery_details',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ deliveryDetails: resp.data })
      resp.data.map((item) => {
        item.invoice_id !== undefined && item.invoice_id !== null ? (this.getInvoice(item)) : (null)
        this.getProduct(item)
      })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadCdsAutoCompleteOptions(searchText = null) {
    axios({
      method: 'get',
      url: '/v1/cds/search?description=' + searchText,
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ products: resp.data.cds, productsAutoCompleteLoading: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadInvoicesAutoCompleteOptions(client_id, searchText = '') {
    axios({
      method: 'get',
      url: '/v1/invoices/search?client_id=' + client_id + '&reference_no=' + searchText + "&payment_status=Not Yet Paid",
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ invoices: resp.data.invoices, invoicesAutoCompleteLoading: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadInvoiceCdsAutoCompleteOptions(invoice_id) {
    axios({
      method: 'get',
      url: '/v1/invoices/' + invoice_id + '/cds/',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ products: resp.data, productsAutoCompleteLoading: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  getInvoice(deliveryDetail) {
    axios({
      method: 'get',
      url: '/v1/invoices/' + deliveryDetail.invoice_id,
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key') }
    }).then(resp => {
      deliveryDetail.invoice = resp.data
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  getProduct(deliveryDetail) {
    axios({
      method: 'get',
      url: '/v1/cds/' + deliveryDetail.cd_id,
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key') }
    }).then(resp => {
      deliveryDetail.cd = resp.data
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleAutocomplete(event, value) {
    if (event.target.id.includes("employee")) {
      this.setState({ initialDelivery: { ...this.state.initialDelivery, employee: value } })
    } else if (event.target.id.includes("branch")) {
      var reference_no = value !== undefined && value !== null ? (value.name.substr(0, 1) + '-') : ('')
      this.setState({ branchesAutoCompleteLoading: false, initialDelivery: { ...this.state.initialDelivery, branch: value, reference_no: reference_no } })
    } else if (event.target.id.includes("invoice")) {
      this.setState({ initialDeliveryDetail: { ...this.state.initialDeliveryDetail, invoice: value } })
      if (value !== undefined && value !== null) {
        this.loadInvoiceCdsAutoCompleteOptions(value.id)
      } else {
        this.loadCdsAutoCompleteOptions()
      }
    } else if (event.target.id.includes("cd")) {
      this.setState({ initialDeliveryDetail: { ...this.state.initialDeliveryDetail, cd: value } })
    }
  }

  handleAutocompleteOnInputChange(event, value, reason) {
    if (reason === 'input') {
      if(event.target.id.includes("invoice")) {
        this.setState({ invoicesAutoCompleteLoading: true })
        this.loadInvoicesAutoCompleteOptions(this.state.delivery.client_id, event.target.value || '')
      } else if(event.target.id.includes("cd")) {
        this.setState({ productsAutoCompleteLoading: true })
        this.loadCdsAutoCompleteOptions(event.target.value || '')
      }
    }
  }

  handleDeliveryDetailsFormInputChange(event) {
    this.setState({ initialDeliveryDetail: { ...this.state.initialDeliveryDetail, [event.target.name]: event.target.value } })
  }

  handleCloseSimplePopup () {
    this.setState({ openNewAndEditDeliveryDetailPopup: false, openDeleteDeliveryPopup: false, openDeleteDeliveryDetailsPopup: false, openEditDeliveryPopup: false,
                    openPostDeliveryPopup: false, openUnpostDeliveryPopup: false, openNewAndEditProductTransactionPopup: false, isAddProductTransaction: false, error_messages: '' })
  }

  handleOpenDeliveryDetailSimplePopup(item, isAdd, targetIndex = 0) {
    var popupHeader = ''
    isAdd ? (popupHeader = 'Add Delivery Detail') : (popupHeader = 'Edit Delivery Detail')
    item.invoice_id !== undefined && item.invoice_id !== null ? (this.loadInvoiceCdsAutoCompleteOptions(item.invoice_id)) : (this.loadCdsAutoCompleteOptions())
    this.setState({ openNewAndEditDeliveryDetailPopup: true, initialDeliveryDetail: item, popupHeader: popupHeader, isAddDeliveryDetail: isAdd, selectedDeliveryDetailsIndex: parseInt(targetIndex) })
  }

  handleOpenDeletePopup(opts) {
    this.setState(opts)
  }

  handleDeleteDelivery() {
    axios({
      method: 'DELETE',
      url: '/v1/deliveries/' + this.state.delivery.id,
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({ openAlertMessage: true, openDeleteDeliveryPopup: false, redirectToIndex: true, alertMessage: 'Deleted Successfully', type: 'error' })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleDeleteDeliveryDetails(item, index) {
    axios({
      method: 'DELETE',
      url: '/v1/deliveries/' + item.delivery_id + '/delivery_details/' + item.id,
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(() => {
      this.state.deliveryDetails.splice(index, 1)
      this.setState({ openAlertMessage: true, openDeleteDeliveryDetailsPopup: false, alertMessage: 'Deleted Successfully', type: 'error' })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleSaveDeliveryDetails(deliveryDetail) {
    var method = ''
    var url = '/v1/deliveries/' + this.state.delivery.id + '/delivery_details/'
    var delivery_detail = { invoice_id: deliveryDetail.invoice.id, cd_id: deliveryDetail.cd.id, quantity: parseInt(deliveryDetail.quantity),
                            purpose: deliveryDetail.purpose, media_type: deliveryDetail.media_type, is_virtual: deliveryDetail.is_virtual,
                            remarks: deliveryDetail.remarks }

    if (deliveryDetail.id !== undefined && deliveryDetail.id !== null) {
      method = 'PUT'
      url = url + deliveryDetail.id
    } else {
      method = 'POST'
    }

    axios({
      method: method,
      url: url,
      data: delivery_detail,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.state.deliveryDetails.splice(this.state.selectedDeliveryDetailsIndex, 1, resp.data)
      this.setState({ openAlertMessage: true, alertMessage: 'Submitted Successfully', type: 'success' })
      this.handleCloseSimplePopup()
      this.loadDeliveryDetails()
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openAlertMessage: false, alertMessage: '', type: '' })
  }

  handleSwitch(event) {
    this.setState({ initialDeliveryDetail: { ...this.state.initialDeliveryDetail, [event.target.id]: event.target.checked } })
  }

  handleDeliveryFormInputChange(event) {
    this.setState({ initialDelivery: { ...this.state.initialDelivery, [event.target.id]: event.target.value } })
  }

  handleEditDeliverySimplePopup() {
    this.setState({ openEditDeliveryPopup: true, popupHeader: 'Edit Delivery', initialDelivery: this.state.delivery })
    this.setState({ initialDelivery: { ...this.state.initialDelivery, employee: this.state.delivery.deliverer } })
  }

  handleSaveDelivery() {
    var delivery = { client_id: this.state.initialDelivery.client.id, branch_id: this.state.initialDelivery.branch.id, employee_id: this.state.initialDelivery.employee.id,
                     reference_no: this.state.initialDelivery.reference_no, date_of_delivery: this.state.initialDelivery.date_of_delivery,
                     description: this.state.initialDelivery.description }

    axios({
      method: 'PUT',
      url: '/v1/deliveries/' + this.state.delivery.id,
      data: delivery,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ openAlertMessage: true, alertMessage: 'Updated Successfully', type: 'success', delivery: resp.data })
      this.handleCloseSimplePopup()
    }).catch(error => {
      if (Object.entries(error.response).length > 0 && error.response.status === 422) {
        this.setState({error_messages: 'Delivery Number ' + error.response.data.errors.reference_no[0]})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
      }
    })
  }

  handleOpenPostAndUnpostSimplePopUp(opts) {
    this.setState(opts)
  }

  handlePostDelivery() {
    axios({
      method: 'put',
      url: '/v1/deliveries/' + this.state.delivery.id + '/post',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then((resp) => {
      this.setState({ openAlertMessage: true, openPostDeliveryPopup: false, alertMessage: 'Successfully Posted', type: 'success', delivery: resp.data })
    })
  }

  handleUnpostDelivery() {
    axios({
      method: 'put',
      url: '/v1/deliveries/' + this.state.delivery.id + '/unpost',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then((resp) => {
      this.setState({ openAlertMessage: true, openUnpostDeliveryPopup: false, alertMessage: 'Successfully Unosted', type: 'success', delivery: resp.data })
    })
  }

  handleOpenProductTransactionSimplePopup(item, isAdd, targetIndex = 0) {
    var popupHeader = ''
    isAdd ? (popupHeader = 'Add Product Given Password') : (popupHeader = 'Edit Product Given Password')
    this.setState({ openNewAndEditProductTransactionPopup: true, initialProductTransaction: item, isAddProductTransaction: isAdd,
                    popupHeader: popupHeader, selectedProductTransactionsIndex: parseInt(targetIndex),
                    deliveredProducts: item.id && item.delivery_detail_id ? this.state.deliveryDetails.filter(detail => detail.id === item.delivery_detail_id) : [] })
  }

  handleChangeTabs(event, newValue) {
    this.setState({value: newValue})
  }

  handleProductTransactionFormInputChange(event) {
    this.setState({ initialProductTransaction: { ...this.state.initialProductTransaction, [event.target.id]: event.target.value } })
  }

  handleProductTransactionAutocomplete(event, value) {
    if (event.target.id.includes("employee")) {
      this.setState({ initialProductTransaction: { ...this.state.initialProductTransaction, employee: value || {} } })
    } else if (event.target.id.includes("delivery_detail")) {
      this.setState({ initialProductTransaction: { ...this.state.initialProductTransaction, delivery_detail: value || {} },
                      deliveredProducts: value ? value.products : [] })
    } else if (event.target.id.includes("product")) {
      this.setState({ initialProductTransaction: { ...this.state.initialProductTransaction, product: value || {} } })
    }
  }

  handleSaveProductTransaction() {
    const item = this.state.initialProductTransaction
    let method = ''
    let url = ''

    if (item.id) {
      method = "PUT"
      url = '/v1/deliveries/' + this.state.delivery.id + '/product_transactions/' + item.id
    } else {
      method = "POST"
      url = '/v1/deliveries/' + this.state.delivery.id + '/product_transactions/'
    }

    const product_transaction = { id: item.id, delivery_id: this.state.delivery.id, delivery_detail_id: item.delivery_detail.id, 
                                  product_id: item.product.id, employee_id: item.employee.id, date_of_password: item.date_of_password,
                                  cd_id: item.delivery_detail.cd_id, reason: item.reason, status: item.status }

    axios({
      method: method,
      url: url,
      data: product_transaction,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      if (this.state.isAddProductTransaction) {
        this.state.productTransactions.push(resp.data)
      } else {
        this.state.productTransactions.splice(this.state.selectedProductTransactionsIndex, 1, resp.data) 
      }
      this.setState({ openAlertMessage: true, alertMessage: 'Submitted Successfully', type: 'success' })
      this.handleCloseSimplePopup()
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  render() {
    const {redirectToIndex} = this.state
    if (redirectToIndex)
      return (<Redirect to={'/deliveries'}/>)
    return (
      <>
        {
          this.state.load ? (
            <div>
              <div className={styles.showTitleHeader}>
                <div><h2>Reference Number: {this.state.delivery.reference_no}</h2></div>
                <div>
                  <div className={styles.groupButtonShow}>
                    <Link to="/deliveries">
                      <Tooltip title="Back" arrow>
                        <IconButton>
                          <ArrowBackIosOutlinedIcon/>
                        </IconButton>
                      </Tooltip>
                    </Link>
                    {
                      this.state.delivery.can_unpost ? (
                        <Tooltip title="Unpost">
                          <IconButton
                            onClick={() => this.handleOpenPostAndUnpostSimplePopUp({ openUnpostDeliveryPopup: true })}
                          >
                            <CancelOutlinedIcon/>
                          </IconButton>
                        </Tooltip>
                      ) : null
                    }

                    {
                      this.state.delivery.can_post ? (
                        <Tooltip title="Post">
                          <IconButton
                            onClick={() => this.handleOpenPostAndUnpostSimplePopUp({ openPostDeliveryPopup: true })}
                          >
                            <CheckCircleOutlineOutlinedIcon/>
                          </IconButton>
                        </Tooltip>
                      ) : null
                    }
                    <Tooltip title="Edit">
                    <IconButton
                      color="primary"
                      onClick={() => this.handleEditDeliverySimplePopup()}
                    >
                      <EditIcon/>
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                    <IconButton
                      color="secondary"
                      onClick={() => this.handleOpenDeletePopup({ model: "delivery", openDeleteDeliveryPopup: true })}
                    >
                      <DeleteIcon/>
                    </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <hr/>
              <div className={styles.detailsBody}>
                <div className={styles.leftShowDetails}>
                  <div className={styles.detailList}>
                    <div><b> Client Name </b></div>
                    <div>:&emsp;</div>
                    <div> { this.state.delivery.client.name } </div>
                  </div>

                  <div className={styles.detailList}>
                    <div><b> Branch </b></div>
                    <div>:&emsp; { this.state.delivery.branch.name } </div>
                  </div>

                  <div className={styles.detailList}>
                    <div><b> Date of Delivery </b></div>
                    <div>:&emsp; { moment(this.state.delivery.date_of_delivery).format("MM/DD/YYYY") }
                    </div>
                  </div>
                </div>

                <div className={styles.rightShowDetails}>
                  <div className={styles.detailList}>
                    <div><b> Address </b></div>
                    <div>:&emsp; </div>
                    <div>{ this.state.delivery.client.company_address || this.state.delivery.client.home_address } </div>
                  </div>

                  <div className={styles.detailList}>
                    <div><b> Delivered by </b></div>
                    <div>:&emsp; { this.state.delivery.deliverer.name } </div>
                  </div>

                  <div className={styles.detailList}>
                    <div><b> Prepared by </b></div>
                    <div>:&emsp; { this.state.delivery.creator_name } </div>
                  </div>
                </div>
              </div>

              <div className={styles.detailList}>
                <div><b> Description </b></div>
                <div>:&emsp;&emsp; {this.state.delivery.description}</div>
              </div>
              <hr/>

              <div>
                <Tabs value={ this.state.value } onChange={this.handleChangeTabs} indicatorColor="secondary"
                      className={ styles.tabBody } aria-label="simple tabs example">
                  <Tab className={ styles.tabList } label={<div className={styles.tabListTitle}>
                    {/*<PersonOutlineIcon /> */}
                    <span className={ styles.tabLabel }>DELIVERY DETAILS</span></div>}{...a11yProps(0)} />
                  {
                    this.state.current_user.is_from_cdasia ? (
                      <Tab className={ styles.tabList } label={<div className={styles.tabListTitle}>
                        <span className={ styles.tabLabel }>PRODUCT GIVEN PASSWORDS</span></div>}{...a11yProps(1)} />
                    ) : null
                  }
                  <Tab className={ styles.tabList } label={<div className={styles.tabListTitle}>
                        <span className={ styles.tabLabel }>AUDIT LOGS</span></div>}{...a11yProps(2)} />
                </Tabs>
                <hr/>

                <TabPanel value={ this.state.value } index={0}>
                  <div>
                    <div className={styles.checkDetailsHeader}>
                      <div>
                        <Tooltip title="Add Delivery Detail" arrow>
                          <IconButton
                            variant="addDeliveryDetail"
                            name="add_delivery_details"
                            disabled={Object.keys(this.state.delivery.client).length === 0}
                            onClick={() => this.handleOpenDeliveryDetailSimplePopup({}, true)}>
                            <AddCircleOutlineIcon fontSize="medium" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    {
                      this.state.deliveryDetails.length > 0 ? (
                        <TableContainer className="action-button" style={{maxHeight: '575px', position: 'relative'}}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <b>Invoice Reference No</b>
                                </TableCell>
                                <TableCell>
                                  <b>Product Description</b>
                                </TableCell>
                                <TableCell>
                                  <b>Media Type</b>
                                </TableCell>
                                <TableCell>
                                  <b>Delivered Qty</b>
                                </TableCell>
                                <TableCell>
                                  <b>Purpose</b>
                                </TableCell>
                                <TableCell>
                                  <b>Remarks</b>
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody className={tableStyles.tableBody}>
                              {
                                this.state.deliveryDetails.map((item, index) => (
                                  <>
                                    <TableRow key={index}>
                                      <TableCell>
                                        { item.invoice_no !== null && item.invoice_no !== undefined && item.invoice_no !== '' ? (item.invoice_no) : ('') }
                                      </TableCell>

                                      <TableCell>
                                        { item.description }
                                      </TableCell>

                                      <TableCell>
                                        { item.media_type }
                                      </TableCell>

                                      <TableCell>
                                        { item.quantity }
                                      </TableCell>

                                      <TableCell>
                                        { item.purpose }
                                      </TableCell>

                                      <TableCell>
                                        { item.remarks }
                                      </TableCell>

                                      <TableCell size="small" className={styles.actionButtonHoverable} align="right">
                                        {
                                          item.can_edit ? (
                                            <Tooltip title="Edit" arrow>
                                              <IconButton
                                                size="small"
                                                id="edit_delivery_detail"
                                                name="edit_delivery_detail"
                                                color="primary"
                                                onClick={() => this.handleOpenDeliveryDetailSimplePopup(item, false, index)}>
                                                <EditIcon/>
                                              </IconButton>
                                            </Tooltip>
                                          ) : (null)
                                        }
                                        {
                                          item.can_delete ? (
                                            <Tooltip title="Delete" arrow>
                                              <IconButton
                                                id="delete_delivery_detail"
                                                name="delete_delivery_detail"
                                                size="small"
                                                color="secondary"
                                                onClick={() => this.handleOpenDeletePopup({ model: "delivery", initialDeliveryDetail: item, openDeleteDeliveryDetailsPopup: true, selectedDeliveryDetailsIndex: parseInt(index) })}
                                              >
                                                <DeleteIcon/>
                                              </IconButton>
                                            </Tooltip>
                                          ) : (null)
                                        }
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ))
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (null)
                    }
                  </div>
                </TabPanel>

                {
                  this.state.current_user.is_from_cdasia ? (
                    <TabPanel value={ this.state.value } index={1}  className={styles.tabListBody}>
                      <ProductTransactionsTab
                        initialProductTransaction={this.state.initialProductTransaction}
                        productTransactions={this.state.productTransactions}
                        handleOpenProductTransactionSimplePopup={this.handleOpenProductTransactionSimplePopup}
                      />
                    </TabPanel>
                  ) : null
                }
              
              <TabPanel value={ this.state.value } index={2}  className={styles.tabListBody}>
                <AuditLog gid={this.state.delivery.gid} />
              </TabPanel>
              
              </div>

              <SimplePopUp
                openPopup={this.state.openNewAndEditDeliveryDetailPopup}
                title={this.state.popupHeader}
                handleClose={this.handleCloseSimplePopup}
                maxWidth={true}
              >
                <DeliveryDetailsForm
                  invoicesAutoCompleteLoading={this.state.invoicesAutoCompleteLoading}
                  productsAutoCompleteLoading={this.state.productsAutoCompleteLoading}
                  invoices={this.state.invoices}
                  products={this.state.products}
                  initialDeliveryDetail={this.state.initialDeliveryDetail}
                  handleAutocomplete={this.handleAutocomplete}
                  handleAutocompleteOnInputChange={this.handleAutocompleteOnInputChange}
                  handleDeliveryDetailsFormInputChange={this.handleDeliveryDetailsFormInputChange}
                  handleSaveDeliveryDetails={this.handleSaveDeliveryDetails}
                  handleSwitch={this.handleSwitch} />
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeleteDeliveryPopup}
                item={this.state.delivery}
                delete={this.handleDeleteDelivery}
                handleDeleteClose={this.handleCloseSimplePopup}
                model={this.state.model}
              />

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeleteDeliveryDetailsPopup}
                item={this.state.initialDeliveryDetail}
                delete={() => this.handleDeleteDeliveryDetails(this.state.initialDeliveryDetail, this.state.selectedDeliveryDetailsIndex)}
                handleDeleteClose={this.handleCloseSimplePopup}
                model={this.state.model}
              />

              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.openAlertMessage}
                type={this.state.type}
                message={this.state.alertMessage}
              />

              <SimplePopUp
                openPopup={this.state.openEditDeliveryPopup}
                title={this.state.popupHeader}
                handleClose={this.handleCloseSimplePopup}
                maxWidth={true}
              >
                <DeliveryForm
                  branches={this.state.branches}
                  employees={this.state.employees}
                  clients={this.state.clients}
                  clientsAutoCompleteLoading={this.state.clientsAutoCompleteLoading}
                  branchesAutoCompleteLoading={this.state.branchesAutoCompleteLoading}
                  selectedClient={this.state.delivery.client}
                  initialDelivery={this.state.initialDelivery}
                  handleAutocomplete={this.handleAutocomplete}
                  handleAutocompleteOnInputChange={this.handleAutocompleteOnInputChange}
                  handleDeliveryFormInputChange={this.handleDeliveryFormInputChange}
                  handleSaveDelivery={this.handleSaveDelivery} />
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openPostDeliveryPopup}
                title="Complete Delivery"
                handleClose={this.handleCloseSimplePopup}
                maxWidth="sm"
              >
                <div>
                  <div>
                    <Typography> Would you like to post this Delivery? </Typography>
                  </div>
                  <div align="right"
                      className={styles.actionButton}>
                    <Button color="primary" variant="outlined"
                            onClick={this.handlePostDelivery}
                    >save</Button></div>
                </div>

              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openUnpostDeliveryPopup}
                title="Unpost Delivery"
                handleClose={this.handleCloseSimplePopup}
                maxWidth="sm"
              >
                <div>
                  <div>
                    <Typography> Would you like to unpost this Delivery? </Typography>
                  </div>
                  <div align="right"
                      className={styles.actionButton}>
                    <Button color="primary" variant="outlined"
                            onClick={this.handleUnpostDelivery}
                    >save</Button></div>
                </div>

              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openNewAndEditProductTransactionPopup}
                title={this.state.popupHeader}
                handleClose={this.handleCloseSimplePopup}
                maxWidth={true}
              >
                <DeliveryProductTransactionForm
                  client={this.state.delivery.client}
                  deliveryDetails= {this.state.deliveryDetails}
                  employees={this.state.employees}
                  products={this.state.deliveredProducts}
                  initialProductTransaction={this.state.initialProductTransaction}
                  handleAutocomplete={this.handleProductTransactionAutocomplete}
                  handleInputChange={this.handleProductTransactionFormInputChange}
                  handleSaveProductTransaction={this.handleSaveProductTransaction}/>
              </SimplePopUp>
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default DeliveryShow

DeliveryShow.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  })
}
