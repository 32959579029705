import React from 'react'
import styles from './Accounts.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  MenuItem,
  Grid, InputLabel
} from '@material-ui/core'
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select/Select";

class AccountsSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      statuses: [
        {
          name: 'Active',
          value: 'active'
        },
        {
          name: 'Receivable',
          value: 'receivable'
        },
        {
          name: 'Installment',
          value: 'installment'
        },
        {
          name: 'Cash',
          value: 'cash'
        },
        {
          name: 'Input Tax',
          value: 'input_tax'
        },
        {
          name: 'Output Tax',
          value: 'output_tax'
        },
        {
          name: 'Withholding Tax',
          value: 'with_holding_tax'
        },
        {
          name: 'Inactive',
          value: 'inactive'
        },
      ]
    }
  }

  render() {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="name"
              fullWidth={true}
              label="Name"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.item.name}
              id="account-name"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="code"
              fullWidth={true}
              label="Code"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.item.code}
              id="payee-name"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth="true" className={styles.textFields} size="small" variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>
              <Select
                fullWidth
                label="Type"
                variant="outlined"
                value={this.props.item.type}
                onChange={this.props.handleChange}
                name="type"
                inputProps={{
                  id: 'outlined-age-native-simple'
                }}
              >
                <MenuItem value=""> Please Select </MenuItem>
                <MenuItem value="Asset">Asset</MenuItem>
                <MenuItem value="Expense">Expense</MenuItem>
                <MenuItem value="Liability">Liability</MenuItem>
                <MenuItem value="Revenue">Revenue</MenuItem>
                <MenuItem value="Capital">Capital</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth="true" className={styles.textFields} size="small" variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
              <Select
                fullWidth
                label="Status"
                variant="outlined"
                defaultValue={'active'}
                value={this.props.item.status}
                onChange={this.props.handleChange}
                name="status"
                inputProps={{
                  id: 'outlined-age-native-simple'
                }}
              >
                {
                  this.state.statuses.map((status, idx) => {
                    const { name, value } = status

                    return <MenuItem key={idx} value={value}>{name}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <div className={styles.actionButton}>
          <Button variant="outlined" id="account-search" color="primary"
                  onClick={() => this.props.search()}>Search</Button>
          <Button variant="outlined" id="account-clearSearch" color="secondary"
                  onClick={() => this.props.clearSearch()}>Clear</Button>
        </div>

      </div>
    )
  }
}

export default AccountsSearch

AccountsSearch.propTypes = {
  handleChange: PropTypes.func,
  item: PropTypes.object,
  productTypes: PropTypes.array,
  search: PropTypes.func,
  clearSearch: PropTypes.func,
  handleKeyPress: PropTypes.func
}
