import React from 'react'

import PropTypes from 'prop-types'
import styles from '../Clients.module.css'

import {
  Table, TableHead, TableBody, TableCell, TableRow, Tooltip, TableContainer
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ShowContactAttributes from '../ClientsTab/ShowContactAttributes'

class ContactPersonDetailTab extends React.Component {
  render() {
    return (
      <div>
        <div className={styles.checkDetailsHeader}>
          <div>
            <h3>
              Contact Person
            </h3>
          </div>

          <div>
            <Tooltip title={`Add Contact Person`} arrow>
              <IconButton color="inherit" aria-label="menu"
                          onClick={() => this.props.handleClick('Add Contact Person', this.props.initialContactPerson, true)}
                          name="create-contactPerson"
              >
                <AddCircleOutlineIcon/></IconButton>
            </Tooltip>
          </div>
        </div>
        <TableContainer style={{position: 'relative'}}>
        <Table size="small" stickyHeader className={styles.contactTableBody}>
          <TableHead>
            <TableRow>
              <TableCell>
                <b> Name</b>
              </TableCell>
              <TableCell>
                <b>Designation</b>
              </TableCell>
              <TableCell>
                <b>Department</b>
              </TableCell>
              <TableCell>
                <b>Remarks</b>
              </TableCell>
              <TableCell className={styles.customCellValue}>
                <TableCell>
                  <b>Primary Contact</b>
                </TableCell>
                <TableCell>
                  <b>Primary Email</b>
                </TableCell>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.props.contactPersons.map((person) => (
              <TableRow key={person.id} className={styles.contactPersonRow}>
                <TableCell>
                  {person.name}
                </TableCell>
                <TableCell>
                  {person.designation}
                </TableCell>
                <TableCell>
                  {person.department}
                </TableCell>
                <TableCell>
                  {person.remarks}
                </TableCell>

                <TableCell className={styles.customCellValue}>
                  <ShowContactAttributes id={person.id}/>
                </TableCell>
                <div className={styles.buttonsHover}>
                  <IconButton
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => this.props.handleClick('Edit Contact Person', person, false)}
                    name={'contactNumber_edit'}
                  >
                    <EditIcon fontSize="medium"/>
                  </IconButton>
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => this.props.handleOpenDelete('contact person', person)}
                    name={'contactNumber_delete'}
                  >
                    <DeleteIcon fontSize="medium"/>
                  </IconButton>
                </div>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>

      </div>
    )
  }
}

export default ContactPersonDetailTab

ContactPersonDetailTab.propTypes = {
  classes: PropTypes.object,
  contactPersons: PropTypes.array,
  handleClick: PropTypes.func,
  handleOpenDelete: PropTypes.func,
  initialContactPerson: PropTypes.object,
}
