import React from 'react'
import styles from './Receipt.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  Grid
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

class ReceiptSearch extends React.Component {
  render() {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              type="date"
              variant="outlined"
              size="small"
              name="date_of_receipt_from"
              fullWidth={true}
              label="First Date"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.searchParams.date_of_receipt_from}
              id="date_of_receipt_from"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              type="date"
              variant="outlined"
              size="small"
              name="date_of_receipt_to"
              fullWidth={true}
              label="End Date"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.searchParams.date_of_receipt_to}
              id="date_of_receipt_to"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              variant="outlined"
              size="small"
              name="reference_no"
              fullWidth={true}
              label="Reference Number"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.searchParams.reference_no}
              id="reference_no"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoComplete="off"
              variant="outlined"
              size="small"
              name="client_name"
              fullWidth={true}
              label="Client Name"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.searchParams.client_name}
              id="client_name"
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              name="branch_id"
              size="small"
              options={this.props.branches}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleBranchAutoComplete}
              fullWidth="true"
              value={this.props.searchParams.branch_id}
              renderInput={(params) => <TextField {...params} label="Branch Name" variant="outlined" />}
            />
          </Grid>
        </Grid>
        <div className={styles.actionButton}>
          <Button variant="outlined" id="payee-clearSearch" color="secondary" onClick={() => this.props.clearSearch()} >Clear</Button>
          <Button variant="outlined" id="payee-search" color="primary" onClick={() => this.props.search()} >Search</Button>
        </div>
      </div>
    )
  }
}
export default ReceiptSearch

ReceiptSearch.propTypes = {
  handleChange: PropTypes.func,
  handleBranchAutoComplete: PropTypes.func,
  searchParams: PropTypes.object,
  branches: PropTypes.array,
  banks: PropTypes.array,
  payees: PropTypes.array,
  search: PropTypes.func,
  clearSearch: PropTypes.func,
  handleKeyPress: PropTypes.func
}
