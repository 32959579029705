import React from 'react'
import styles from '../BalanceSheets/BalanceSheets.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  MenuItem,
  Grid
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

class ConsolidatedBalanceSheetsSearch extends React.Component {
  render() {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              type="date"
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="as_of_date"
              fullWidth={true}
              label="As of Date"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.item.as_of_date}
              id="balanceSheet-as_of_date"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="date"
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="date_to_compare"
              fullWidth={true}
              label="Date to Compare"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.item.date_to_compare}
              id="balanceSheet-date_to_compare"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              value={this.props.item.branch_id}
              id="branch"
              className={styles.autoCompleteField}
              name="branch_id"
              fullWidth
              size="small"
              options={(this.props.branches).sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              })}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleAutoComplete}
              renderInput={(params) => (
                <TextField {...params} name="branch_id" variant="outlined" label="Branch Name" />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              autoComplete="off"
              select
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="is_posted"
              fullWidth={true}
              label="Status"
              onChange={this.props.handleChange}
              value={this.props.item.is_posted || ''}
              id="balanceSheet-classification"
            >
              <MenuItem value=""><div>&nbsp;</div></MenuItem>
              <MenuItem value="true">Posted</MenuItem>
              <MenuItem value="false">Not Posted</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <div className={styles.actionButton}>
          <Button variant="outlined" id="balanceSheet-search" disabled={!this.props.item.as_of_date} color="primary" onClick={() => this.props.search()} >Search</Button>
          <Button variant="outlined" id="balanceSheet-clearSearch" color="secondary" onClick={() => this.props.clearSearch()} >Clear</Button>
        </div>

      </div>
    )
  }
}
export default ConsolidatedBalanceSheetsSearch

ConsolidatedBalanceSheetsSearch.propTypes = {
  handleChange: PropTypes.func,
  item: PropTypes.object,
  branches: PropTypes.array,
  search: PropTypes.func,
  clearSearch: PropTypes.func,
  handleKeyPress: PropTypes.func,
  handleAutoComplete: PropTypes.func
}
