import React from 'react'
import PropTypes from 'prop-types'
import styles from './BalanceSheets.module.css'
import {
  Table, TableHead, TableBody, TableCell, TableRow, IconButton
} from '@material-ui/core'
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CompanyHeader from '../../CompanyHeader/CompanyHeader'

class BalanceSheetsTable extends React.Component {
  getTotal(params, balType) {
    var total = 0
    if (this.props.items.length > 0) {
      this.props.items.map((content) => {
        if (content.type === balType) {
          total = parseFloat(total) + parseFloat(content[params])
        }

      })
    }
    if (balType === "Caes::Capital") {
      if (params === "balance") {
        total = total + parseFloat(this.props.urlPrint["net_income"])
      } else {
        total = total + parseFloat(this.props.urlPrint["compared_net_income"])
      }
    }
    total ? (
      total > 0 ? (
        total = parseFloat(total).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      ) : (
        total = " (" + parseFloat(total * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      )
    ) : null
    return total
  }

  getTotalLiabilityandCapital(params) {
    var total = 0
    if (this.props.items.length > 0) {
      this.props.items.map((content) => {
        if (content.type === "Caes::Liability" || content.type === "Caes::Capital") {
          total = parseFloat(total) + parseFloat(content[params])
        }
      })
    }
    if (params === "balance") {
      total = total + parseFloat(this.props.urlPrint["net_income"])
    } else {
      total = total + parseFloat(this.props.urlPrint["compared_net_income"])
    }
    total ? (
      total > 0 ? (
        total = parseFloat(total).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      ) : (
        total = " (" + parseFloat(total * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      )
    ) : null
    return total
  }
  render() {
    const balTypes = ["Caes::Asset", "Caes::Liability", "Caes::Capital"]
    return (
      <div className={this.props.forPrint ? styles.tableBodyHeight : null}>
        <CompanyHeader forPrint={this.props.forPrint} />
        <div className={styles.checkDetailsHeader}>
          <div>
            <h2 className={styles.detailList}>
              <ListAltIcon className={this.props.forPrint ? styles.noDisplay : null} /> Balance Sheets
            </h2>
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

            <div>{this.props.urlPrint.reports_url !== undefined ? (
              <Tooltip title={`Print ${this.props.model}`} arrow>
                <div className={this.props.forPrint ? styles.noDisplay : null}>
                  {this.props.printPdf}
                </div>
              </Tooltip>
            ) : null}</div>
            {
							this.props.withXlsxDownload && this.props.items.length
							&& !this.props.forPrint ?
							<Tooltip 
								title="Download to .xlsx">
								<IconButton
									color="primary" 
									aria-label='download'
									style={{ pointerEvents: 'auto' }}
									onClick={this.props.handleXlsxDownloadClick}
								>
									<CloudDownloadIcon fontSize='small'/>
								</IconButton>
							</Tooltip> :
							null
						}
          </div>

        </div>

        <Table size="small">
          {
            this.props.urlPrint.net_income !== undefined ? (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b> Account Code</b>
                    </TableCell>
                    <TableCell>
                      <b>Account Title</b>
                    </TableCell>
                    <TableCell align="right">
                      {this.props.urlPrint.compared_net_income !== undefined ? (
                        <b>{this.props.searchItem.date_to_compare ? (new Date(this.props.searchItem.date_to_compare).toLocaleDateString('en-Us', { month: '2-digit', day: '2-digit', year: '2-digit' })) : null}</b>
                      ) : null
                      }
                    </TableCell>
                    <TableCell align="right">
                      {this.props.urlPrint.net_income !== undefined ? (
                        <span><b>{this.props.searchItem.as_of_date ? (new Date(this.props.searchItem.as_of_date).toLocaleDateString('en-Us', { month: '2-digit', day: '2-digit', year: '2-digit' })) : null}</b></span>
                      ) : null
                      }
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    this.props.items.length > 0 ? (
                      <>
                        {balTypes.map((type, idx) => (
                          <>
                            <TableRow key={idx}>
                              <TableCell colspan={4} align="center" className={styles.balanceType}><b><span>{type.replace("Caes::", "")}</span></b></TableCell>
                            </TableRow>
                            {this.props.items.sort(function (a, b) {
                              if (a.code.toLowerCase() < b.code.toLowerCase()) return -1;
                              if (a.code.toLowerCase() > b.code.toLowerCase()) return 1;
                              return 0;
                            }).map((item) => (
                              item.type === type &&
                              <TableRow key={item.id}>
                                <TableCell>
                                  {item.code}
                                </TableCell>
                                <TableCell>
                                  {item.name}
                                </TableCell>
                                <TableCell align="right">
                                  {
                                    this.props.urlPrint.compared_net_income !== undefined ? (
                                      item.compared_balance === 0 ? ("0.00") : (item.compared_balance > 0 ? (
                                        parseFloat(item.compared_balance).toLocaleString(navigator.language, { minimumFractionDigits: 2 }
                                        )) : (
                                        "( " + parseFloat(item.compared_balance * -1)
                                          .toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
                                      ))
                                    ) : null
                                  }
                                </TableCell>
                                <TableCell align="right">
                                  {
                                    item.balance !== undefined ? (
                                      item.balance === 0 ? ("0.00") : (item.balance > 0 ? (
                                        parseFloat(item.balance).toLocaleString(navigator.language, { minimumFractionDigits: 2 }
                                        )) : (
                                        "( " + parseFloat(item.balance * -1)
                                          .toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
                                      ))
                                    ) : null
                                  }
                                </TableCell>
                              </TableRow>
                            ))}
                            {
                              type === "Caes::Capital" ? (
                                < TableRow >
                                  <TableCell>
                                  </TableCell>
                                  <TableCell>
                                    <b>Net Income</b>
                                  </TableCell>
                                  <TableCell align="right">
                                    {
                                      this.props.urlPrint.compared_net_income !== undefined ? (
                                        this.props.urlPrint.compared_net_income === 0 ? (
                                          "0.00"
                                        ) : (
                                          this.props.urlPrint.compared_net_income > 0 ? (
                                            parseFloat(this.props.urlPrint.compared_net_income).toLocaleString(navigator.language, { minimumFractionDigits: 2 }
                                            )) : (
                                            " (" + parseFloat(this.props.urlPrint.compared_net_income * -1)
                                              .toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + " )"
                                          )
                                        )
                                      ) : null
                                    }
                                  </TableCell>
                                  <TableCell align="right">
                                    {

                                      this.props.urlPrint.net_income !== undefined ? (
                                        this.props.urlPrint.net_income === 0 ? ("0.00") : (this.props.urlPrint.net_income > 0 ? (
                                          parseFloat(this.props.urlPrint.net_income).toLocaleString(navigator.language, { minimumFractionDigits: 2 }
                                          )) : (
                                          "( " + parseFloat(this.props.urlPrint.net_income * -1)
                                            .toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
                                        ))
                                      ) : null
                                    }
                                  </TableCell>
                                </TableRow>
                              ) : null
                            }
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell align="right" ><b>{`Total ${type.replace("Caes::", "")} :`}</b></TableCell>
                              <TableCell align="right" className={styles.totalCell}>
                                {
                                  this.props.urlPrint.compared_net_income !== undefined ? (
                                    <b>{this.getTotal('compared_balance', type).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</b>) : null
                                }
                              </TableCell>
                              <TableCell align="right" className={styles.totalCell}><b>{this.getTotal('balance', type).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</b></TableCell>
                            </TableRow>
                          </>
                        ))}
                      </>
                    ) : null
                  }
                </TableBody>


                {
                  <TableBody>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="right" >

                        <b> Total Liability and Capital :</b>

                      </TableCell>

                      <TableCell align="right" className={styles.grandTotalCell}>{
                        this.props.urlPrint.compared_net_income !== undefined ? (
                          <b>{this.getTotalLiabilityandCapital('compared_balance').toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</b>
                        ) : null
                      }
                      </TableCell>

                      <TableCell align="right" className={styles.grandTotalCell}>
                        <b>{this.getTotalLiabilityandCapital('balance').toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                }
              </>
            ) : null
          }
        </Table>
      </div >
    )
  }
}

export default BalanceSheetsTable

BalanceSheetsTable.propTypes = {
  classes: PropTypes.object,
  items: PropTypes.array,
  urlPrint: PropTypes.object,
  model: PropTypes.string,
  searchItem: PropTypes.object,
  printPdf: PropTypes.object,
  forPrint: PropTypes.bool,
  withXlsxDownload: PropTypes.bool,
	handleXlsxDownloadClick: PropTypes.func
}
