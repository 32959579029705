import React from 'react'
import PropTypes from 'prop-types'
import styles from '../../SimpleTable/SimpleTable.module.css'
import style from './IncomeStatements.module.css'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { IconButton, Tooltip } from "@material-ui/core";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CompanyHeader from '../../CompanyHeader/CompanyHeader'

class IncomeStatementTable extends React.Component {
  getAmount(dateVariable, params) {
    var totalSales = 0
    if (this.props.item.consolidated_income_statement_attributes[params].length > 0) {
      this.props.item.consolidated_income_statement_attributes[params].map((sale) => {

        if (new Date(sale.date).toLocaleDateString() === new Date(dateVariable).toLocaleDateString()) {
          totalSales = totalSales + sale.amount
        }
      })
    }
    if (totalSales) {
      if (totalSales > 0) {
        totalSales = parseFloat(totalSales).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalSales = "(" + parseFloat(totalSales * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalSales = "0.00" }

    return totalSales
  }
  getAmountWithMonths(monthVariable, dateVariable, params) {
    var totalSales = 0
    if (this.props.item.consolidated_income_statement_attributes[params].length > 0) {
      this.props.item.consolidated_income_statement_attributes[params].map((sale) => {
        if (sale.month === monthVariable && monthVariable !== new Date(dateVariable).getMonth() + 1) {
          totalSales = totalSales + sale.amount
        }
      })
    }
    if (totalSales) {
      if (totalSales > 0) {
        totalSales = parseFloat(totalSales).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalSales = "(" + parseFloat(totalSales * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalSales = "0.00" }

    return totalSales
  }
  getIncomeTax(dateVariable, params) {
    var totalSales = 0
    if (this.props.item.consolidated_income_statement_attributes[params].length > 0) {
      this.props.item.consolidated_income_statement_attributes[params].map((sale) => {

        if (sale.month === new Date(dateVariable).getMonth() + 1) {
          totalSales = totalSales + sale.amount
        }
      })
    }
    if (totalSales) {
      if (totalSales > 0) {
        totalSales = parseFloat(totalSales).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalSales = "(" + parseFloat(totalSales * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalSales = "0.00" }

    return totalSales
  }
  getIncomeTaxWithMonths(monthVariable, dateVariable, params) {
    var totalSales = 0
    if (this.props.item.consolidated_income_statement_attributes[params].length > 0) {
      this.props.item.consolidated_income_statement_attributes[params].map((sale) => {
        if (sale.month === monthVariable && monthVariable !== new Date(dateVariable).getMonth() + 1) {
          totalSales = totalSales + sale.amount
        }
      })
    }
    if (totalSales) {
      if (totalSales > 0) {
        totalSales = parseFloat(totalSales).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalSales = "(" + parseFloat(totalSales * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalSales = "0.00" }

    return totalSales
  }

  getAmountFromLessBalance(dateVariable, params) {
    var totalSales = 0
    if (this.props.item.consolidated_income_statement_attributes.less_balances[params].length > 0) {
      this.props.item.consolidated_income_statement_attributes.less_balances[params].map((sale) => {

        if (new Date(sale.date).toLocaleDateString() === new Date(dateVariable).toLocaleDateString()) {
          totalSales = totalSales + sale.amount
        }
      })
    }
    if (totalSales) {
      if (totalSales > 0) {
        totalSales = parseFloat(totalSales).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalSales = "(" + parseFloat(totalSales * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalSales = "0.00" }

    return totalSales
  }

  getAmountFromLessBalanceWithMonths(monthVariable, dateVariable, params) {
    var totalSales = 0
    if (this.props.item.consolidated_income_statement_attributes.less_balances[params].length > 0) {
      this.props.item.consolidated_income_statement_attributes.less_balances[params].map((sale) => {
        if (sale.month === monthVariable && monthVariable !== new Date(dateVariable).getMonth() + 1) {
          totalSales = totalSales + sale.amount
        }
      })
    }
    if (totalSales) {
      if (totalSales > 0) {
        totalSales = parseFloat(totalSales).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalSales = "(" + parseFloat(totalSales * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalSales = "0.00" }

    return totalSales
  }

  getAmountFromOperatingExpenses(dateVariable, params) {
    var totalSales = 0
    if (this.props.item.consolidated_income_statement_attributes.operating_expenses[params].length > 0) {
      this.props.item.consolidated_income_statement_attributes.operating_expenses[params].map((sale) => {

        if (new Date(sale.date).toLocaleDateString() === new Date(dateVariable).toLocaleDateString()) {
          totalSales = totalSales + sale.amount
        }
      })
    }
    if (totalSales) {
      if (totalSales > 0) {
        totalSales = parseFloat(totalSales).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalSales = "(" + parseFloat(totalSales * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalSales = "0.00" }

    return totalSales
  }
  getAmountFromOperatingExpensesWithMonths(monthVariable, dateVariable, params) {
    var totalSales = 0
    if (this.props.item.consolidated_income_statement_attributes.operating_expenses[params].length > 0) {
      this.props.item.consolidated_income_statement_attributes.operating_expenses[params].map((sale) => {

        if (sale.month === monthVariable && monthVariable !== new Date(dateVariable).getMonth() + 1) {
          totalSales = totalSales + sale.amount
        }
      })
    }
    if (totalSales) {
      if (totalSales > 0) {
        totalSales = parseFloat(totalSales).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalSales = "(" + parseFloat(totalSales * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalSales = "0.00" }

    return totalSales
  }


  getAmountFromOtherIncome(dateVariable, params) {
    var totalSales = 0
    if (this.props.item.consolidated_income_statement_attributes.other_income_expenses[params].length > 0) {
      this.props.item.consolidated_income_statement_attributes.other_income_expenses[params].map((sale) => {

        if (new Date(sale.date).toLocaleDateString() === new Date(dateVariable).toLocaleDateString()) {
          totalSales = totalSales + sale.amount
        }
      })
    }
    if (totalSales) {
      if (totalSales > 0) {
        totalSales = parseFloat(totalSales).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalSales = "(" + parseFloat(totalSales * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalSales = "0.00" }

    return totalSales
  }

  getAmountFromOtherIncomeWithMonths(monthVariable, dateVariable, params) {
    var totalSales = 0
    if (this.props.item.consolidated_income_statement_attributes.other_income_expenses[params].length > 0) {
      this.props.item.consolidated_income_statement_attributes.other_income_expenses[params].map((sale) => {

        if (sale.month === monthVariable && monthVariable !== new Date(dateVariable).getMonth() + 1) {
          totalSales = totalSales + sale.amount
        }
      })
    }
    if (totalSales) {
      if (totalSales > 0) {
        totalSales = parseFloat(totalSales).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalSales = "(" + parseFloat(totalSales * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalSales = "0.00" }

    return totalSales
  }

  getTotalRevenue(balType) {
    var totalRevenue = 0
    if (this.props.items.length > 0) {
      this.props.items.map((item) => {
        if (item.type === balType) {
          totalRevenue = totalRevenue + parseFloat(item.balance)
        }
      })
    }
    if (totalRevenue) {
      if (totalRevenue > 0) {
        totalRevenue = parseFloat(totalRevenue).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalRevenue = "(" + parseFloat(totalRevenue * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalRevenue = "0.00" }
    return totalRevenue

  }
  getTotalRevenueWithMonth(val, balType) {
    var totalRevenue = 0
    if (this.props.items.length > 0) {
      this.props.items.map((item) => {
        if (item.type === balType) {
          if (item.month_balance.length > 0) {
            item.month_balance.map((monthBal) => {
              if (monthBal.month.toString() === val.toString()) {
                totalRevenue = totalRevenue + parseFloat(monthBal.amount)
              }
            })
          }
        }
      })
    }
    if (totalRevenue) {
      if (totalRevenue > 0) {
        totalRevenue = parseFloat(totalRevenue).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalRevenue = "(" + parseFloat(totalRevenue * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalRevenue = "0.00" }
    return totalRevenue

  }

  getTotalComparedMonth(val){
    var total = 0
    if(this.props.urlPrint){
      if(this.props.urlPrint.compared_income!==undefined&&this.props.urlPrint.compared_income.length>0){
        this.props.urlPrint.compared_income.map((compared)=>{
          if(compared.month === val.toString())
          {
            total = compared.amount
          }
        })
      }
    }
    if (total) {
      if (total > 0) {
        total = parseFloat(total).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        total = "(" + parseFloat(total * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { total = "0.00" }
    return total
  }


  render() {
    var monthsLength = 0
    if (this.props.searchItem.months !== undefined) {
      monthsLength = this.props.searchItem.months.length
    }
    return (
      <div className={this.props.forPrint? style.table:null}>
        <CompanyHeader forPrint={this.props.forPrint}/>
        <>
          <div className={styles.tableTitle}>
            <div className={styles.groupTileSearch}>
              <div className={styles.tileBody}>
                {this.props.forPrint? null: this.props.icon}
                <h2 className={style.titleIncome}>
                  <div className={styles.head}>
                    <div>{this.props.model.replace('_', ' ') + 's'}</div>
                  </div>
                </h2>
              </div>

            </div>
            <div className={this.props.forPrint? style.noDisplay: styles.rightAction}>
              <div className={this.props.forPrint? style.noDisplay: styles.buttonDisbursement}>
                {this.props.urlPrint.reports_url !== undefined ? (
                  <Tooltip title={`Print ${this.props.model}`} arrow>
                    {this.props.print}
                  </Tooltip>
                ) : null}
                {
                  this.props.withXlsxDownload && this.props.items.length
                  && !this.props.forPrint && this.props.searchItem.as_of_date ?
                  <Tooltip 
                    title="Download to .xlsx">
                    <IconButton
                      color="primary" 
                      aria-label='download'
                      style={{ pointerEvents: 'auto' }}
                      onClick={this.props.handleXlsxDownloadClick}
                    >
                      <CloudDownloadIcon fontSize='small'/>
                    </IconButton>
                  </Tooltip> :
                  null
                }
              </div>
            </div>
          </div>
          {
            this.props.item !== undefined && this.props.item !== null ? (
              <Table size="small"
                name={this.props.model.toLowerCase().replace(/ /g, '_') + "_table"}>
                <TableHead className={styles.tableHeadBody}>
                  <TableRow>
                    {
                      this.props.searchItem.as_of_date ? (
                        <>
                          <TableCell
                            className={styles.cell}><b></b>
                          </TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.sort(function (a, b) {
                                  if (a.value < b.value) return -1;
                                  if (a.value > b.value) return 1;
                                  return 0;
                                }).map((month, idx) => (
                                  <TableCell key={idx}
                                    className={styles.cell}><b>{month.name} {new Date(this.props.searchItem.as_of_date).getFullYear()}</b>
                                  </TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          <TableCell
                            className={styles.cell}><b>{`AS OF DATE: ${new Date(this.props.searchItem.as_of_date).toLocaleDateString('en-Us')}`}</b>
                          </TableCell>
                        </>
                      ) : null}

                  </TableRow>
                </TableHead>
                <TableBody className={styles.tableBody}>
                  {
                    this.props.item.consolidated_income_statement_attributes ? (
                      <>
                        <TableRow>
                          <TableCell>Sales</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountWithMonths(month.value, this.props.searchItem.as_of_date, "sales")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmount(this.props.searchItem.as_of_date, "sales")}</TableCell>
                            ) : null
                          }

                        </TableRow>
                        <TableRow>
                          <TableCell>Income Special Projects</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountWithMonths(month.value, this.props.searchItem.as_of_date, "income_special_projects")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmount(this.props.searchItem.as_of_date, "income_special_projects")}</TableCell>
                            ) : null
                          }

                        </TableRow>
                        <TableRow>
                          <TableCell>Less :</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={style.marginCell}>Direct Cost of Sales</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountFromLessBalanceWithMonths(month.value, this.props.searchItem.as_of_date, "direct_cost_of_sales")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmountFromLessBalance(this.props.searchItem.as_of_date, "direct_cost_of_sales")}</TableCell>
                            ) : null
                          }

                        </TableRow>
                        <TableRow>
                          <TableCell className={style.marginCell}>Other Cost of Sales</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountFromLessBalanceWithMonths(month.value, this.props.searchItem.as_of_date, "other_cost_of_sales")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmountFromLessBalance(this.props.searchItem.as_of_date, "other_cost_of_sales")}</TableCell>
                            ) : null
                          }

                        </TableRow>
                        <TableRow>
                          <TableCell className={style.marginCell}>Sub Total</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountFromLessBalanceWithMonths(month.value, this.props.searchItem.as_of_date, "sub_total")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmountFromLessBalance(this.props.searchItem.as_of_date, "sub_total")}</TableCell>
                            ) : null
                          }

                        </TableRow>
                        <TableRow>
                          <TableCell>Gross Profit</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountWithMonths(month.value, this.props.searchItem.as_of_date, "gross_profit")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmount(this.props.searchItem.as_of_date, "gross_profit")}</TableCell>
                            ) : null
                          }


                        </TableRow>
                        <TableRow>
                          <TableCell>Operating Expenses</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={style.marginCell}>General Administration</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountFromOperatingExpensesWithMonths(month.value, this.props.searchItem.as_of_date, "general_administration")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmountFromOperatingExpenses(this.props.searchItem.as_of_date, "general_administration")}</TableCell>
                            ) : null
                          }

                        </TableRow>
                        <TableRow>
                          <TableCell className={style.marginCell}>Premises</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountFromOperatingExpensesWithMonths(month.value, this.props.searchItem.as_of_date, "premises")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmountFromOperatingExpenses(this.props.searchItem.as_of_date, "premises")}</TableCell>
                            ) : null
                          }

                        </TableRow>
                        <TableRow>
                          <TableCell className={style.marginCell}>Selling and Marketing Expenses</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountFromOperatingExpensesWithMonths(month.value, this.props.searchItem.as_of_date, "selling_and_marketing_expenses")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmountFromOperatingExpenses(this.props.searchItem.as_of_date, "selling_and_marketing_expenses")}</TableCell>
                            ) : null
                          }

                        </TableRow>
                        <TableRow>

                          <TableCell className={style.marginCell}>Staff Costs</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountFromOperatingExpensesWithMonths(month.value, this.props.searchItem.as_of_date, "staff_costs")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmountFromOperatingExpenses(this.props.searchItem.as_of_date, "staff_costs")}</TableCell>
                            ) : null
                          }

                        </TableRow>
                        <TableRow>
                          <TableCell className={style.marginCell}>Sub Total</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountFromOperatingExpensesWithMonths(month.value, this.props.searchItem.as_of_date, "sub_total")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmountFromOperatingExpenses(this.props.searchItem.as_of_date, "sub_total")}</TableCell>
                            ) : null
                          }

                        </TableRow>
                        <TableRow>
                          <TableCell className={style.marginCell}>Utilities</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountFromOperatingExpensesWithMonths(month.value, this.props.searchItem.as_of_date, "utilities")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmountFromOperatingExpenses(this.props.searchItem.as_of_date, "utilities")}</TableCell>
                            ) : null
                          }

                        </TableRow>
                        <TableRow>
                          <TableCell>Operating Income</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountWithMonths(month.value, this.props.searchItem.as_of_date, "operating_income")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmount(this.props.searchItem.as_of_date, "operating_income")}</TableCell>
                            ) : null
                          }

                        </TableRow>
                        <TableRow>
                          <TableCell>Other Income (Expenses)</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={style.marginCell}>Bank Charges</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountFromOtherIncomeWithMonths(month.value, this.props.searchItem.as_of_date, "bank_charges")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmountFromOtherIncome(this.props.searchItem.as_of_date, "bank_charges")}</TableCell>
                            ) : null
                          }

                        </TableRow>
                        <TableRow>
                          <TableCell className={style.marginCell}>Foreign Exchange</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountFromOtherIncomeWithMonths(month.value, this.props.searchItem.as_of_date, "foreign_exchange")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmountFromOtherIncome(this.props.searchItem.as_of_date, "foreign_exchange")}</TableCell>
                            ) : null
                          }
                        </TableRow>
                        <TableRow>
                          <TableCell className={style.marginCell}>Interest Income</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountFromOtherIncomeWithMonths(month.value, this.props.searchItem.as_of_date, "interest_income")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmountFromOtherIncome(this.props.searchItem.as_of_date, "interest_income")}</TableCell>
                            ) : null
                          }
                        </TableRow>
                        <TableRow>
                          <TableCell className={style.marginCell}>Miscellaneous Expense</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountFromOtherIncomeWithMonths(month.value, this.props.searchItem.as_of_date, "miscellaneous_expense")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmountFromOtherIncome(this.props.searchItem.as_of_date, "miscellaneous_expense")}</TableCell>
                            ) : null
                          }
                        </TableRow>
                        <TableRow>
                          <TableCell className={style.marginCell}>Miscellaneous Income</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountFromOtherIncomeWithMonths(month.value, this.props.searchItem.as_of_date, "miscellaneous_income")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmountFromOtherIncome(this.props.searchItem.as_of_date, "miscellaneous_income")}</TableCell>
                            ) : null
                          }
                        </TableRow>
                        <TableRow>
                          <TableCell className={style.marginCell}>Other Income</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountFromOtherIncomeWithMonths(month.value, this.props.searchItem.as_of_date, "other_income")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmountFromOtherIncome(this.props.searchItem.as_of_date, "other_income")}</TableCell>
                            ) : null
                          }
                        </TableRow>
                        <TableRow>
                          <TableCell className={style.marginCell}>Service Income</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountFromOtherIncomeWithMonths(month.value, this.props.searchItem.as_of_date, "service_income")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmountFromOtherIncome(this.props.searchItem.as_of_date, "service_income")}</TableCell>
                            ) : null
                          }
                        </TableRow>
                        <TableRow>
                          <TableCell className={style.marginCell}>Sub Total</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountFromOtherIncomeWithMonths(month.value, this.props.searchItem.as_of_date, "sub_total")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmountFromOtherIncome(this.props.searchItem.as_of_date, "sub_total")}</TableCell>
                            ) : null
                          }

                        </TableRow>
                        <TableRow>
                          <TableCell><b>Net Income(Loss) Before Tax</b></TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountWithMonths(month.value, this.props.searchItem.as_of_date, "net_income_before_income_tax")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmount(this.props.searchItem.as_of_date, "net_income_before_income_tax")}</TableCell>
                            ) : null
                          }
                        </TableRow>
                        <TableRow>
                          <TableCell>Provision for Income Tax</TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}>{this.getAmountWithMonths(month.value, this.props.searchItem.as_of_date, "provision_for_income_tax")}</TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}>{this.getAmount(this.props.searchItem.as_of_date, "provision_for_income_tax")}</TableCell>
                            ) : null
                          }

                        </TableRow>
                        <TableRow>
                          <TableCell><b>Net Income After Tax</b></TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.map((month, idx) => (
                                  <TableCell key={idx} className={styles.amount}><b>{this.getIncomeTaxWithMonths(month.value, this.props.searchItem.as_of_date, "net_income_after_tax")}</b></TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          {
                            this.props.searchItem.as_of_date ? (
                              <TableCell className={styles.amount}><b>{this.getIncomeTax(this.props.searchItem.as_of_date, "net_income_after_tax")}</b></TableCell>
                            ) : null
                          }
                        </TableRow>
                      </>

                    ) : null
                  }
                </TableBody>
              </Table>
            ) : null
          }

        </>

        {
          this.props.notConsolidated ? (
            <>
              <Table size="small">
                <TableHead className={styles.tableHeadBody}>
                  <TableRow>
                    {
                      this.props.searchItem.as_of_date ? (
                        <>
                          <TableCell
                            className={styles.cell}><b>Code</b>
                          </TableCell>
                          <TableCell
                            className={styles.cell}><b>Account Name</b>
                          </TableCell>
                          {
                            this.props.searchItem.months ? (
                              this.props.searchItem.months.length > 0 ? (
                                this.props.searchItem.months.sort(function (a, b) {
                                  if (a.value < b.value) return -1;
                                  if (a.value > b.value) return 1;
                                  return 0;
                                }).map((month, idx) => (
                                  <TableCell key={idx}
                                    className={styles.cell}><b>{month.name} {new Date(this.props.searchItem.as_of_date).getFullYear()}</b>
                                  </TableCell>
                                ))
                              ) : null
                            ) : null
                          }
                          <TableCell
                            className={styles.cell}><b>{`AS OF DATE: ${new Date(this.props.searchItem.as_of_date).toLocaleDateString('en-Us')}`}</b>
                          </TableCell>
                        </>
                      ) : null}

                  </TableRow>
                </TableHead>

                <TableBody>

                  <TableRow >
                    <TableCell colspan={3 + monthsLength} style={{ backgroundColor: 'cadetblue' }} align="left"><b>REVENUE</b></TableCell>
                  </TableRow>
                  {this.props.items.sort(function (a, b) {
                    if (a.code.toLowerCase() < b.code.toLowerCase()) return -1;
                    if (a.code.toLowerCase() > b.code.toLowerCase()) return 1;
                    return 0;
                  })
                    .map((item) => (
                      item.type === "Caes::Revenue" &&
                      <TableRow key={item.id}>
                        <TableCell>
                          {item.code}
                        </TableCell>
                        <TableCell>
                          {item.name}
                        </TableCell>
                        {
                          this.props.searchItem.months ? (
                            item.month_balance.length > 0 ? (
                              item.month_balance.sort(function (a, b) {
                                if (a.value < b.value) return -1;
                                if (a.value > b.value) return 1;
                                return 0;
                              }).map((month, idx) => (
                                <TableCell key={idx} className={styles.amount}>
                                  {month.amount ?
                                    month.amount > 0 ?
                                      parseFloat(month.amount).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
                                      : "(" + parseFloat(month.amount * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
                                    : parseFloat(month.amount).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                                </TableCell>
                              ))
                            ) : null
                          ) : null
                        }
                        <TableCell align="right">
                          {item.balance ?
                            item.balance > 0 ?
                              parseFloat(item.balance).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
                              : "(" + parseFloat(item.balance * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
                            : parseFloat(item.balance).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                        </TableCell>

                      </TableRow>
                    ))}

                  <TableRow >
                    <TableCell ></TableCell>
                    <TableCell align="right"><b>Total Revenue</b></TableCell>
                    {
                      this.props.searchItem.months ? (
                        this.props.searchItem.months.length > 0 ? (
                          this.props.searchItem.months.sort(function (a, b) {
                            if (a.value < b.value) return -1;
                            if (a.value > b.value) return 1;
                            return 0;
                          }).map((month, idx) => (
                            <TableCell key={idx} className={styles.amount}><b>{this.getTotalRevenueWithMonth(month.value, "Caes::Revenue")}</b></TableCell>
                          ))
                        ) : null
                      ) : null
                    }
                    <TableCell align="right"><b>{this.getTotalRevenue("Caes::Revenue")}</b></TableCell>
                  </TableRow>

                  <TableRow >
                    <TableCell colspan={3 + monthsLength} style={{ backgroundColor: 'cadetblue' }} align="left"><b>EXPENSE</b></TableCell>
                  </TableRow>

                  {this.props.items.sort(function (a, b) {
                    if (a.code.toLowerCase() < b.code.toLowerCase()) return -1;
                    if (a.code.toLowerCase() > b.code.toLowerCase()) return 1;
                    return 0;
                  })
                    .map((item) => (
                      item.type === "Caes::Expense" &&
                      <TableRow key={item.id}>
                        <TableCell>
                          {item.code}
                        </TableCell>
                        <TableCell>
                          {item.name}
                        </TableCell>
                        {
                          this.props.searchItem.months ? (
                            item.month_balance.length > 0 ? (
                              item.month_balance.sort(function (a, b) {
                                if (a.value < b.value) return -1;
                                if (a.value > b.value) return 1;
                                return 0;
                              }).map((month, idx) => (
                                <TableCell key={idx} className={styles.amount}>
                                  {month.amount ?
                                    month.amount > 0 ?
                                      parseFloat(month.amount).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
                                      : "(" + parseFloat(month.amount * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
                                    : parseFloat(month.amount).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                                </TableCell>
                              ))
                            ) : null
                          ) : null
                        }
                        <TableCell align="right">
                          {item.balance ?
                            item.balance > 0 ?
                              parseFloat(item.balance).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
                              : "(" + parseFloat(item.balance * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
                            : parseFloat(item.balance).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                        </TableCell>

                      </TableRow>
                    ))}
                  <TableRow >
                    <TableCell ></TableCell>
                    <TableCell align="right"><b>Total Expense</b></TableCell>
                    {
                      this.props.searchItem.months ? (
                        this.props.searchItem.months.length > 0 ? (
                          this.props.searchItem.months.sort(function (a, b) {
                            if (a.value < b.value) return -1;
                            if (a.value > b.value) return 1;
                            return 0;
                          }).map((month, idx) => (
                            <TableCell key={idx} className={styles.amount}><b>{this.getTotalRevenueWithMonth(month.value, "Caes::Expense")}</b></TableCell>
                          ))
                        ) : null
                      ) : null
                    }
                    <TableCell align="right"><b>{this.getTotalRevenue("Caes::Expense")}</b></TableCell>
                  </TableRow>
                </TableBody>
                <TableRow className="fixWidth">
                  <TableCell className={styles.cellNone}></TableCell>
                  <TableCell style={{color:'#b71c1c'}}align="right"><b>NET INCOME</b></TableCell>
                  {
                    this.props.searchItem.months ? (
                      this.props.searchItem.months.length > 0 ? (
                        this.props.searchItem.months.sort(function (a, b) {
                          if (a.value < b.value) return -1;
                          if (a.value > b.value) return 1;
                          return 0;
                        }).map((month, idx) => (
                          <TableCell style={{borderBottom:'double'}}  key={idx} className={styles.amount}><b>{this.getTotalComparedMonth(month.value)}</b></TableCell>
                        ))
                      ) : null
                    ) : null
                  }
                  <TableCell style={{borderBottom:'double'}} align="right"><b>{
                  this.props.urlPrint?(
                    this.props.urlPrint.net_income===0?(
                      "0.00"
                    ):(
                      this.props.urlPrint.net_income > 0 ?
                          parseFloat(this.props.urlPrint.net_income).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
                          : "(" + parseFloat(this.props.urlPrint.net_income * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
                    )
                  ):null
                  }</b></TableCell>
                </TableRow>


              </Table>


            </>
          ) : null
        }
      </div>

    )
  }
}

export default IncomeStatementTable

IncomeStatementTable.propTypes = {
  headers: PropTypes.array,
  item: PropTypes.object,
  icon: PropTypes.object,
  model: PropTypes.string,
  urlPrint: PropTypes.object,
  withPrint: PropTypes.bool,
  months: PropTypes.array,
  searchItem: PropTypes.object,
  notConsolidated: PropTypes.bool,
  items: PropTypes.array,
  print: PropTypes.object,
  forPrint: PropTypes.bool,
  withXlsxDownload: PropTypes.bool,
  handleXlsxDownloadClick: PropTypes.func
}
