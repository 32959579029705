import React from 'react'

import PropTypes from 'prop-types'
import moment from 'moment';
import 'moment-timezone';
import styles from '../Invoices.module.css'

import {
  Table, TableHead, TableBody, TableCell, TableRow,TableContainer
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import PrintIcon from '@material-ui/icons/Print';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import SyncAltOutlinedIcon from '@material-ui/icons/SyncAltOutlined';
class JournalEntriesDetailTab extends React.Component {
  getTotalDebit () {
    var totalDebit = 0
    if(this.props.journalEntries.length>0){
      this.props.journalEntries.map((content)=>{
        totalDebit = parseFloat(totalDebit) + parseFloat(content.amount_debit)
      })
    }
    return totalDebit
  }

  getTotalCredit () {
    var totalCredit = 0
    if(this.props.journalEntries.length>0){
      this.props.journalEntries.map((content)=>{
        totalCredit = parseFloat(totalCredit) + parseFloat(content.amount_credit)
      })
    }
    return totalCredit
  }
  render() {
    return (
      <div>
        <div className={this.props.forPrint ? styles.noDisplay : styles.checkDetailsHeader}>
          <div className={styles.actionJournalBody}>
            <Tooltip title="Reverse Journal" arrow>
              <IconButton name="reverse_journal" onClick={this.props.reverseJournal}>
                <SyncAltOutlinedIcon style={{color: 'orangered'}}/>
              </IconButton>
            </Tooltip>

            <Tooltip title="Print Journal" arrow>
              <IconButton onClick={this.props.openPrint} color="primary">
                <PrintIcon/>
              </IconButton>
            </Tooltip>
            {this.props.invoice.journal !==null?(
              this.props.invoice.journal.can_post && this.props.invoice.status === 'for_posting' && !this.props.invoice.journal.is_posted ? (
                  this.props.invoice.journal.can_post === true ? (
                    <Tooltip title="Post Journal" arrow>
                      <IconButton onClick={this.props.activeJournal}>
                        <CheckCircleOutlineIcon/>
                      </IconButton>
                    </Tooltip>
                  ):null
                ) :
                this.props.invoice.journal.can_unpost === true ? (
                  <Tooltip title="Unpost Journal" arrow>
                    <IconButton onClick={this.props.cancelJournal}>
                      <CancelIcon/>
                    </IconButton>
                  </Tooltip>
                ):null
            ):null
            }
          </div>
        </div>
          <div className={styles.journalBody}>
            <div  className={styles.journalList}>
              <div><b>Reference number: </b></div>
              <div name='journal_reference_number'>{this.props.invoice.journal.reference_no}</div>
            </div>
            <div className={styles.journalList}>
              <div><b>Date of Journal :</b></div>
              <div name='date_of_journal'>{moment(this.props.invoice.journal.date_of_journal).format("MM/DD/yyyy")}</div>
            </div>
          </div>
        <TableContainer style={{maxHeight: '575px', position: 'relative'}}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
               <b> Account Code</b>
              </TableCell>
              <TableCell>
                <b>Account Title</b>
              </TableCell>
              <TableCell align="right">
                <b>Debit</b>
              </TableCell>
              <TableCell align="right">
                <b>Credit</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.props.journalEntries.sort(function (a, b) {
              if (a.account.code.toLowerCase() < b.account.code.toLowerCase()) return -1;
              if (a.account.code.toLowerCase() > b.account.code.toLowerCase()) return 1;
              return 0;
            }).map((journal) => (
                journal.amount_credit==="0.0" &&
            <TableRow  key={journal.id}>
              <TableCell>
                {journal.account.code}
              </TableCell>

              <TableCell>
                {journal.account.name}
              </TableCell>
              <TableCell align="right">
                {parseFloat(journal.amount_debit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
              </TableCell>
              <TableCell align="right">
                {parseFloat(journal.amount_credit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
              </TableCell>

            </TableRow>
            ))}
            {this.props.journalEntries.sort(function (a, b) {
              if (a.account.code.toLowerCase() < b.account.code.toLowerCase()) return -1;
              if (a.account.code.toLowerCase() > b.account.code.toLowerCase()) return 1;
              return 0;
            }).map((journal) => (
              journal.amount_credit!=="0.0"&&
              <TableRow  key={journal.id}>
                <TableCell>
                  {journal.account.code}
                </TableCell>
                <TableCell>
                  {journal.account.name}
                </TableCell>
                <TableCell align="right">
                  {parseFloat(journal.amount_debit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                </TableCell>
                <TableCell align="right">
                  {parseFloat(journal.amount_credit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                </TableCell>

              </TableRow>
            ))}
            <TableRow>
            <TableCell></TableCell>
            <TableCell align="right"><b> Total :</b></TableCell>
            <TableCell align="right"><b>{this.getTotalDebit().toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b></TableCell>
            <TableCell align="right"><b>{this.getTotalCredit().toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b></TableCell>
          </TableRow>

          </TableBody>
        </Table>
        </TableContainer>
      </div>
    )
  }
}

export default JournalEntriesDetailTab

JournalEntriesDetailTab.propTypes = {
  classes: PropTypes.object,
  invoice: PropTypes.object,
  journalEntries: PropTypes.array,
  activeJournal: PropTypes.func,
  cancelJournal: PropTypes.func,
  reverseJournal: PropTypes.func,
  openPrint: PropTypes.func,
  forPrint: PropTypes.bool,
}
