import React from 'react'
import PropTypes from 'prop-types'
import styles from './Journal.module.css'
import {
  Table, TableHead, TableBody, TableCell, TableRow
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import SyncAltOutlinedIcon from '@material-ui/icons/SyncAltOutlined';

class JournalEntriesDetailTab extends React.Component {
  getTotalDebit() {
    var totalDebit = 0
    if (this.props.journalEntries.length > 0) {
      this.props.journalEntries.map((content) => {
        totalDebit = parseFloat(totalDebit) + parseFloat(content.amount_debit)
      })
    }
    return totalDebit
  }

  getTotalCredit() {
    var totalCredit = 0
    if (this.props.journalEntries.length > 0) {
      this.props.journalEntries.map((content) => {
        totalCredit = parseFloat(totalCredit) + parseFloat(content.amount_credit)
      })
    }
    return totalCredit
  }
  render() {
    return (
      <div>
        <div className={this.props.forPrint ? styles.labelDisplay: styles.noDisplay}> <h2> Journal Entries </h2></div>
        <div className={styles.checkDetailsHeader}>
          <div style={{display: 'flex'}} className={this.props.forPrint ? styles.noDisplay : null}>
            <Tooltip title="Reverse Journal" arrow>
              <IconButton name="reverse_journal" onClick={this.props.reverseJournal}>
                <SyncAltOutlinedIcon style={{ color: 'orangered' }} />
              </IconButton>
            </Tooltip>
            {this.props.journal.links ? <Tooltip title="Print Journal" arrow>
              <div>
                {this.props.printPdf}
              </div>
            </Tooltip> : null}

            {this.props.journal.is_posted === false ? (
              this.props.journal.can_post === true ? (
                <Tooltip title="Post Journal" arrow>
                  <IconButton onClick={this.props.activeJournal}>
                    <CheckCircleOutlineIcon style={{ color: 'darkgreen' }} />
                  </IconButton>
                </Tooltip>
              ) : null
            ) :
              this.props.journal.can_unpost === true ? (
                <Tooltip title="Unpost Journal" arrow>
                  <IconButton onClick={this.props.cancelJournal}>
                    <CancelIcon style={{ color: 'red' }} />
                  </IconButton>
                </Tooltip>
              ) : null
            }
          </div>
        </div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <b> Account Code</b>
              </TableCell>
              <TableCell>
                <b>Account Title</b>
              </TableCell>
              <TableCell align="right">
                <b>Debit</b>
              </TableCell>
              <TableCell align="right">
                <b>Credit</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.props.journalEntries.sort(function (a, b) {
              if (a.account.code.toLowerCase() < b.account.code.toLowerCase()) return -1;
              if (a.account.code.toLowerCase() > b.account.code.toLowerCase()) return 1;
              return 0;
            })
              .map((journal) => (
                journal.amount_credit==="0.0"&&
                <TableRow key={journal.id}>
                  <TableCell>
                    {journal.account.code}
                  </TableCell>

                  <TableCell>
                    {journal.account.name}
                  </TableCell>
                  <TableCell align="right">
                    {parseFloat(journal.amount_debit).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                  </TableCell>
                  <TableCell align="right">
                    {parseFloat(journal.amount_credit).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                  </TableCell>

                </TableRow>
              ))}
           {this.props.journalEntries.sort(function (a, b) {
              if (a.account.code.toLowerCase() < b.account.code.toLowerCase()) return -1;
              if (a.account.code.toLowerCase() > b.account.code.toLowerCase()) return 1;
              return 0;
            })
              .map((journal) => (
                journal.amount_credit!=="0.0"&&
              <TableRow key={journal.id}>
                <TableCell>
                  {journal.account.code}
                </TableCell>

                <TableCell>
                  {journal.account.name}
                </TableCell>
                <TableCell align="right">
                  {parseFloat(journal.amount_debit).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                </TableCell>
                <TableCell align="right">
                  {parseFloat(journal.amount_credit).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                </TableCell>

              </TableRow>
            ))}
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right"><b> Total :</b></TableCell>
              <TableCell align="right"><b>{this.getTotalDebit().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</b></TableCell>
              <TableCell align="right"><b>{this.getTotalCredit().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</b></TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </div>
    )
  }
}

export default JournalEntriesDetailTab

JournalEntriesDetailTab.propTypes = {
  classes: PropTypes.object,
  journalEntries: PropTypes.array,
  activeJournal: PropTypes.func,
  cancelJournal: PropTypes.func,
  journal: PropTypes.object,
  reverseJournal: PropTypes.func,
  forPrint: PropTypes.bool,
  printPdf: PropTypes.func,
}
