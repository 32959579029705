import React from 'react'

import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import styles from './Invoices.module.css'
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {Grid, InputAdornment, MenuItem} from "@material-ui/core";
import NumberFormat from "react-number-format";
import CloseIcon from '@material-ui/icons/Close'

class PaymentTermsForm extends React.Component {
  render() {
    var years = []
    var additionalDate = new Date().getFullYear()
    var i =''
    do {
      i = additionalDate;
      years.push(i)
      additionalDate = additionalDate - 1;
    } while (additionalDate >=2005);
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              value={this.props.item.product}
              id="product_id"
              size="small"
              fullWidth
              name="product_id"
              key="product"
              loading={this.props.productsAutoCompleteLoading}
              options={this.props.products.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              })}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleAutocompleteInvoiceDetails}
              onInputChange={(event, value, reason) => this.props.handleAutocompleteOnInputChange(event, value, reason)}
              closeIcon={<CloseIcon fontSize="small" id="clear_product_autocomplete" />}
              renderInput={(params) => (
                <TextField {...params} name="product_name" variant="outlined" label="Product" required/>
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              variant="outlined"
              autoComplete="off"
              size="small"
              type="date"
              fullWidth
              className={styles.textFields}
              label="Date of Expiration"
              disabled={!this.props.invoiceDetailsExpirable}
              name="date_of_expiration"
              value={this.props.item.date_of_expiration}
              onChange={this.props.handleChange}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoComplete="off"
              select
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="year"
              id="year"
              fullWidth={true}
              label="Year"
              onChange={this.props.handleChange}
              value={this.props.item.year}
            >

              {years.length > 0 ? (
                years.sort(function (a, b) {
                  if (a > b) return -1;
                  if (a < b) return 1;
                  return 0;
                }).map((year) => (
                  <MenuItem key={year} value={year}>{year}</MenuItem>
                ))
              ) : <MenuItem value="">No Option</MenuItem>}
            </TextField>
          </Grid>

          <Grid item xs={6} sm={3}>
            <TextField
              variant="outlined"
              autoComplete="off"
              size="small"
              fullWidth
              className={styles.textFieldsAlignRight}
              label="Quantity"
              type="number"
              name="qty"
              value={this.props.item.qty}
              onChange={this.props.handleChange}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <NumberFormat
              customInput={TextField}
              variant="outlined"
              className={styles.textFieldsAlignRight}
              required
              size="small"
              label="Discount"
              onValueChange={this.props.discountChange}
              fullWidth="true"
              name="discount"
              thousandSeparator={true}
              value={this.props.item.discount}
              decimalSeparator="."
              fixedDecimalScale={true}
              suffix={'%'}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <NumberFormat
              customInput={TextField}
              variant="outlined"
              autoComplete="off"
              size="small"
              fullWidth
              className={styles.textFieldsAlignRight}
              shrink
              label="Unit Amount"
              name="unit_amount"
              value={parseFloat(this.props.item.unit_amount).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
              onValueChange={this.props.handleInputChangeInvoiceDetailAmount}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <NumberFormat
              customInput={TextField}
              variant="outlined"
              required
              size="small"
              disabled={true}
              name="amount"
              label="Total Amount"
              className={styles.textFieldsAlignRight}
              thousandSeparator={true}
              value={parseFloat(this.props.item.amount).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
              decimalSeparator="."
              fixedDecimalScale={true}
              prefix={'₱'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              autoComplete="off"
              size="small"
              fullWidth
              className={styles.textFields}
              label="Description"
              name="remarks"
              value={this.props.item.remarks}
              onChange={this.props.handleChange}
              InputProps={{
                startAdornment: <InputAdornment
                  position="end">{this.props.invoiceDetailsValue !== null && this.props.invoiceDetailsValue !== undefined && this.props.invoiceDetailsValue.year !== undefined ? (this.props.invoiceDetailsValue.year) : null}</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
        <div className={styles.actionButton}>
          <Button
            variant="outlined"
            onClick={this.props.handleAddInvoiceDetails}
            disabled={!this.props.item.product}
            color="primary">Save</Button>
        </div>
      </>
    )
  }
}

export default PaymentTermsForm

PaymentTermsForm.propTypes = {
  item: PropTypes.object,
  invoiceDetailsValue: PropTypes.object,
  products: PropTypes.array,
  handleChange: PropTypes.func,
  invoiceDetailsExpirable: PropTypes.func,
  handleAutocompleteInvoiceDetails: PropTypes.func,
  discountChange: PropTypes.func,
  handleInputChangeInvoiceDetailAmount: PropTypes.func,
  handleAddInvoiceDetails: PropTypes.func,
  handleAutocompleteOnInputChange: PropTypes.func,
  productsAutoCompleteLoading: PropTypes.bool
}
