import React from 'react'

import PropTypes from 'prop-types'
import styles from '../Invoices.module.css'
import moment from 'moment';
import 'moment-timezone';

import {
  Table, TableHead, TableBody, TableCell, TableRow, TableContainer
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import PrintIcon from '@material-ui/icons/Print';
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

class ProductDetailTab extends React.Component {
  getSubTotal () {
    var total = 0
    if (this.props.invoice_details.length > 0) {
      this.props.invoice_details.map((amount) => {
        total = total + parseFloat(amount.amount)
      })
    }

    return total
  }

  getVat() {
    var withTax = 0
    if (this.props.invoice_details.length > 0) {
      this.props.invoice_details.map((invoice_detail) => {
        withTax += parseFloat(invoice_detail.tax_amount)
      })
    }
    return withTax
  }

  computeTotalAmount () {
    var total = parseFloat(this.getSubTotal()) + parseFloat(this.getVat())

    if (this.props.invoice.cwt_type_id) {
      return total -= (total * this.props.invoice.cwt_type.multiplier)
    } else {
      return total
    }
  }

  render() {
    return (
      <div>
        <div className={this.props.forPrint ? styles.noDisplay : styles.checkDetailsHeader}>
          <div align="right">
            <Tooltip title="Print Invoice" arrow>
              <IconButton onClick={this.props.openPrint}>
                <PrintIcon color="primary"/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Add Invoice Details" arrow>
              <IconButton color="inherit" aria-label="menu" name="add_invoice_detail"
                          onClick={() => this.props.handleClick(this.props.initialItem, true, this.props.model)}
              >
                <AddCircleOutlineIcon fontSize="medium"/>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <TableContainer style={{maxHeight: '575px', position: 'relative'}} className='table_container'>
          <Table size="small" name="invoice_details_table">
            <TableHead className={styles.tableHead}>
              <TableRow>
                <TableCell width='15%'>
                  <b>Unit</b>
                </TableCell>
                <TableCell width='15%'>
                  <b>Product Description</b>
                </TableCell>
                <TableCell width='10%'>
                  <b>Date of Expiration</b>
                </TableCell>
                <TableCell align="right" width='10%'>
                  <b>Quantity</b>
                </TableCell>
                <TableCell align="right" width='10%'>
                  <b>Discount</b>
                </TableCell>
                <TableCell width='10%'>
                  <b>Tax Exempted </b>
                </TableCell>
                <TableCell align="right" width='15%'>
                  <b>Unit Amount</b>
                </TableCell>
                <TableCell align="right" width='15%'>
                  <b>Total Amount</b>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
            {this.props.invoice_details.sort(function (a, b) {
              if (a.product.name < b.product.name) return -1;
              if (a.product.name > b.product.name) return 1;
              return 0;
            }).map((invoice_detail) => (
              <TableRow key={invoice_detail.id} className={styles.tableBody}>
                <TableCell>
                  {invoice_detail.product.unit}
                </TableCell>
                <TableCell>
                  {invoice_detail.product.name}
                </TableCell>
                <TableCell>{invoice_detail.date_of_expiration ? moment(invoice_detail.date_of_expiration).format("MM/DD/yyyy") : "N/A"}</TableCell>
                <TableCell align="right">
                  {invoice_detail.qty}
                </TableCell>
                <TableCell align="right">
                  {invoice_detail.discount !== '0.0' ? invoice_detail.discount : '0%'}
                </TableCell>
                <TableCell name="is_tax_exempted">
                  {invoice_detail.product.tax_exempt === true ? 'Yes' : 'No'}
                </TableCell>
                <TableCell align="right">
                  {parseFloat(invoice_detail.unit_amount).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                </TableCell>
                <TableCell align="right" name="amount" style={{ position: 'relative' }} >
                  {parseFloat(invoice_detail.amount).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                  <div className={this.props.forPrint ? styles.noDisplay : styles.actionButtonHoverable} align="right" >
                    <Tooltip title="Delete" arrow>
                      <IconButton size="small" color="secondary" name="delete_invoice_detail"
                        onClick={() => this.props.onOpenDeletePopup(invoice_detail, this.props.model)}
                      >
                        <DeleteIcon/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit" arrow>
                      <IconButton size="small" color="primary" name="edit_invoice_detail"
                        onClick={() => this.props.handleClick(invoice_detail, false, this.props.model)}
                      >
                        <EditIcon/>
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
              <TableRow>
                <TableCell align="right" colSpan={7}><b>Sub Total:</b></TableCell>
                <TableCell name="sub_total"
                  align="right"><b>{parseFloat(this.getSubTotal()).toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b></TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right" colSpan={7}><b>Add 12% VAT:</b></TableCell>
                <TableCell name="tax_amount"
                  align="right"><b>{this.getVat().toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b></TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right" colSpan={7}><b>Total:</b></TableCell>
                <TableCell name="total_amount"
                  align="right"><b>{parseFloat(this.computeTotalAmount().toFixed(2)).toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }
}

export default ProductDetailTab

ProductDetailTab.propTypes = {
  classes: PropTypes.object,
  invoice: PropTypes.object,
  initialItem: PropTypes.object,
  invoice_details: PropTypes.array,
  activeJournal: PropTypes.func,
  model: PropTypes.func,
  onOpenDeletePopup: PropTypes.func,
  handleClick: PropTypes.func,
  client: PropTypes.func,
  cancelJournal: PropTypes.func,
  openPrint: PropTypes.func,
  forPrint: PropTypes.bool,
}
