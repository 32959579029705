// eslint-disable-next-line


import React from 'react'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import TermOfPaymentsForm from './TermOfPaymentsForm'
import PaymentIcon from '@material-ui/icons/Payment';
import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import * as CommonFunction from "../../shared/Custom/CommonFunctions"

class TermOfPayments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Term of Payment',
      termOfPayments: [],
      openPopup: false,
      openDeletePopup: false,
      termOfPayment: {},
      initialTermOfPayment: {
        id: '',
        name: '',
        description: ''
      },
      title: '',
      load: false,
      tableHead: ['name', 'description'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
  }

  componentDidMount() {
    this.loadTermOfPayments('/v1/payment_terms')
  }

  loadTermOfPayments(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({termOfPayments: resp.data})
      this.setState({load: true})
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleSubmit() {
    const item = this.state.termOfPayment
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = '/v1/payment_terms/'
    } else {
      method = 'put'
      url = '/v1/payment_terms/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {

      const newData = resp.data
      this.setState({
        termOfPayments: this.state.termOfPayments.filter(termOfPayment => termOfPayment.id !== item.id)
      })
      this.setState({termOfPayments: [...this.state.termOfPayments, newData]})
      this.setState({openPopup: false})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({error_messages: 'Name ' + error.response.data.errors.name[0]})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.termOfPayment.id
    axios({
      method: 'delete',
      url: '/v1/payment_terms/' + DeleteItemId,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        termOfPayments: this.state.termOfPayments.filter(item => item.id !== DeleteItemId)
      })
      this.setState({openDeletePopup: false})
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleClose() {
    this.setState({openPopup: false})
    this.setState({openDeletePopup: false})
    this.setState({error_messages: ''})
    this.setState({updateDepartment: []})
    this.setState({updateBranch: []})
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({openPopup: true})
    this.setState({termOfPayment: item})
    this.setState({title: textTitle})
  }

  handleInputChange(e) {
    this.setState({
      termOfPayment: {
        ...this.state.termOfPayment,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({model: model})
    this.setState({termOfPayment: item})
    this.setState({openDeletePopup: true})
  }

  handleDeleteClose() {
    this.setState({openDeletePopup: false})
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>
              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialTermOfPayment}
                items={this.state.termOfPayments}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                icon={<PaymentIcon fontSize="large"/>}
                headerTitle="Term of Payments"
                createUsingSimplePopup={true}
                hasDeleteButton={true}
              />

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <TermOfPaymentsForm
                  error={this.state.error_messages}
                  item={this.state.termOfPayment}
                  submit={this.handleSubmit}
                  onchange={this.handleInputChange}/>
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.termOfPayment}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default TermOfPayments
