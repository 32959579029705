import React from 'react'
import PropTypes from 'prop-types'
import axios from "axios"
import {Redirect} from 'react-router'
import moment from 'moment';
// import 'moment-timezone';
import InvoicesForm from "./InvoicesForm";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import SimplePopUp from "../../shared/SimplePopUp/SimplePopUp"
import PaymentTermsForm from "./PaymentTermsForm"
import InvoiceDetailsForm from "../Invoices/InvoiceDetailsForm"
import * as CommonFunction from "../../shared/Custom/CommonFunctions"

import ClientNewForm from "../Clients/ClientNewForm";
import CwtTypesForm from "../CwtTypes/CwtTypesForm";
class InvoicesNewAndUpdate extends React.Component {

  constructor(props) {
    const current_user = JSON.parse(localStorage.getItem("current_user"))
    super(props)
    this.state = {
      model: 'Invoice',
      invoices: [],
      emailAddress: [],
      contactNumbers: [],
      forEmailContent: [],
      contactPersons: [],
      paymentTerms: [],
      branches: [],
      classifications: [],
      products: [],
      load: false,
      withDelete: false,
      openClientPopUp: false,
      openTerms: false,
      openCTW: false,
      isInstallment: false,
      invoiceDetailsExpirable: false,
      openInvoiceDetails: false,
      updateClassification: {},
      payment_term: {},
      nestedInvoiceAttribute: false,
      ifInstallment: '',
      ifGov: '',
      invoiceId: '',
      invoiceDetails: [],
      invoiceDetail: { product: {}},
      invoiceDetailsValue: {},
      client: {
        id: '',
        name: '',
        classification_id: '',
        company_address: '',
        business_type: '',
        remarks: '',
        home_address: '',
        tax_exempted: false,
        tin_no: '',
      },
      invoice: {
        id: '',
        name: '',
        client_id: '',
        date_of_invoice: moment().format("yyyy-MM-DD"),
        date_of_maturity: moment().format("yyyy-MM-DD"),
        branch_id: current_user.branch_id || '',
        remarks: '',
        contact_person_id: '',
        project_name: '',
        reference_no: '',
        down_payment: 0,
        monthly_payment: 0,
        no_of_months: 0,
        tin_no: '',
        payment_term_id: '',
        cwt_type_id: '',
        invoice_details_attributes: []
      },
      email_addresses_attributes: [{category: 0, content: ''}],
      contact_numbers_attributes: [{category: 0, content: ''}],
      clients: [],
      selectedClient: {},
      clientsAutoCompleteLoading: false,
      productsAutoCompleteLoading: false,
      selectedInvoiceDetailIndex: 0,
      popupHeader: '',
      isAddInvoiceDetail: false,
      redirect: false,
      cwtTypes: [],
      banks: [],
      banksAutoCompleteLoading: false,
      selectedBank: {},
      isCheckPayment: false,
      isBankPayment: false,
      selectedBranch: {},
      errorMessages: {},
      clientErrorMessages: {}
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.handleSubmitClient = this.handleSubmitClient.bind(this)
    this.openTermsForm = this.openTermsForm.bind(this)
    this.handleCloseTerms = this.handleCloseTerms.bind(this)
    this.handleTermsChange = this.handleTermsChange.bind(this)
    this.addPaymentTerms = this.addPaymentTerms.bind(this)
    this.handleAddInvoiceDetails = this.handleAddInvoiceDetails.bind(this)
    this.openInvoiceDetailsForm = this.openInvoiceDetailsForm.bind(this)
    this.handleAutocompleteInvoiceDetails = this.handleAutocompleteInvoiceDetails.bind(this)
    this.handleCloseInvoiceDetails = this.handleCloseInvoiceDetails.bind(this)
    this.handleInputChangeInvoiceDetail = this.handleInputChangeInvoiceDetail.bind(this)
    this.handleSubmitInvoice = this.handleSubmitInvoice.bind(this)
    this.discountChange = this.discountChange.bind(this)
    this.removeInvoiceDetailsContent = this.removeInvoiceDetailsContent.bind(this)
    this.handleInputChangeInvoiceDetailAmount = this.handleInputChangeInvoiceDetailAmount.bind(this)
    this.handleAutocompleteOnInputChange = this.handleAutocompleteOnInputChange.bind(this)
    this.openClientForm = this.openClientForm.bind(this)
    this.handleClientInputChange = this.handleClientInputChange.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleAutocompleteClassification = this.handleAutocompleteClassification.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.amountChange = this.amountChange.bind(this)
    this.donwPaymentChange = this.donwPaymentChange.bind(this)
    this.padFix = this.padFix.bind(this)
    this.handleSwitchForInvoice = this.handleSwitchForInvoice.bind(this)
    this.openCTWForm = this.openCTWForm.bind(this)
    this.handleInputCWTChange = this.handleInputCWTChange.bind(this)
    this.handleSubmitCWT = this.handleSubmitCWT.bind(this)
    this.clearField=this.clearField.bind(this)
  }

  componentDidMount() {
    this.loadProductsAutoCompleteOptions()
    this.loadPaymentTermsAutoCompleteOptions()
    this.loadBranchesAutoCompleteOptions()
    this.loadCwtTypes()
    this.loadClientsAutoCompleteOptions()
    this.loadBanks()

    if(Object.entries(this.props.match.params).length > 0 && this.props.match.params.client_id) {
      axios({
        method: 'get',
        url: '/v1/clients/' + this.props.match.params.client_id,
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ selectedClient: resp.data, invoice: { ...this.state.invoice, client_id: resp.data.id } })
        this.loadClientContactPersons(resp.data)
      })
    }

    this.setState({ invoice: { ...this.state.invoice, reference_no: this.padFix(0)[0] }, load: true })
  }

  openTermsForm() {
    this.setState({payment_term: {id: '', name: '', description: ''}})
    this.setState({openTerms: true})
  }

  openCTWForm() {
    this.setState({cwtType: {}, openCTW: true })
  }

  handleInputCWTChange(e) {
    this.setState({ cwtType: { ...this.state.cwtType, [e.target.name]: e.target.value } })
  }

  handleSubmitCWT() {
    const item = this.state.cwtType
    axios({
      method: 'post',
      url: '/v1/invoice_cwt_types/',
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    })
      .then(resp => {
        this.setState({ invoice: {...this.state.invoice, cwt_type_id: resp.data.id}, cwtTypes: [...this.state.cwtTypes, resp.data], openCTW: false})
      })
      .catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }


  openClientForm() {
    this.setState({openClientPopUp: true})
    axios({
      method: 'get',
      url: '/v1/clients/classifications',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({classifications: resp.data})
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))

  }

  handleCloseTerms() {
    this.setState({openTerms: false})
  }

  handleClose() {
    this.setState({openClientPopUp: false, openCTW: false})
  }

  handleCloseInvoiceDetails() {
    this.setState({openInvoiceDetails: false})
  }

  handleInputChange(e) {
    if (e.target.name.includes("payment_term") && this.state.paymentTerms.length > 0) {
      const payment_term = this.state.paymentTerms.find((item) => item.id === e.target.value)
      if (payment_term.name.toLowerCase().includes("installment")) {
        this.setState({ isInstallment: true, isCheckPayment: false, isBankPayment: false })
      } else if (["check", "cheque"].includes(payment_term.name.toLowerCase())) {
        this.setState({ isInstallment: false, isCheckPayment: true, isBankPayment: false, invoice: { ...this.state.invoice, date_of_check: moment().format("yyyy-MM-DD") } })
      } else if (payment_term.name.toLowerCase().includes("bank")) {
        this.setState({ isInstallment: false, isCheckPayment: false, isBankPayment: true })
      } else {
        this.setState({ isInstallment: false, isCheckPayment: false, isBankPayment: false })
      }
      this.setState({ invoice: { ...this.state.invoice, payment_term_id: payment_term.id, no_of_months: 0, monthly_payment: 0, down_payment: 0,
                      date_of_maturity: moment(this.state.invoice.date_of_invoice).add(parseInt(1), 'month').format("yyyy-MM-DD") } })
    } else if (e.target.name === "no_of_months") {
      var amountTotal = 0
      var taxVat = 0
      var totalAmount =  this.state.invoice.invoice_details_attributes.reduce((a,v) =>  a = a + v.amount , 0 )
      if (this.state.selectedClient.tax_exempted === false) {
        taxVat = totalAmount * .12
        amountTotal = taxVat + totalAmount
      }else {
        amountTotal = totalAmount
      }
      this.setState({ invoice: { ...this.state.invoice, no_of_months: parseInt(e.target.value), date_of_maturity: moment().add(parseInt(e.target.value), 'month').format("yyyy-MM-DD"),
          monthly_payment: ((amountTotal - this.state.invoice.down_payment) / parseInt(e.target.value)).toFixed(2)
        } })
    } else if (e.target.name.includes("reference_no")) {
      this.setState({
        invoice: {...this.state.invoice, reference_no: this.padFix(e.target.value)[0]} })
    } else if (e.target.name.includes("receipt_no")) {
      this.setState({ invoice: {...this.state.invoice, receipt_reference_no: this.padFix(e.target.value)[0]} })
    } else if (e.target.name === "date_of_invoice" && this.state.invoice.is_cash_sales) {
      this.setState({ invoice: { ...this.state.invoice, [e.target.name]: e.target.value, date_of_receipt: e.target.value }})
    } else {
      this.setState({invoice: {...this.state.invoice, [e.target.name]: e.target.value}})
    }
  }

  padFix(n) {
    return ('00000000'+n.toString()).match(/\S{8}$/);
  }

  handleTermsChange(e) {
    this.setState({
      payment_term: {
        ...this.state.payment_term,
        [e.target.name]: e.target.value
      }
    })

  }

  openInvoiceDetailsForm(item, isAdd, targetIndex = 0) {
    var popupHeader = ''
    isAdd ? (popupHeader = 'Add Invoice Detail') : (popupHeader = 'Edit Invoice Detail')
    this.setState({ openInvoiceDetails: true, invoiceDetail: item, popupHeader: popupHeader, isAddInvoiceDetail: isAdd, selectedInvoiceDetailIndex: parseInt(targetIndex) })
  }

  addPaymentTerms() {
    const item = this.state.payment_term
    axios({
      method: 'post',
      url: '/v1/payment_terms/',
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    })
      .then(resp => {
        this.setState({ invoice: {...this.state.invoice, payment_term_id: resp.data.id}, paymentTerms: [...this.state.paymentTerms, resp.data], payment_term: resp.data, openTerms: false})
      })
      .catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }


  handleSubmitClient() {
    axios({
      method: 'post',
      url:  '/v1/clients',
      data: (this.state.client),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.loadClientsAutoCompleteOptions()
      this.setState({selectedClient: resp.data})
      this.setState({invoice: {...this.state.invoice, client_id: resp.data.id}})
      this.setState({openClientPopUp: false})
    }).catch(error => {
      if (error.response.status === 422) {
          var errors = {}
          Object.keys(error.response.data.errors[0].detail.errors).map((field) => {
            errors[field] = error.response.data.errors[0].detail.errors[field][0]
          })
          this.setState({clientErrorMessages: errors})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }

  handleAddInvoiceDetails() {
    const item = this.state.invoiceDetail
    var contentDetailUpdate = this.state.invoice.invoice_details_attributes.filter(filter => filter.product_id !== item.product_id)
    this.setState({invoice: {...this.state.invoice, invoice_details_attributes: [...contentDetailUpdate, item]}})
    this.setState({openInvoiceDetails: false})
    this.setState({invoiceDetailsValue: []})
  }


  handleSubmitInvoice() {
    var item = this.state.invoice
    axios({
      method: 'post',
      url: '/v1/invoices/',
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ invoiceId: resp.data.id, redirect: true })
    }).catch(error => {
      if (error.response.status === 422) {
        var errors = {}
        Object.keys(error.response.data.errors[0].detail.errors).map((field) => {
          errors[field] = error.response.data.errors[0].detail.errors[field][0]
        })
        this.setState({errorMessages: errors})
      } else {
        this.setState(CommonFunction.handleApiRequestsErrors(error))
      }
    })
  }

  handleInputChangeInvoiceDetailAmount(e) {
    this.setState({
      invoiceDetail: {
        ...this.state.invoiceDetail,
        unit_amount: e.floatValue,
        amount: ((e.floatValue) * (1 - (this.state.invoiceDetail.discount / 100)) * this.state.invoiceDetail.qty),
        tax_amount: this.state.selectedClient.tax_exempted === false ? ((e.floatValue) * (1 - (this.state.invoiceDetail.discount / 100)) * this.state.invoiceDetail.qty) * .12 : 0
      }
    })
  }

  handleInputChangeInvoiceDetail(e) {
    if (e.target.name === "year") {
      this.setState({
        invoiceDetail: {
          ...this.state.invoiceDetail,
          [e.target.name]: e.target.value,
          remarks: this.state.invoiceDetail.product.name + ' ' + e.target.value
        }
      })
    } else if (e.target.name === "qty") {
      this.setState({
        invoiceDetail: {
          ...this.state.invoiceDetail,
          qty: e.target.value,
          amount: ((this.state.invoiceDetail.unit_amount) * (1 - (this.state.invoiceDetail.discount / 100)) * e.target.value),
          tax_amount: ((this.state.invoiceDetail.unit_amount) * (1 - (this.state.invoiceDetail.discount / 100)) * e.target.value) * .12
        }
      })
    } else {
      this.setState({
        invoiceDetail: {
          ...this.state.invoiceDetail,
          [e.target.name]: e.target.value

        }
      })
    }
  }

  handleAutocompleteInvoiceDetails(e, values) {
    if (values !== null) {
      this.setState({
        invoiceDetails: {
          ...this.state.invoiceDetails,
          [e.target.name]: e.target.value
        }
      })
      this.setState({invoiceDetailsValue: values})
      this.setState({invoiceDetailsExpirable: values.expirable})
      this.setState({
        invoiceDetail: {
          ...this.state.invoiceDetail,
          remarks: values.name,
          name: values.name,
          product: { name: values.name},
          unit_amount: values.amount,
          amount: values.amount,
          unit: values.unit,
          tax_exempt: this.state.selectedClient.tax_exempted === false ? true : false,
          is_tax_exempted: values.tax_exempt,
          product_id: values.id,
        }
      })
    } else {
      this.setState({
        invoiceDetail: {
          ...this.state.invoiceDetail,
          remarks: "",
          unit_amount: 0,
          amount: 0
        }
      })
      // This is the reload of the products autocomplete.
      this.loadProductsAutoCompleteOptions()
    }
  }

  discountChange(e) {
    if (this.state.amount === undefined) {
      this.setState({amount: 0})
    }
    this.setState({
      invoiceDetail: {
        ...this.state.invoiceDetail,
        discount: e.floatValue,
        amount: ((this.state.invoiceDetail.unit_amount) * (1 - (e.floatValue / 100)) * (this.state.invoiceDetail.qty)),
        tax_amount: ((this.state.invoiceDetail.unit_amount) * (1 - (e.floatValue / 100)) * (this.state.invoiceDetail.qty)) * .12
      }
    })
  }


  donwPaymentChange(e) {
    var amountTotal = 0
    var taxVat = 0
    var totalAmount =  this.state.invoice.invoice_details_attributes.reduce((a,v) =>  a = a + v.amount , 0 )
    if (this.state.selectedClient.tax_exempted === false) {
      taxVat = totalAmount * .12
      amountTotal = taxVat + totalAmount
    }else {
      amountTotal = totalAmount
    }
    this.setState({invoice: {...this.state.invoice,
        down_payment: e.floatValue,
        monthly_payment: parseInt(this.state.invoice.no_of_months) > 0 ? parseFloat((amountTotal - e.floatValue) / this.state.invoice.no_of_months).toFixed(2) : 0
    }})
  }

  amountChange(e) {
    this.setState({invoice: {...this.state.invoice, monthly_payment:  e.floatValue}})
  }

  removeInvoiceDetailsContent(idx) {
    const updatedInvoice = [...this.state.invoice.invoice_details_attributes]
    updatedInvoice.splice(idx, 1)
    this.setState({
      invoice: {
        ...this.state.invoice,
        invoice_details_attributes: updatedInvoice
      }
    })
  }

  handleClientInputChange(e) {
    this.setState({client: {...this.state.client, [e.target.name]: e.target.value} })
  }

  handleSwitch(event) {
    const value = event.target.checked
    this.setState({client: {...this.state.client, [event.target.name]: value}})
  }

  handleSwitchForInvoice(event) {
    if (event.target.name.includes("cash_sales")) {
      this.setState({ invoice: { ...this.state.invoice, [event.target.name]: event.target.checked, date_of_receipt: moment().format("yyyy-MM-DD"), 
                      receipt_reference_no: event.target.checked ? this.padFix(0)[0] : '' } })
      if (event.target.checked) {
        this.setState({ paymentTerms: this.state.paymentTerms.filter(term => !term.name.toLowerCase().includes("installment")) })
      } else {
        this.loadPaymentTermsAutoCompleteOptions()
      }
    } else {
      this.setState({ invoice: { ...this.state.invoice, [event.target.name]: event.target.checked }})
    }
  }

  loadClientsAutoCompleteOptions(searchText = '') {
    axios({
      method: 'get',
      url: '/v1/clients/search',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')},
      params: {name: searchText}
    }).then(resp => {
      this.setState({ clients: resp.data.clients, clientsAutoCompleteLoading: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadClientContactPersons(client) {
    if (![null, undefined].includes(client)) {
      axios({
        method: 'get',
        url: '/v1/clients/' + client.id + '/contact_persons',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ contactPersons: resp.data, invoice: { ...this.state.invoice, contact_person_id: resp.data[0] ? (resp.data[0].id) : ("") } })
      })
    } else {
      this.setState({ contactPersons: [] })
    }
  }

  loadProductsAutoCompleteOptions (searchText = '') {
    axios({
      method: 'get',
      url: '/v1/products/search',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')},
      params: {name: searchText}
    }).then(resp => {
      this.setState({ products: resp.data.products, productsAutoCompleteLoading: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadPaymentTermsAutoCompleteOptions () {
    axios({
      method: 'get',
      url: '/v1/payment_terms',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ paymentTerms: resp.data})
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadBranchesAutoCompleteOptions () {
    axios({
      method: 'get',
      url: '/v1/branches',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      const filtered_branch = resp.data.filter((branch) => branch.id === this.state.invoice.branch_id)
      this.setState({ branches: resp.data, selectedBranch: filtered_branch.length ? filtered_branch[0] : {} })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadCwtTypes () {
    axios({
      method: 'get',
      url: '/v1/invoice_cwt_types',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ cwtTypes: resp.data })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadBanks (searchText = '') {
    axios({
      method: 'get',
      url: '/v1/banks/search',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')},
      params: { name: searchText }
    }).then(resp => {
      this.setState({ banks: resp.data.banks })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleAutocomplete(event, value) {
    if (event.target.id.includes("client")) {
      this.setState({ selectedClient: value || {}, invoice: { ...this.state.invoice, client_id: ![null, undefined].includes(value) ? (value.id) : '' } })
      this.loadClientContactPersons(value)
    } else if (event.target.id.includes("branch")) {
      this.setState({ selectedBranch: value || {}, invoice: { ...this.state.invoice, branch_id: ![null, undefined].includes(value) ? (value.id) : '', reference_no: this.padFix("0")[0] } })
    } else if (event.target.id.includes("payment_term")) {
      this.setState({ selectedPaymentTerm: value || {}, invoice: { ...this.state.invoice, payment_term_id: ![null, undefined].includes(value) ? (value.id) : '' } })
    } else if (event.target.id.includes("bank")) {
      this.setState({ selectedBank: value || {}, invoice: { ...this.state.invoice, bank_id: ![null, undefined].includes(value) ? (value.id) : '' } })
    }
  }

  handleAutocompleteOnInputChange(event, value, reason) {
    if (reason === 'input') {
      if (event.target.id.includes("client")) {
        this.setState({ clientsAutoCompleteLoading: true })
        this.loadClientsAutoCompleteOptions(value || '')
      } else if (event.target.id.includes("product")) {
        this.setState({ productsAutoCompleteLoading: true })
        this.loadProductsAutoCompleteOptions(value || '')
      } else if (event.target.id.includes("bank")) {
        this.setState({ banksAutoCompleteLoading: true })
        this.loadProductsAutoCompleteOptions(value || '')
      }
    }
  }

  handleAutocompleteClassification(event, values) {
    if (values !== null) {
      this.setState({updateClassification: values})
      this.setState({
        client: {
          ...this.state.client,
          classification_id: values.id
        }
      })
    }
  }

  clearField() {
    this.setState({
      invoice:{
        ...this.state.invoice,
        cwt_type_id: ''
      }
    })
  }

  render() {
    const {redirect} = this.state
    if (redirect)
      return (
        <Redirect to={{
          pathname: '/invoices/' + this.state.invoiceId
        }}/>)
    return (

      this.state.load ? (
        <>
          <InvoicesForm
            handleSubmit={this.handleSubmitClient}
            handleAutocomplete={this.handleAutocomplete}
            contactPersons={this.state.contactPersons}
            selectedBranch={this.state.selectedBranch}
            branches={this.state.branches}
            clients={this.state.clients}
            selectedClient={this.state.selectedClient}
            item={this.state.invoice}
            ifGov={this.state.ifGov}
            paymentTerms={this.state.paymentTerms}
            isInstallment={this.state.isInstallment}
            onchange={this.handleInputChange}
            amountChange={this.amountChange}
            donwPaymentChange={this.donwPaymentChange}
            openTermsForm={this.openTermsForm}
            openCTWForm={this.openCTWForm}
            openClientForm={this.openClientForm}
            openInvoiceDetailsForm={this.openInvoiceDetailsForm}
            invoiceDetails={this.state.invoiceDetails}
            submit={this.handleSubmitInvoice}
            removeInvoiceDetailsContent={this.removeInvoiceDetailsContent}
            handleAddInvoiceDetails={this.handleAddInvoiceDetails}
            clientsAutoCompleteLoading={this.state.clientsAutoCompleteLoading}
            handleAutocompleteOnInputChange={this.handleAutocompleteOnInputChange}
            handleSwitch={this.handleSwitchForInvoice}
            cwtTypes={this.state.cwtTypes}
            banks={this.state.banks}
            banksAutoCompleteLoading={this.state.banksAutoCompleteLoading}
            selectedBank={this.state.selectedBank}
            isCheckPayment={this.state.isCheckPayment}
            isBankPayment={this.state.isBankPayment}
            error={this.state.errorMessages}
            clearField={this.clearField}
          />
          <SimplePopUp openPopup={this.state.openTerms}
            title="Add Payment Terms"
            handleClose={this.handleCloseTerms}
            maxWidth='xs'
          >
            <PaymentTermsForm item={this.state.payment_term} handleChange={this.handleTermsChange}
                              addPaymentTerms={this.addPaymentTerms}/>
          </SimplePopUp>

          <SimplePopUp
            openPopup={this.state.openCTW}
            title="Add Payment Terms"
            handleClose={this.handleClose}
            maxWidth='xs'
          >
            <CwtTypesForm
              item={this.state.cwtType}
              submit={this.handleSubmitCWT}
              onchange={this.handleInputCWTChange} />
          </SimplePopUp>


          <SimplePopUp
            openPopup={this.state.openClientPopUp}
            title="Add Client"
            handleClose={this.handleClose}
            maxWidth='xl'
          >
            <ClientNewForm
              item={this.state.client}
              classifications={this.state.classifications}
              inviceClientThru={true}
              handleInputChange={this.handleClientInputChange}
              handleSwitch={this.handleSwitch}
              handleSubmit={this.handleSubmitClient}
              handleAutocompleteClassification={this.handleAutocompleteClassification}
              error={this.state.clientErrorMessages}
            />
          </SimplePopUp>

          <SimplePopUp
            openPopup={this.state.openInvoiceDetails}
            title={this.state.popupHeader}
            handleClose={this.handleCloseInvoiceDetails}
            maxWidth='sm'
          >
            <InvoiceDetailsForm
              item={this.state.invoiceDetail}
              invoiceDetailsValue={this.state.invoiceDetailsValue}
              products={this.state.products}
              invoiceDetailsExpirable={this.state.invoiceDetailsExpirable}
              productsAutoCompleteLoading = {this.state.productsAutoCompleteLoading}
              handleAddInvoiceDetails={this.handleAddInvoiceDetails}
              discountChange={this.discountChange}
              handleAutocompleteInvoiceDetails={this.handleAutocompleteInvoiceDetails}
              handleChange={this.handleInputChangeInvoiceDetail}
              handleInputChangeInvoiceDetailAmount={this.handleInputChangeInvoiceDetailAmount}
              handleAutocompleteOnInputChange={this.handleAutocompleteOnInputChange}

            />
          </SimplePopUp>
        </>
      ) : <ProgressBar model={this.state.model}/>
    )
  }
}

export default InvoicesNewAndUpdate

InvoicesNewAndUpdate.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      client_id: PropTypes.string
    })
  })
}
