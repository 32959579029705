import React from 'react'
import styles from './Products.module.css'

import PropTypes from 'prop-types'

import {
	TextField,
	Button,
	MenuItem,
	Grid, InputLabel
} from '@material-ui/core'
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select/Select";

class PayeesSearch extends React.Component {
	render() {
		return (
			<div>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={6} md={6}>
						<TextField
							autoComplete="off"
							className={styles.textFields}
							variant="outlined"
							size="small"
							name="name"
							fullWidth={true}
							label="Name"
							onChange={this.props.handleChange}
							onKeyPress={this.props.handleKeyPress}
							value={this.props.item.name}
							id="payee-name"
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6}>
						<FormControl fullWidth="true" className={styles.textFields} size="small" required variant="outlined">
							<InputLabel htmlFor="outlined-age-native-simple">Product Type</InputLabel>
							<Select
								fullWidth
								label="Product Type"
								variant="outlined"
								value={this.props.item.product_type_id}
								onChange={this.props.handleChange}
								name="product_type_id"
								inputProps={{
									id: 'outlined-age-native-simple'
								}}
							>
								{
									this.props.productTypes.length > 0 ? (
										this.props.productTypes.map((type) => (
											<MenuItem
												key={type}
												value={type.id}
											>{type.name}
											</MenuItem>
										))
									) : (null)
								}
							</Select>
						</FormControl>
					</Grid>
				</Grid>

				<div className={styles.actionButton}>
					<Button variant="outlined" id="product-search" color="primary" onClick={() => this.props.search()} >Search</Button>
					<Button variant="outlined" id="product-clearSearch" color="secondary" onClick={() => this.props.clearSearch()} >Clear</Button>
				</div>

			</div>
		)
	}
}
export default PayeesSearch

PayeesSearch.propTypes = {
	handleChange: PropTypes.func,
	item: PropTypes.object,
	productTypes: PropTypes.array,
	search: PropTypes.func,
	clearSearch: PropTypes.func,
	handleKeyPress: PropTypes.func
}
