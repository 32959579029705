// eslint-disable-next-line

import React from 'react'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";

import PropTypes from "prop-types";
import ReceiptSearch from '../Receipts/ReceiptSearch'
import * as CommonFunction from "../../shared/Custom/CommonFunctions"
import ReceiptIcon from '@material-ui/icons/Receipt';
export const Component = withRouter(() => {

})
class Receipts extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      model: 'receipts',
      headerTitle: "Receipts/Collections",
      receipts: [],
      branches: [],
      load: false,
      tableHead: ['date_of_receipt', 'reference_no', 'client.name', 'total_amount', 'branch.name', 'status'],
      searchParams: {},
      totalPages: 1,
      currentPage: 1,
      page: 1,
      withPagination: true,
      clickableTableRow: true,
      searchUrl: '/v1/receipts/search',
      totalResults: 0,
      perPage: '20',
      withResultsLabel: false,
    }

    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.handleBranchAutoComplete = this.handleBranchAutoComplete.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handlePerPageChange = this.handlePerPageChange.bind(this)
  }

  componentDidMount () {
    var searchParams = {}
    const params = new URLSearchParams(this.props.location.search)

    // This is to load all params based on URLSearchParams
    params.forEach(function(value, key) {
      if (key !== 'page' && key !== 'per_page') {
        searchParams[key] = value
      }
    })

    if(params.get('per_page')) {
      this.setState({perPage: params.get('per_page')})
    }

    if (searchParams.branch_id !== null && searchParams.branch_id !== undefined && searchParams.branch_id !== '') {
      axios({
        method: 'get',
        url: '/v1/branches/' + searchParams.branch_id,
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ searchParams: { ...this.state.searchParams, branch_id: resp.data } })
      })
    }

    this.setState({ currentPage: parseInt(searchParams.page), searchParams: searchParams })
    this.loadReceipts(this.state.searchUrl + this.props.location.search)
  }

  loadReceipts(url, loadBranches = true) {
    axios({
      method: 'get',
      url: url ,
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ receipts: resp.data.receipts, totalPages: resp.data.meta.total_pages,
        currentPage: resp.data.meta.current_page, withResultsLabel: true, totalResults: resp.data.meta.total_count })
      if (loadBranches) {
        axios({
          method: 'get',
          url: '/v1/branches',
          headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
        }).then(resp => {
          this.setState({ branches: resp.data, load: true })
        }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
      }
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handlePageChange(event, value) {
    var searchParams = this.getSearchParams()
    searchParams.push(["page", value].join("="))
    searchParams = searchParams.join("&")

    const { history } = this.props
    const params = new URLSearchParams()

    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ current_page: value })
    this.loadReceipts([this.state.searchUrl, searchParams].join("?"), false)
  }

  handleSearchInputChange(e) {
    this.setState({ searchParams: { ...this.state.searchParams, [e.target.name]: e.target.value } })
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSearch()
    }
  }

  handleBranchAutoComplete(event, values) {
    if (values !== null) {
      this.setState({ searchParams: { ...this.state.searchParams, branch_id: values } })
    }
  }

  handleSearch() {
    const { history } = this.props
    var searchParams = this.getSearchParams().join("&")
    const params = new URLSearchParams()

    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.loadReceipts([this.state.searchUrl, searchParams].join("?"), false)
  }

  clearSearch() {
    const { history } = this.props
    history.push({ search: 'per_page='+ this.state.perPage })
    this.setState({ searchParams: {date_of_receipt_from: '', date_of_receipt_to: '', reference_no: '',client_name: '', branch_id: ''} })
    this.loadReceipts('/v1/receipts/search'+ '?per_page='+this.state.perPage, false)
  }

  getSearchParams() {
    var searchParams = []

    // This is to load all params based on searchParams state.
    Object.entries(this.state.searchParams).map(([key, value]) => {
      if (value != '' && value != undefined && value != null) {
        if(key === "branch_id") {
          searchParams.push([key, value.id].join("="))
        }else {
          searchParams.push([key, value].join("="))
        }
      }
    })
    searchParams.push(["per_page", this.state.perPage].join("="))
    return searchParams
  }

  handlePerPageChange(e){
    var searchParams = this.getSearchParams()
    searchParams.map((value, index) => {

      if(value.includes("per_page")) {
        searchParams.splice(index, 1)
      }
    })
    searchParams.push(["per_page", e.target.value].join("="))
    searchParams = searchParams.join("&")

    const { history } = this.props
    const params = new URLSearchParams()

    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ perPage: e.target.value })
    this.loadReceipts([this.state.searchUrl, searchParams].join("?"), false)
  }

  render () {
    return (
      <>
        {
          this.state.load ? (
            <div>
              <ReceiptSearch
                searchParams={this.state.searchParams}
                clearSearch={this.clearSearch}
                branches={this.state.branches}
                handleChange={this.handleSearchInputChange}
                handleKeyPress={this.handleKeyPress}
                search={this.handleSearch}
                handleBranchAutoComplete={this.handleBranchAutoComplete}
              />
              <SimpleTable
                items={this.state.receipts}
                model={this.state.model}
                headers={this.state.tableHead}
                icon={<ReceiptIcon fontSize="large" />}
                currentPage={this.state.currentPage}
                totalPages={this.state.totalPages}
                handlePageChange={this.handlePageChange}
                withPagination={this.state.withPagination}
                clickableTableRow={this.state.clickableTableRow}
                clickableNewFormPath={true}
                headerTitle={this.state.headerTitle}
                withResultsLabel={this.state.withResultsLabel}
                totalResults={this.state.totalResults}
                perPage={this.state.perPage}
                handlePerPageChange={this.handlePerPageChange}
                defaultModelRoute="/receipts/"
                defaultNewRoutesPath="/receipts/new"
                createUsingSimplePopup={false}
              />
            </div>

          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default withRouter(Receipts)

Receipts.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
