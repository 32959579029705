import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types'
import styles from './Sessions.module.css'
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import SignIn from "./SignIn";
import UnlockInstruction from "./UnlockInstruction"
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      unlock: false
    }
    this.unlock = this.unlock.bind(this)
    this.login = this.login.bind(this)
  }

  unlock() {
    this.setState({unlock: true})
  }

  login() {
    this.setState({login: true})
  }

  render() {
    return (
      <>
        <Grid container component="main" className={styles.root}>
          <CssBaseline/>
          <Grid item xs={false} sm={4} md={7}>
            <img src={process.env.REACT_APP_BG_URL} alt="" className={styles.image}/>
          </Grid>
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={styles.paper}>
              <div className={styles.headerName}> {process.env.REACT_APP_TITLE_COMPANY} Enterprise System</div>
              <div>
                <img src={process.env.REACT_APP_LOGO_URL}  alt="Company Background" className={styles.avatar}/>
              </div>
              <Typography component="h1" variant="h5">
                Forget your Password
              </Typography>
              <form className={styles.form} noValidate>
                <Grid container>
                  <Grid item xs>

                  </Grid>
                  <Grid item className={styles.forgotIcons}>
                    <Tooltip title="Unlock your Account" arrow>
                      <IconButton variant="body2"
                            onClick={this.unlock}
                      >
                        <LockOpenOutlinedIcon/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Login" arrow>
                      <Link variant="body2"
                            onClick={this.login}>
                        <PersonOutlineOutlinedIcon/>
                      </Link>
                    </Tooltip>
                  </Grid>
                </Grid>
                <TextField
                  variant="outlined"
                  margin="normal"
                  className={styles.textField}
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={styles.submit}
                >
                  Send Reset Instructions
                </Button>

              </form>
            </div>
          </Grid>
        </Grid>
        {this.state.unlock === true && <UnlockInstruction/>}
        {this.state.login === true && <SignIn/>}
      </>
    )
  }
}

export default ForgotPassword

ForgotPassword.propTypes = {
  setToken: PropTypes.func,
  setLoad: PropTypes.func
}
