import React from 'react'
import styles from './TermOfPayments.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  Grid
} from '@material-ui/core'

class TermOfPaymentsForm extends React.Component {
  render() {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} lg={6}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="name"
              fullWidth="true"
              label="Name"
              onChange={this.props.onchange}
              value={this.props.item.name}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="description"
              fullWidth="true"
              label="Description"
              onChange={this.props.onchange}
              value={this.props.item.description}
              id="description"
            />
          </Grid>
        </Grid>

        <div className={styles.actionButton}>
          <Button variant="outlined"
            id="term-of-payments-submit" color="primary"
            disabled={!this.props.item.name}
            onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default TermOfPaymentsForm

TermOfPaymentsForm.propTypes = {
  onchange: PropTypes.func,
  handleAutocomplete: PropTypes.func,
  handleSwitch: PropTypes.func,
  branches: PropTypes.array,
  departments: PropTypes.array,
  item: PropTypes.object,
  updateBranch: PropTypes.object,
  updateDepartment: PropTypes.object,
  submit: PropTypes.func
}
