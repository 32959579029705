// eslint-disable-next-line

import React from 'react'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'

import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import CwtTypesForm from './CwtTypesForm'
import SimpleDialogForm from '../../shared/SimplePopUp/SimpleDialogForm'
class CwtTypes extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      model: 'CWT Type',
      cwtTypes: [],
      openPopup: false,
      openDeletePopup: false,
      cwtType: {},
      title: '',
      load: false,
      tableHead: ['Name','description','multiplier'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {  },
      isOpen: false,
      message: '',
      type: '',
      showpoup: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleShowPopUp = this.handleShowPopUp.bind(this)
  }

  componentDidMount () {
    this.loadCwtTypes('/v1/invoice_cwt_types')
  }

  loadCwtTypes (url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ cwtTypes: resp.data, load: true })
    }).catch(error => console.log(error.response))
  }

  handleSubmit () {
    const item = this.state.cwtType
    var method = ''
    var url = ''
    if ([null, undefined].includes(item.id)) {
      method = 'post'
      url = '/v1/invoice_cwt_types/'
    } else {
      method = 'put'
      url = '/v1/invoice_cwt_types/' + item.id
    }

    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      this.setState({ cwtTypes: this.state.cwtTypes.filter(cwtType => cwtType.id !== item.id) })
      this.setState({ cwtTypes: [...this.state.cwtTypes, newData], cwtType: {}, 
                      openPopup: false, isOpen: true, message: 'Submitted Successfully', type: 'success' 
                    })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({error_messages: 'Name ' + error.response.data.errors.name[0]})
      } else {
        this.setState({ isOpen: true, openPopup: false, type: 'error', message: error.response.status.toString() + " Unexpected Error Problem Occurred" })
      }
    })
  }

  handleDeleteItem () {
    const DeleteItemId = this.state.cwtType.id
    axios({
      method: 'delete',
      url: '/v1/invoice_cwt_types/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({ cwtTypes: this.state.cwtTypes.filter(item => item.id !== DeleteItemId), 
                      openDeletePopup: false, isOpen: true, message: 'Deleted Successfully', type: 'error' })
    }).catch(error => console.log(error.response))
  }

  handleClose () {
    this.setState({ openPopup: false, openDeletePopup: false, error_messages: '', cwtType: {}, showpoup: false })
  }

  handleCreateorUpdateItem (item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({ openPopup: true, cwtType: item, title: textTitle })
  }

  handleInputChange (e) {
    this.setState({ cwtType: { ...this.state.cwtType, [e.target.name]: e.target.value } })
  }

  handleOpenDeletePopup (item, model) {
    this.setState({ model: model, cwtType: item, openDeletePopup: true })
  }

  handleDeleteClose () {
    this.setState({ openDeletePopup: false })
  }

  handleCloseAlert (event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  handleShowPopUp(item){
    this.setState({cwtType: item, showpoup: true})
  }

  render () {
    return (
      <>
        {
          this.state.load ? (
            <div>
              <SimpleTable
                headerTitle="CWT Types"
                createUsingSimplePopup={true}
                hasDeleteButton={true}
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.cwtType}
                items={this.state.cwtTypes}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                icon={<AccountBalanceWalletIcon fontSize="large"/>}
                withShowPopUp={true}
                handleShowPopUp={this.handleShowPopUp}
              />

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <CwtTypesForm error={this.state.error_messages}
                 item={this.state.cwtType}
                 submit={this.handleSubmit}
                 onchange={this.handleInputChange} />
              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.showpoup}
                title={`CWT Types - ${this.state.cwtType.id}`}
                handleClose={this.handleClose}
                maxWidth="md"
              >
                {this.state.cwtType? <SimpleDialogForm item={this.state.cwtType} fields={['name','multiplier','description']} /> :null}
                
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.cwtType}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default CwtTypes
