import React from 'react'

import PropTypes from 'prop-types'

import styles from '../../SimpleTable/SimpleTable.module.css'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from "@material-ui/core/TableContainer"
import { Tooltip } from "@material-ui/core";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import moment from 'moment'
import CompanyHeader from '../../CompanyHeader/CompanyHeader'

class ConsolidatedBalanceSheetTable extends React.Component {
  getAmount(balType, params) {
    var totalSales = this.props.item.asset_balances.current_assets[params][balType]
    if (totalSales) {
      if (totalSales > 0) {
        totalSales = parseFloat(totalSales).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalSales = "(" + parseFloat(totalSales * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalSales = "0.00" }
    return totalSales
  }
  getSimpleAmount(balType, params) {
    var totalSales = this.props.item.asset_balances[params][balType]
    if (totalSales) {
      if (totalSales > 0) {
        totalSales = parseFloat(totalSales).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalSales = "(" + parseFloat(totalSales * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalSales = "0.00" }
    return totalSales
  }
  getAssets(assetType, balType, params) {
    var totalSales = this.props.item.asset_balances[assetType][params][balType]
    if (totalSales) {
      if (totalSales === 0) {
        totalSales = "0.00"
      }
      else if (totalSales > 0) {
        totalSales = parseFloat(totalSales).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalSales = "(" + parseFloat(totalSales * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalSales = "0.00" }
    return totalSales
  }
  getCapital(assetType, balType, params) {
    var totalSales = this.props.item.capital_balances[assetType][params][balType]
    if (totalSales) {
      if (totalSales === 0) {
        totalSales = "0.00"
      }
      else if (totalSales > 0) {
        totalSales = parseFloat(totalSales).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalSales = "(" + parseFloat(totalSales * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalSales = "0.00" }
    return totalSales
  }
  getSimpleCapital(balType, params) {
    var totalSales = this.props.item.capital_balances[params][balType]
    if (totalSales) {
      if (totalSales === 0) {
        totalSales = "0.00"
      }
      else if (totalSales > 0) {
        totalSales = parseFloat(totalSales).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalSales = "(" + parseFloat(totalSales * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalSales = "0.00" }
    return totalSales
  }
  getLiabilities(assetType, balType, params) {
    var totalSales = this.props.item.liability_balances[assetType][params][balType]
    if (totalSales) {
      if (totalSales > 0) {
        totalSales = parseFloat(totalSales).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalSales = "(" + parseFloat(totalSales * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalSales = "0.00" }
    return totalSales
  }
  getSimpleLiability(balType, params) {
    var totalSales = this.props.item.liability_balances[params][balType]
    if (totalSales) {
      if (totalSales > 0) {
        totalSales = parseFloat(totalSales).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalSales = "(" + parseFloat(totalSales * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalSales = "0.00" }
    return totalSales
  }
  totalLiabilitiesCapital(balType) {
    var totalSales = this.props.item.liability_balances["total_liability"][balType] + this.props.item.capital_balances["total_capital"][balType]
    if (totalSales) {
      if (totalSales > 0) {
        totalSales = parseFloat(totalSales).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
      } else {
        totalSales = "(" + parseFloat(totalSales * -1).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) + ")"
      }
    } else { totalSales = "0.00" }
    return totalSales
  }

  render() {

    return (
      <div className={this.props.forPrint ? styles.simpleTableBody : null}>
        <CompanyHeader forPrint={this.props.forPrint} />
        <>
          <div className={styles.tableTitle}>
            <div className={styles.groupTileSearch}>
              <div className={styles.tileBody}>
                {this.props.icon}
                <h2 className={styles.title}>
                  <div className={styles.head}>
                    <div>{this.props.model.replace('_', ' ') + 's'}</div>
                  </div>
                </h2>
              </div>

            </div>
            <div className={styles.rightAction}>
              <div className={styles.buttonDisbursement}>
                {this.props.urlPrint.reports_url !== undefined ? (
                  <Tooltip title={`Print ${this.props.model}`} arrow>
                    <div className={this.props.forPrint ? styles.noDisplay : null}>
                      {this.props.printPdf}
                    </div>
                  </Tooltip>
                ) : null}
                {this.props.urlPrint.export_excel_url !== undefined ? (
                  <Tooltip title="Download Excel File" arrow className={this.props.forPrint ? styles.noDisplay : null}>
                    <a href={`${this.props.urlPrint.export_excel_url}`} target="_blank"
                      rel="noopener noreferrer">
                      <CloudDownloadIcon />
                    </a>
                  </Tooltip>
                ) : null}
              </div>
            </div>
          </div>

          <TableContainer style={{ maxHeight: '575px', position: 'relative' }}>
            <Table size="small" stickyHeader aria-label="customized sticky table"
              name={this.props.model.toLowerCase().replace(/ /g, '_') + "_table"}>
              <TableHead className={styles.tableHeadBody}>
                <TableRow>
                  {
                    this.props.searchItem.as_of_date && this.props.item !== null ? (
                      <>
                        <TableCell
                          className={styles.cell}><b>Particulars</b>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          {this.props.searchItem.date_to_compare ? (moment(this.props.searchItem.date_to_compare).format('L')) : null
                          }</TableCell>
                        <TableCell className={styles.cell}>{
                          this.props.searchItem.as_of_date ? (
                            moment(this.props.searchItem.as_of_date).format('L')

                          ) : null
                        }</TableCell>


                      </>
                    ) : null}

                </TableRow>
              </TableHead>
              <TableBody className={styles.tableBody}>

                {
                  this.props.item.asset_balances ? (
                    <>
                      <TableRow>
                        <TableCell
                          className={styles.cell}><b>Assets</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Current Assets</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getAmount("compared_balance", "accounts_receivable")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (this.getAmount("balance", "accounts_receivable")
                          ) : null
                        }</TableCell>

                      </TableRow>

                      <TableRow>
                        <TableCell>Cash on Hand</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getAmount("compared_balance", "cash_on_hand")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getAmount("balance", "cash_on_hand")
                          ) : null
                        }</TableCell>

                      </TableRow>
                      <TableRow>
                        <TableCell>Other Current Assets</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getAmount("compared_balance", "other_current_assets")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getAmount("balance", "other_current_assets")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Sub Total</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getAmount("compared_balance", "sub_total")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getAmount("balance", "sub_total")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Advances to Stockholders</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getSimpleAmount("compared_balance", "advances_stockholders")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getSimpleAmount("balance", "advances_stockholders")
                          ) : null
                        }</TableCell>

                      </TableRow>
                      <TableRow>
                        <TableCell>Advances to Affiliates</TableCell>
                        <TableCell className={styles.amount}>
                          {
                            this.props.searchItem.date_to_compare ? (
                              this.getSimpleAmount("compared_balance", "advances_affiliates")
                            ) : null
                          }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getSimpleAmount("balance", "advances_affiliates")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={styles.cell}>Fixed Assets
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Leasehold Improvement</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getAssets("fixed_assets", "compared_balance", "leasehold_improvement")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getAssets("fixed_assets", "balance", "leasehold_improvement")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Office Furniture and Equipment</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getAssets("fixed_assets", "compared_balance", "office_furniture")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getAssets("fixed_assets", "balance", "office_furniture")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Computer Software and Equipment</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getAssets("fixed_assets", "compared_balance", "computer_software")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getAssets("fixed_assets", "balance", "computer_software")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Property and Equipment</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getAssets("fixed_assets", "compared_balance", "property")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getAssets("fixed_assets", "balance", "property")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Construction Progress</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getAssets("fixed_assets", "compared_balance", "construction_progress")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getAssets("fixed_assets", "balance", "construction_progress")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Sub Total</TableCell>
                        <TableCell className={styles.amount}>
                          {
                            this.props.searchItem.date_to_compare ? (
                              this.getAssets("fixed_assets", "compared_balance", "sub_total")
                            ) : null
                          }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getAssets("fixed_assets", "balance", "sub_total")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Accumulated Depreciation</TableCell>
                        <TableCell className={styles.amount}>
                          {
                            this.props.searchItem.date_to_compare ? (
                              this.getAssets("fixed_assets", "compared_balance", "accumulated_depreciation")
                            ) : null
                          }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getAssets("fixed_assets", "balance", "accumulated_depreciation")
                          ) : null
                        }</TableCell>

                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell className={styles.amount}>
                          {
                            this.props.searchItem.date_to_compare ? (
                              this.getAssets("fixed_assets", "compared_balance", "total")
                            ) : null
                          }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getAssets("fixed_assets", "balance", "total")
                          ) : null
                        }</TableCell>

                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Other Assets</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell className={styles.cell}>Investment In Associates</TableCell>
                        <TableCell className={styles.amount}>
                          {
                            this.props.searchItem.date_to_compare ? (
                              this.getAssets("other_assets", "compared_balance", "investment_associates")
                            ) : null
                          }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getAssets("other_assets", "balance", "investment_associates")
                          ) : null
                        }</TableCell>

                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Research and Development Cost</TableCell>


                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getAssets("other_assets", "compared_balance", "research_and_development_cost")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getAssets("other_assets", "balance", "research_and_development_cost")
                          ) : null
                        }</TableCell>

                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Tax Credit</TableCell>

                        <TableCell className={styles.amount}>
                          {
                            this.props.searchItem.date_to_compare ? (
                              this.getAssets("other_assets", "compared_balance", "tax_credit")
                            ) : null
                          }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getAssets("other_assets", "balance", "tax_credit")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Suspense Account</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getAssets("other_assets", "compared_balance", "suspense_account")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>
                          {
                            this.props.searchItem.as_of_date ? (
                              this.getAssets("other_assets", "balance", "suspense_account")
                            ) : null
                          }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Due Projects</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getAssets("other_assets", "compared_balance", "due_projects")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getAssets("other_assets", "balance", "due_projects")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Sub Total</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getAssets("other_assets", "compared_balance", "sub_total")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>
                          {
                            this.props.searchItem.as_of_date ? (
                              this.getAssets("other_assets", "balance", "sub_total")
                            ) : null
                          }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Land</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getSimpleAmount("compared_balance", "land")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>
                          {
                            this.props.searchItem.as_of_date ? (
                              this.getSimpleAmount("balance", "land")
                            ) : null
                          }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}><b>TOTAL ASSETS</b></TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            <b>{this.getSimpleAmount("compared_balance", "total_assets")}</b>
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>
                          {
                            this.props.searchItem.as_of_date ? (
                              <b>{this.getSimpleAmount("balance", "total_assets")}</b>
                            ) : null
                          }</TableCell>
                      </TableRow>
                      <TableRow>
                        <hr />
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}><b>Liabilities and Capital</b></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Current Liabilities</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Accounts Payable</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getLiabilities("current_liabilities", "compared_balance", "accounts_payable")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getLiabilities("current_liabilities", "balance", "accounts_payable")
                          ) : null
                        }</TableCell>

                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Accrued Expenses</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getLiabilities("current_liabilities", "compared_balance", "accrued_expenses")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>
                          {
                            this.props.searchItem.as_of_date ? (
                              this.getLiabilities("current_liabilities", "balance", "accrued_expenses")
                            ) : null
                          }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Other Current Liabilities</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getLiabilities("current_liabilities", "compared_balance", "other_current_liabilities")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>
                          {
                            this.props.searchItem.as_of_date ? (
                              this.getLiabilities("current_liabilities", "balance", "other_current_liabilities")
                            ) : null
                          }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Sub Total</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getLiabilities("current_liabilities", "compared_balance", "sub_total")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>
                          {
                            this.props.searchItem.as_of_date ? (
                              this.getLiabilities("current_liabilities", "balance", "sub_total")
                            ) : null
                          }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Loans Payable</TableCell>

                        <TableCell className={styles.amount}>
                          {
                            this.props.searchItem.date_to_compare ? (
                              this.getSimpleLiability("compared_balance", "loans_payable")
                            ) : null
                          }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getSimpleLiability("balance", "loans_payable")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Dividends Payable</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getSimpleLiability("compared_balance", "dividends_payable")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>
                          {
                            this.props.searchItem.as_of_date ? (
                              this.getSimpleLiability("balance", "dividends_payable")
                            ) : null
                          }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Advances from Stockholders</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getSimpleLiability("compared_balance", "advances_stockholders")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getSimpleLiability("balance", "advances_stockholders")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Stockholders Equity</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Share Capital</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getCapital("stockholders_equity", "compared_balance", "share_capital")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getCapital("stockholders_equity", "balance", "share_capital")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Treasury Stock</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getCapital("stockholders_equity", "compared_balance", "treasury_stock")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getCapital("stockholders_equity", "balance", "treasury_stock")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Cash Dividends</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getCapital("stockholders_equity", "compared_balance", "cash_dividends")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getCapital("stockholders_equity", "balance", "cash_dividends")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Sub Total</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getCapital("stockholders_equity", "compared_balance", "sub_total")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getCapital("stockholders_equity", "balance", "sub_total")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Retained Earnings</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Beginning</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getCapital("retained_earnings", "compared_balance", "beginning")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getCapital("retained_earnings", "balance", "beginning")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Premium Paid on Treasury Stock</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getCapital("retained_earnings", "compared_balance", "premium_paid")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getCapital("retained_earnings", "balance", "premium_paid")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Prior Period Adjustment</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getCapital("retained_earnings", "compared_balance", "period_adjustment")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getCapital("retained_earnings", "balance", "period_adjustment")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Current Earnings</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getCapital("retained_earnings", "compared_balance", "net_income")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getCapital("retained_earnings", "balance", "net_income")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}>Ending</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            this.getCapital("retained_earnings", "compared_balance", "sub_total")
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            this.getCapital("retained_earnings", "balance", "sub_total")
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}><b>TOTAL CAPITAL</b></TableCell>
                        <TableCell className={styles.amount}> {
                          this.props.searchItem.date_to_compare ? (
                            <b>{this.getSimpleCapital("compared_balance", "total_capital")}</b>
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            <b>{this.getSimpleCapital("balance", "total_capital")}</b>
                          ) : null
                        }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={styles.cell}><b>TOTAL LIABILITIES AND CAPITAL</b></TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.date_to_compare ? (
                            <b>{this.totalLiabilitiesCapital("compared_balance")}</b>
                          ) : null
                        }</TableCell>
                        <TableCell className={styles.amount}>{
                          this.props.searchItem.as_of_date ? (
                            <b>{this.totalLiabilitiesCapital("balance")}</b>
                          ) : null
                        }</TableCell>
                      </TableRow>
                    </>

                  ) : null
                }
              </TableBody>
            </Table>
          </TableContainer>
        </>
      </div>

    )
  }
}

export default ConsolidatedBalanceSheetTable

ConsolidatedBalanceSheetTable.propTypes = {
  headers: PropTypes.array,
  item: PropTypes.object,
  icon: PropTypes.object,
  model: PropTypes.string,
  urlPrint: PropTypes.object,
  withPrint: PropTypes.bool,
  months: PropTypes.array,
  searchItem: PropTypes.object,
  printPdf: PropTypes.object,
  forPrint: PropTypes.bool,
}
