import React from 'react'
import PropTypes from 'prop-types'

import styles from './Clients.module.css'

import {
  TextField,
  Button,
  MenuItem,
  Grid
} from '@material-ui/core'

class ClientsSearch extends React.Component {
  render() {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <TextField
              autoComplete="off"
              
              variant="outlined"
              size="small"
              name="q"
              fullWidth={true}
              label="Full Text"
              value={this.props.item.q || ''}
              id="q"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              autoComplete="off"
              
              variant="outlined"
              size="small"
              name="name"
              fullWidth={true}
              label="Name"
              value={this.props.item.name}
              id="name"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              autoComplete="off"
              
              variant="outlined"
              size="small"
              name="contact_persons"
              fullWidth={true}
              label="Contact Person"
              value={this.props.item.contact_persons}
              id="contact_persons"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              autoComplete="off"
              
              variant="outlined"
              size="small"
              name="contact_numbers"
              fullWidth={true}
              label="Contact Number"
              value={this.props.item.contact_numbers || ''}
              id="contact_numbers"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              autoComplete="off"
              
              variant="outlined"
              size="small"
              name="email_addresses"
              fullWidth={true}
              label="Email Address"
              value={this.props.item.email_addresses || ''}
              id="email_addresses"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              autoComplete="off"
              select
              
              variant="outlined"
              size="small"
              name="tax_exempted"
              fullWidth={true}
              label="Tax Exempted"
              value={this.props.item.tax_exempted || ''}
              id="tax_exempted"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
            >
              <MenuItem value="" > Please Select </MenuItem>
              <MenuItem value="vatable">Vatable</MenuItem>
              <MenuItem value="non_vatable">Non Vatable</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <div className={styles.actionButton}>
          <Button variant="outlined" id="client_search" color="primary" onClick={this.props.search} >Search</Button>
          <Button variant="outlined" id="client_clearSearch" color="secondary" onClick={this.props.clearSearchParams} >Clear</Button>
        </div>
      </div>
    )
  }
}

export default ClientsSearch

ClientsSearch.propTypes = {
  clearSearchParams: PropTypes.func,
  handleKeyPress: PropTypes.func,
  handleChange: PropTypes.func,
  search: PropTypes.func,
  item: PropTypes.object
}
