import React from 'react'
import PropTypes from 'prop-types'

import styles from './Clients.module.css'

import {
  TextField,
  Button,
  Grid,
  Switch,
  IconButton
} from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import EmailAddress from "./EmailAddress/EmailAddress";

import AddBoxIcon from '@material-ui/icons/AddBox'
import ContactNumber from "./ContactNumber/ContactNumber";

class ClientNewForm extends React.Component {
  render() {
    return (
      <div>
        {this.props.inviceClientThru === true ? null :
        <>
          <div>
            <h1>Client Details</h1>
          </div>
          <hr/>
        </>}
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <TextField
              autoComplete="off"
              variant="outlined"
              size="small"
              name="name"
              required
              fullWidth={true}
              label="Name"
              value={this.props.item.name}
              id="name"
              onChange={this.props.handleInputChange}
            />
            <div className={styles.error}>{this.props.error.name?'Name ' + this.props.error.name:null}</div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              value={this.props.updateClassification}
              id="account_id"
              size="small"
              fullWidth
              name="account_id"
              options={this.props.classifications.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              })}

              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleAutocompleteClassification}
              renderInput={(params) => (
                <TextField {...params} name="classification_id" variant="outlined" label="Classification" required/>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              variant="outlined"
              size="small"
              name="home_address"
              fullWidth={true}
              label="Home Address"
              value={this.props.item.home_address}
              id="contact_numbers"
              onChange={this.props.handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              variant="outlined"
              size="small"
              name="company_address"
              fullWidth={true}
              label="Company Address"
              value={this.props.item.company_address}
              id="email_addresses"
              onChange={this.props.handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              autoComplete="off"
              variant="outlined"
              size="small"
              name="tin_no"
              fullWidth={true}
              label="TIN"
              value={this.props.item.tin_no}
              id="tin"
              onChange={this.props.handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              autoComplete="off"
              variant="outlined"
              size="small"
              name="business_type"
              fullWidth={true}
              label="Business Style"
              value={this.props.item.business_type}
              id="email_addresses"
              onChange={this.props.handleInputChange}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <TextField
              autoComplete="off"
              variant="outlined"
              size="small"
              name="reference_no"
              fullWidth={true}
              label="Reference no."
              value={this.props.item.reference_no}
              id="reference_no"
              onChange={this.props.handleInputChange}
            />
          </Grid>
          <Grid item xs={6} sm={2} >
            <FormControlLabel
              control={<Switch
                checked={this.props.item.tax_exempted}
                onChange={this.props.handleSwitch}
                name="tax_exempted"/>
              }
              label="Tax Exempted"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              autoComplete="off"
              variant="outlined"
              size="small"
              name="remarks"
              fullWidth={true}
              label="Remarks"
              value={this.props.item.remarks}
              id="remarks"
              onChange={this.props.handleInputChange}
            />
          </Grid>
          {this.props.inviceClientThru === true ? (null): this.props.showNestedAtrributes === undefined ? (
            <>
              <Grid item xs={12} sm={6}>
                <div>
                  <div className={styles.emailBody}>
                    <h4 className="set-label-width">Email Address</h4>
                    <IconButton
                      color="primary"
                      variant="outlined"
                      name="add_client_email_address"
                      onClick={this.props.addEmailAddress}>
                      <AddBoxIcon/>
                    </IconButton>
                  </div>
                  {
                    this.props.emailAddress.map((val, idx) => (
                      <EmailAddress
                        key={`content-${idx}`}
                        idx={idx}
                        emailAddresses={this.props.emailAddress}
                        handleEmailAddressChange={this.props.handleEmailAddressChange}
                        removeEmail={this.props.removeEmail}
                      />
                    ))
                  }
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <div className={styles.emailBody}>
                    <h4 className="set-label-width">Contact Number</h4>
                    <IconButton
                      color="primary"
                      variant="outlined"
                      name="add_client_contact_number_address"
                      onClick={this.props.addNumber}>
                      <AddBoxIcon/>
                    </IconButton>
                  </div>
                  {
                    this.props.contactNumber.map((val, idx) => (
                      <ContactNumber
                        key={`content-${idx}`}
                        idx={idx}
                        contactNumbers={this.props.contactNumber}
                        handleNumberChange={this.props.handleNumberChange}
                        removeContactNumber={this.props.removeNumber}
                      />
                    ))
                  }
                </div>

              </Grid>
            </>
          ) : null}
        </Grid>
        <div className={styles.actionButton}>
          <a href="/clients">
            <Button variant="outlined" >Back</Button>
          </a>
          <Button variant="outlined" disabled={!this.props.item.name || this.props.updateClassification.id === undefined} id="client_submit" color="primary" onClick={this.props.handleSubmit}>Save</Button>
        </div>
      </div>
    )
  }
}

export default ClientNewForm

ClientNewForm.propTypes = {
  clearSearchParams: PropTypes.func,
  handleKeyPress: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleAutocompleteClassification: PropTypes.func,
  classifications: PropTypes.array,
  contactNumber: PropTypes.array,
  emailAddress: PropTypes.array,
  handleSubmit: PropTypes.func,
  handleEmailAddressChange: PropTypes.func,
  addEmailAddress: PropTypes.func,
  removeEmail: PropTypes.func,
  addNumber: PropTypes.func,
  removeNumber: PropTypes.func,
  handleNumberChange: PropTypes.func,
  updateClassification: PropTypes.func,
  handleSwitch: PropTypes.func,
  item: PropTypes.object,
  error: PropTypes.object,
  showNestedAtrributes: PropTypes.bool,
  inviceClientThru: PropTypes.bool,
}
