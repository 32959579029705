// eslint-disable-next-line
import React from 'react'

import PropTypes from 'prop-types'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SaleRegisterSearchForm from './SaleRegisterSearchForm'
import PaymentIcon from '@material-ui/icons/Payment'
import axios from 'axios'
import {withRouter} from 'react-router-dom'
import AlertMessage from "../../shared/Notify/AlertMessage";

export const Component = withRouter(() => {
})
import styles from './SaleRegister.module.css'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import * as CommonFunction from "../../shared/Custom/CommonFunctions"
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';
import IconButton from '@material-ui/core/IconButton'

class SaleRegister extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Sale Register',
      saleRegisters: [],
      branches: [],
      saleRegister: {},
      title: '',
      accountId: '',
      load: false,
      tableHead: ['date_of_journal', 'reference_no', 'description', 'journal_entries'],
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      branchFirstLetter: '',
      searchSaleRegister: {},
      searchJournals: [],
      journalEntries: [],
      withPrint: true,
      urlPrint: '',
      totalPages: 1,
      currentPage: 1,
      withPagination: true,
      forPrint: false

    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleDisbursementSearch = this.handleDisbursementSearch.bind(this)
    this.changePrintLayout = this.changePrintLayout.bind(this)
  }

  componentDidMount() {
    var url = ''
    if (this.props.location.search === '') {
      url = '/v1/journals/search?'
    } else {
      var searchItem = {}
      url = "/v1/journals/search?per_page=1000000&resource_type=Invoice&sort_by[date_of_journal]=asc&"
      const query = new URLSearchParams(this.props.location.search);
      var urlOptions = []
      if (query.get('starts_on_from') !== null && query.get('starts_on_from') !== '') {
        searchItem["starts_on_from"] = query.get('starts_on_from')
        urlOptions.push("starts_on_from=" + query.get('starts_on_from'))
      }
      if (query.get('starts_on_to') !== null && query.get('starts_on_to') !== '') {
        searchItem["starts_on_to"] = query.get('starts_on_to')
        urlOptions.push("starts_on_to=" + query.get('starts_on_to'))
      }
      if (query.get('branch_id') !== null && query.get('branch_id') !== '') {
        axios({
          method: 'get',
          url: '/v1/branches',
          headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
        }).then(resp => {
          resp.data.map((branch) => {
            if (branch.id.toLocaleString() === query.get('branch_id')) {
              searchItem["branch_id"] = branch
              urlOptions.push("branch_id=" + branch.id)
            }
          })
        })
      }
      if (query.get('is_posted') !== null && query.get('is_posted') !== '') {
        searchItem["is_posted"] = query.get('is_posted')
        urlOptions.push("is_posted=" + query.get('is_posted'))
      }
      if (query.get('page') !== null && query.get('page') !== '') {
        urlOptions.push("page=" + query.get('page'))
      }
      this.setState({currentPage: parseInt(query.get('page'))})

      if (urlOptions.length > 0) {
        urlOptions.map((urlOption, idx) => {
          if (idx > 0) {
            url = url + "&" + urlOption
          } else {
            url = url + urlOption
          }
        })
      }
      this.setState({searchSaleRegister: searchItem})
    }
    this.loadDisbursementRegisters(url)
  }

  loadDisbursementRegisters(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      if (this.props.location.search !== '') {
        var newArray = []
        resp.data.journals.map((account) => {
            var item = account
            axios({
              method: "get",
              url: "/v1/journals/" + account.id + '/journal_entries',
              headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
            }).then(resp => {
              item["journal_entries"] = resp.data
              newArray.push(item)
              this.setState({journalEntries: resp.data})
              this.setState({searchJournals: newArray})
              this.setState({load: true})
            }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
          },
        )

        this.setState({totalPages: resp.data.meta.total_pages})
        this.setState({urlPrint: resp.data.meta})
      } else {
        this.setState({searchJournals: []})
      }
      axios({
        method: 'get',
        url: '/v1/branches',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({branches: resp.data})
        this.setState({load: true})
      }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }


  handleSearch() {
    var url = "/v1/journals/search?per_page=1000000&resource_type=Invoice&sort_by[date_of_journal]=desc&"
    var searchUrl = ''
    var options = []

    if (this.state.searchSaleRegister.starts_on_from !== undefined && this.state.searchSaleRegister.starts_on_from !== '') {
      options.push("starts_on_from=" + this.state.searchSaleRegister.starts_on_from)
    }
    if (this.state.searchSaleRegister.starts_on_to !== undefined && this.state.searchSaleRegister.starts_on_to !== '') {
      options.push("starts_on_to=" + this.state.searchSaleRegister.starts_on_to)
    }
    if (this.state.searchSaleRegister.branch_id !== undefined && this.state.searchSaleRegister.branch_id !== '') {
      options.push("branch_id=" + this.state.searchSaleRegister.branch_id.id)
    }
    if (this.state.searchSaleRegister.is_posted !== undefined && this.state.searchSaleRegister.is_posted !== '') {
      options.push("is_posted=" + this.state.searchSaleRegister.is_posted)
    }

    options.map((option, idx) => {
      if (idx > 0) {
        searchUrl = searchUrl + "&" + option
      } else {
        searchUrl = searchUrl + option
      }
    })
    url = url + searchUrl
    axios({
      method: "get",
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      var newArray = []
      if(!resp.data.journals.length) this.setState({ searchJournals: [] })
      resp.data.journals.map((account) => {
          var item = account
          axios({
            method: "get",
            url: "/v1/journals/" + account.id + '/journal_entries',
            headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
          }).then(resp => {
            item["journal_entries"] = resp.data
            newArray.push(item)
            this.setState({journalEntries: resp.data})
            this.setState({searchJournals: newArray})
            this.setState({load: true})
          }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
        },
      )
      this.setState({totalPages: resp.data.meta.total_pages})
      this.setState({urlPrint: resp.data.meta})
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))

    const {history} = this.props
    const params = new URLSearchParams()
    params.append("/search", searchUrl)
    history.push({search: searchUrl})
  }

  handleSearchInputChange(e) {
    if (e.target.name === "starts_on_from") {
      var dateStart = new Date(e.target.value).toLocaleDateString('fr-Ca')
      var lastDay = new Date(new Date(dateStart).getFullYear(), new Date(dateStart).getMonth() + 1, 0).toLocaleDateString('fr-Ca')
      this.setState({
        searchSaleRegister: {
          ...this.state.searchSaleRegister,
          [e.target.name]: e.target.value,
          starts_on_to: lastDay
        }
      })
    }
    else {
      this.setState({
        searchSaleRegister: {
          ...this.state.searchSaleRegister,
          [e.target.name]: e.target.value
        }
      })
    }
  }

  handleInputChange(e) {
    this.setState({
      saleRegister: {
        ...this.state.saleRegister,
        [e.target.name]: e.target.value
      }
    })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSearch()
    }
  }

  clearSearch() {
    const {history} = this.props
    history.push({search: ''})
    this.loadDisbursementRegisters('/v1/journals/search')
    this.setState({searchSaleRegister: {starts_on_from: '', branch_id: '', is_posted: '', starts_on_to: ''}})
    this.setState({branches: []})
    this.setState({branchName: {}})
    this.setState({urlPrint: ''})
  }

  handlePageChange(event, value) {
    axios({
      method: 'get',
      url: '/v1/saleRegisters/search' + '?page=' + value,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({saleRegisters: resp.data.saleRegisters.journals})
      this.setState({currentPage: value})
    })

    const {history} = this.props
    const params = new URLSearchParams()
    params.append("page", value)
    history.push({search: params.toString()})
  }

  handleDisbursementSearch(event, values) {
    if (values !== null) {
      this.setState({
        searchSaleRegister:
          {
            ...this.state.searchSaleRegister,
            branch_id: values
          }
      })
    }
  }

  grandDebit() {
    var totalDebit = 0
    if (this.state.searchJournals.length > 0) {
      this.state.searchJournals.map((content) => {
        content.journal_entries.map((debit) => {
          totalDebit = parseFloat(totalDebit) + parseFloat(debit.amount_debit)
        })
      })
    }
    return totalDebit
  }

  grandCredit() {
    var totalCredit = 0
    if (this.state.searchJournals.length > 0) {
      this.state.searchJournals.map((content) => {
        content.journal_entries.map((debit) => {
          totalCredit = parseFloat(totalCredit) + parseFloat(debit.amount_credit)
        })
      })
    }
    return totalCredit
  }

  changePrintLayout() {
    return new Promise((resolve) => {
        this.setState({ forPrint: !this.state.forPrint }, () => resolve());
      });     
  }

  render() {
    const printPdf = <ReactToPrint
      trigger={() => {
          return <IconButton color="primary"><PrintIcon /></IconButton>
      }}
      onBeforeGetContent={this.changePrintLayout}
      onAfterPrint={this.changePrintLayout}
      content={() => this.componentRef}
     />
    return (
      <>
        {
          this.state.load ? (
            <div>
              <SaleRegisterSearchForm
                clearSearch={this.clearSearch}
                item={this.state.searchSaleRegister}
                branches={this.state.branches}
                search={this.handleSearch}
                handleChange={this.handleSearchInputChange}
                handleDisbursementSearch={this.handleDisbursementSearch}
                handleKeyPress={this.handleKeyPress}
              />
              <div ref={el => (this.componentRef = el)} >
                <SimpleTable
                  items={this.state.searchJournals}
                  journalEntries={this.state.journalEntries}
                  withPrint={this.state.withPrint}
                  model={this.state.model}
                  headers={this.state.tableHead}
                  icon={this.state.forPrint? null:<PaymentIcon fontSize="large" />}
                  currentPage={this.state.currentPage}
                  totalPages={this.state.totalPages}
                  urlPrint={this.state.urlPrint}
                  handlePageChange={this.handlePageChange}
                  headerTitle="Sale Register"
                  printPdf={printPdf}
                  forPrint={this.state.forPrint}
                />
                {this.state.searchJournals.length > 0 ? (
                  <TableRow className="fixWidth">
                    <TableCell className={styles.cellNone}></TableCell>
                    <TableCell className={styles.grandTotal}>Grand Total</TableCell>
                    <TableCell
                      className={this.state.forPrint? styles.grandTotalTable:styles.grandDebit}>{this.grandDebit().toLocaleString(navigator.language, {minimumFractionDigits: 2})}</TableCell>
                    <TableCell
                       className={this.state.forPrint? styles.grandTotalTable:styles.grandCredit}>{this.grandCredit().toLocaleString(navigator.language, {minimumFractionDigits: 2})}</TableCell>
                  </TableRow>
                ) : null}
              </div>
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default withRouter(SaleRegister)

SaleRegister.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
