// eslint-disable-next-line


import React from 'react'
import PropTypes from 'prop-types'
import SimpleTable from '../../SimpleTable/SimpleTable'
import SimplePopUp from '../../SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../SimpleDeletePopUp/SimpleDeletePopUp'
import BanksForm from './BanksForm'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import axios from 'axios'
import AlertMessage from "../../Notify/AlertMessage";
import ProgressBar from "../../ProgressBar/ProgressBar";
import {withRouter} from 'react-router-dom'
import * as CommonFunction from "../../Custom/CommonFunctions"

export const Component = withRouter(() => {
})
import BanksSearch from './BanksSearch'

class Banks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Bank',
      banks: [],
      accounts: [],
      openPopup: false,
      openDeletePopup: false,
      bank: {},
      initialBanks: {
        id: '',
        bank_account_number: '',
        name: '',
        address: '',
        account_id: '',
      },
      title: '',
      searchParams: {},
      withResultsLabel: false,
      withPagination: true,
      load: false,
      tableHead: ['name', 'bank_account_number', 'address', 'account.name', 'account.code'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      totalResults: 0,
      perPage: '20',
      searchUrl: '/v1/banks/search',
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.handleBankSearch = this.handleBankSearch.bind(this)
    this.handlePerPageChange = this.handlePerPageChange.bind(this)
    this.loadBanks = this.loadBanks.bind(this)
  }

  componentDidMount() {
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = {}
    const params = new URLSearchParams(this.props.location.search)
    // This is to load all params based on URLSearchParams
    params.forEach(function(value, key) {
      if (key !== 'page' && key !== 'per_page') {
        if(key === 'name') {
          searchParams[key] = value.replace(/\*/g,'')
        }else {
          searchParams[key] = value
        }
      }
    })
    if(params.get('per_page')) {
      this.setState({perPage: params.get('per_page')})
    }
    
    this.setState({ searchParams: searchParams })
    this.loadBanks(searchUrl)
  }

  loadBanks(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({banks: resp.data.banks, totalPages: resp.data.meta.total_pages, currentPage: resp.data.meta.current_page, totalResults: resp.data.meta.total_count, withResultsLabel: true })
      axios({
        method: 'get',
        url:  '/v1/accounts/search?per_page=100000',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({accounts: resp.data.accounts})
        this.setState({load: true})
      }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleSubmit() {
    const item = this.state.bank
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = '/v1/banks/'
    } else {
      method = 'put'
      url = '/v1/banks/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      const newData = resp.data
      this.setState({ banks: this.state.banks.filter(bank => bank.id !== item.id) })
      this.setState({banks: [...this.state.banks, newData]})
      this.setState({openPopup: false,isOpen: true, message: 'Submitted Successfully', type: 'success'})
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({error_messages: 'Name ' + error.response.data.errors.name[0]})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.bank.id
    axios({
      method: 'delete',
      url: '/v1/banks/' + DeleteItemId,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        banks: this.state.banks.filter(item => item.id !== DeleteItemId)
      })
      this.setState({openDeletePopup: false, isOpen: true, message: 'Deleted Successfully', type: 'error' })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleClose() {
    this.setState({openPopup: false, openDeletePopup: false, error_messages: ''})
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({updateAccount: item.account, openPopup: true, bank: item, title: textTitle})
  }

  handleInputChange(e) {
    if (e.target.name === "name") {
      this.setState({
        bank: {
          ...this.state.bank,
          name: e.target.value,
          name_type: this.state.bank.name + '(' + this.state.bank.type + ')'
        }
      })
    } else {
      this.setState({
        bank: { ...this.state.bank, [e.target.name]: e.target.value } })
    }
  }

  handleOpenDeletePopup(item, model) {
    this.setState({model: model, bank: item, openDeletePopup: true})
  }

  handleDeleteClose() {
    this.setState({openDeletePopup: false})
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  getSearchParams() {
    var searchParams = []
    // This is to load all params based on searchParams state.
    Object.entries(this.state.searchParams).map(([key, value]) => {
      if (value != '' && value != undefined && value != null) {
        if(key === "account_id") {
          searchParams.push([key, value.id].join("="))
        }else {
          searchParams.push([key, value].join("="))
        }
      }
    })

    searchParams.push(["per_page", this.state.perPage].join("="))
    return searchParams
  }

  handleSearch() {
    const { history } = this.props
    var searchParams = this.getSearchParams().join("&")
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.loadBanks([this.state.searchUrl, searchParams].join("?"))
  }

  handleSearchInputChange(e) {
    this.setState({ searchParams: {  ...this.state.searchParams, [e.target.name]: e.target.value } })
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSearch()
    }
  }

  clearSearch() {
    const { history } = this.props
    history.push({ search: 'per_page='+ this.state.perPage })
    this.setState({ withResultsLabel: false})
    this.loadBanks(this.state.searchUrl+ '?per_page='+this.state.perPage)
    this.setState({searchParams: {name: '', address: '', bank_account_number: '', account_id: ''}})
  }

  handlePageChange(event, value) {
    var searchParams = this.getSearchParams()
    searchParams.push(["page", value].join("="))
    searchParams = searchParams.join("&")

    const { history } = this.props
    const params = new URLSearchParams()

    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ currentPage: value })
    this.loadBanks([this.state.searchUrl, searchParams].join("?"))
  }

  handleAutocomplete(event, values) {
    if (values !== null) {
      this.setState({ updateAccount: values, bank: {   ...this.state.bank, account_id: values.id} })
    }
  }

  handleBankSearch(event, values) {
    if (values !== null) {
      this.setState({searchParams: { ...this.state.searchParams, account_id: values } })
    }
  }

  handlePerPageChange(e){
    var searchParams = this.getSearchParams()
    searchParams.map((value, index) => {
      if(value.includes("per_page")) {
        searchParams.splice(index, 1)
      }
    })
    searchParams.push(["per_page", e.target.value].join("="))
    searchParams = searchParams.join("&")

    const { history } = this.props
    const params = new URLSearchParams()

    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ perPage: e.target.value })
    this.loadBanks([this.state.searchUrl, searchParams].join("?"))
  }

  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>

              <BanksSearch
                clearSearch={this.clearSearch}
                item={this.state.searchParams}
                search={this.handleSearch}
                accounts={this.state.accounts}
                handleChange={this.handleSearchInputChange}
                handleBankSearch={this.handleBankSearch}
                handleKeyPress={this.handleKeyPress}
              />

              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialBanks}
                items={this.state.banks}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                totalPages={this.state.totalPages}
                currentPage={this.state.currentPage}
                withPagination={this.state.withPagination}
                handlePageChange={this.handlePageChange}
                withResultsLabel={this.state.withResultsLabel}
                totalResults={this.state.totalResults}
                perPage={this.state.perPage}
                handlePerPageChange={this.handlePerPageChange}
                icon={<AccountBalanceOutlinedIcon fontSize="large"/>}
                headerTitle="Banks"
                createUsingSimplePopup={true}
                hasDeleteButton={true}
              />

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <BanksForm
                  error={this.state.error_messages}
                  updateAccount={this.state.updateAccount}
                  item={this.state.bank}
                  accounts={this.state.accounts}
                  banks={this.state.banks}
                  handleAutocomplete={this.handleAutocomplete}
                  submit={this.handleSubmit}
                  onchange={this.handleInputChange}/>
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.bank}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default withRouter(Banks)
Banks.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
