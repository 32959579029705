import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment';
import 'moment-timezone';
import styles from './SimpleTable.module.css'
import {Link} from 'react-router-dom'
import {Redirect} from 'react-router'
import { MenuItem } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import VisibilityIcon from '@material-ui/icons/Visibility'
import TableContainer from "@material-ui/core/TableContainer"
import {Button, TextField, Tooltip} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'
import Pagination from '@material-ui/lab/Pagination'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CompanyHeader from '../CompanyHeader/CompanyHeader'

class SimpleTable extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      pathItem: {}
    }
  }

  getTotalDebit(item) {
    var totalDebit = 0
    if (item.journal_entries.length > 0) {
      item.journal_entries.map((content) => {
        totalDebit = parseFloat(totalDebit) + parseFloat(content.amount_debit)
      })
    }
    return totalDebit
  }

  getTotalCredit(item) {
    var totalCredit = 0
    if (item.journal_entries.length > 0) {
      item.journal_entries.map((content) => {
        totalCredit = parseFloat(totalCredit) + parseFloat(content.amount_credit)
      })
    }
    return totalCredit
  }

  redirectTo(item) {
    this.setState({redirect: true})
    this.setState({pathItem: item})
  }

  setKeyValue(header, idx) {
    return header.replace(' ', '_').replace('.', '_').toLowerCase() + "_" + idx.toString()
  }

  render() {
    if (this.state.redirect) {
      if (this.state.pathItem !== null) {
        return (<Redirect
          to={this.props.clickableTableRow ? (
            {
              pathname: this.props.defaultModelRoute.replace(' ', '_').toLowerCase() + this.state.pathItem.id,
              state: {
                id: this.state.pathItem.id,
                item: this.state.pathItem
              }
            }) : ('#')
          }
        />)
      }
    }

    const pagecount = ['10','20','30','40','50','60','70','80','90','100']
    return (
      <div>
        <CompanyHeader forPrint={this.props.forPrint}/>
        <>
          {
              this.props.withResultsLabel?(
            <div className={styles.perPage} >
              <div>Displaying</div>
              <TextField size="small"
                variant="outlined"
                value={this.props.perPage}
                select onChange={this.props.handlePerPageChange}
                className={styles.perPageSelect}>
              {
                pagecount.map((count,idx)=>(
                  <MenuItem value={count} key={idx}>{count}</MenuItem>
                ))
              }
              </TextField>
              <div>records</div>
            </div>
            ):null
          }
          {
          this.props.noHeader? null:
          <div className={styles.tableTitle}>
            <div className={styles.groupTileSearch}>
              <div className={styles.tileBody}>
                {this.props.noLabel? null: this.props.icon}
                <h2 className={styles.title}>
                  <div className={styles.head}>
                    <div className={this.props.noLabel? styles.noDisplay: null}>{this.props.headerTitle}</div>
                  </div>
                </h2>
              </div>
              {this.props.withSearch ? (
                <div>
                  <div className={styles.searchBody}>
                    <div className={styles.searchList}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label={<div className={styles.searchBar}><SearchIcon/>
                          <div>Search</div>
                        </div>}
                        size="small"
                        value={this.props.q}
                        className={styles.searchField}
                        onKeyPress={this.props._handleKeyPress}
                        onChange={this.props.handleChanges}
                        name="global_search"
                      >
                      </TextField>
                      {
                        this.props.model.toLowerCase() !== "document" ? (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={styles.advancedSearch}
                            onClick={this.props.handleAdvanced}
                          >
                            Advanced Search
                          </Button>
                        ) : null
                      }

                      <IconButton
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={this.props.clearSearch}
                      >
                        <SettingsBackupRestoreIcon/>
                      </IconButton>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className={styles.rightAction}>
              { 
                this.props.withClientImport ? (
                  <div className={styles.actionButton}>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      onClick={this.props.handleClientImport}
                    >
                      Import Client File
                    </Button>
                  </div>
                ) : null
              }
              {
                this.props.withPagination && this.props.items.length > 0 ? (
                  this.props.totalPages !== 1 ? (
                    <Pagination
                      className={styles.paginationWidth}
                      size="small"
                      count={this.props.totalPages}
                      page={this.props.currentPage}
                      color="primary"
                      onChange={this.props.handlePageChange}
                      showFirstButton
                      showLastButton
                    />
                  ) : null
                ) : null
              }

              {this.props.withPrint ? (
                <div className={styles.buttonDisbursement}>
                  {this.props.urlPrint.reports_url !== undefined ? (
                    <Tooltip title="Print Sale register" arrow>
                      <div className={this.props.forPrint ? styles.noDisplay : null}>
                        {this.props.printPdf}
                      </div>
                    </Tooltip>
                  ) : null}
                  {this.props.urlPrint.export_excel_url !== undefined ? (
                    <Tooltip title="Download excel File" arrow className={this.props.forPrint? styles.noDisplay:null}>
                      <a href={`${this.props.urlPrint.export_excel_url}`} target="_blank"
                         rel="noopener noreferrer">
                        <CloudDownloadIcon/>
                      </a>
                    </Tooltip>
                  ) : null}
                </div>
              ) : null}
              {
                this.props.withXlsxDownload && !this.props.forPrint
                && this.props.items.length ?
                <Tooltip 
                  title="Download to .xlsx">
                  <IconButton
                    color="primary" 
                    aria-label='download'
                    style={{ pointerEvents: 'auto' }}
                    onClick={this.props.handleXlsxDownloadClick}
                  >
                    <CloudDownloadIcon fontSize='small'/>
                  </IconButton>
                </Tooltip> :
                null
              }
              {
                this.props.model.toLowerCase() !== 'sale register' && this.props.model.toLowerCase() !== 'collection register' && this.props.model.toLowerCase() !== 'journal register' ? (
                  this.props.createUsingSimplePopup ? (
                    <Tooltip title={`Add ${this.props.model}`} arrow>
                      <IconButton color="inherit" aria-label="menu"
                                  id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_add`}
                                  name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_add`}
                                  onClick={() => this.props.handleClick(this.props.initialItem, true, this.props.model)}>
                        <AddCircleOutlineIcon fontSize={this.props.insideTab? "medium" : "large"}/>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title={`Add ${this.props.model}`} arrow>
                      <Link
                        to={{pathname: this.props.defaultNewRoutesPath, state: {item: this.props.initialItem || {},} }}
                        name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_add`}>
                        <IconButton size="large"><AddCircleOutlineIcon fontSize={this.props.insideTab? "medium" : "large"}/></IconButton>
                      </Link>
                    </Tooltip>
                  )
                ) : null}
            </div>
          </div>
          }
           <div className={styles.resultsLabel}>
            {
              this.props.withResultsLabel ? (
                this.props.totalResults ? (
                    this.props.totalResults > 1 ?
                    ('Displaying '+((parseInt(this.props.currentPage)*parseInt(this.props.perPage))-(parseFloat(this.props.perPage)-1)).toString()+'-'+((parseInt(this.props.currentPage)*parseInt(this.props.perPage))-(parseInt(this.props.perPage)-1) +(this.props.items.length-1) ).toString() +' of '+this.props.totalResults + ' in total') :
                      (this.props.totalResults + ' result')
                  ) :
                  (this.props.totalResults === 0 ? ('No Results found') : null)
              ) : null
            }
          </div>
          <TableContainer className={this.props.forPrint? styles.tableBodyHeight:this.props.customClassName} style={{maxHeight: '575px', position: 'relative'}}>
            <Table size="small" stickyHeader aria-label="customized sticky table"
                 className={styles.table}  name={this.props.model.toLowerCase().replace(/ /g, '_') + "_table"}>
              <TableHead className={styles.tableHeadBody}>
                <TableRow>
                  {
                    this.props.headers.length > 0 && this.props.items.length > 0 ? (
                      this.props.headers.map((header) => (
                        header.toLowerCase() === 'actions' ? (
                          <TableCell key={header} className={styles.actions}
                                     align="center"><strong>{header}</strong></TableCell>
                        ) : (header.toLowerCase() === 'post_status' ? (
                            <TableCell key={header} className={styles.actions}
                                       align="center"><strong>Status</strong></TableCell>
                          ) : (header.toLowerCase() === 'amount' ? (
                              <TableCell key={header} className={styles.actions}
                                         align="right"><strong>Amount</strong></TableCell>) : (
                              header.includes('.') ? (
                                  header === 'bank.name' ? (
                                    <TableCell key={header}
                                               className={styles.cell}><strong>Bank</strong></TableCell>
                                  ) : (
                                    header === 'product_type.name' ? (
                                      <TableCell key={header}
                                                 className={styles.cell}><strong>Type</strong></TableCell>
                                    ) : (
                                      header === 'department.name' ? (
                                        <TableCell key={header}
                                                   className={styles.cell}><strong>Name</strong></TableCell>
                                      ) : (
                                        header === 'department.short_name' ? (
                                        <TableCell key={header}
                                                   className={styles.cell}><strong>Short Name</strong></TableCell>
                                      ) : (
                                      this.props.model.toLowerCase() === 'product' && header === 'account.name' ? (
                                        <TableCell key={header}
                                                   className={styles.cell}><strong>Sales Account</strong></TableCell>
                                      ) : (
                                        header === 'payee.name' ? (
                                          <TableCell key={header}
                                                     className={styles.cell}><strong>Payee Name</strong></TableCell>
                                        ) : (
                                          header.toLowerCase().includes("payment_term") ? (
                                            <TableCell key={header}
                                                     className={styles.cell}><strong>Terms of Payment</strong></TableCell>
                                          ) : (
                                            header.toLowerCase().includes("deliverer") ? (
                                              <TableCell key={header}
                                                     className={styles.cell}><strong>Delivered By</strong></TableCell>
                                            ) : (
                                              header.toLowerCase().includes("creator") ? (
                                                <TableCell key={header}
                                                     className={styles.cell}><strong>Prepared By</strong></TableCell>
                                              ) : (
                                                <TableCell key={header}
                                                     className={styles.cell}><strong>{header.split('.')[0].replace('_', ' ').replace('.', ' ')}</strong></TableCell>
                                              )
                                            )
                                          )
                                          )
                                        )

                                        )
                                      )
                                    )
                                  )
                                )
                                : (
                                  header === 'prefix' ? (
                                    <TableCell key={header}
                                               className={styles.cell}><strong>Voucher No.</strong>
                                    </TableCell>
                                  ) : (
                                    header.toLowerCase() === "tax_exempt" ? (
                                      <TableCell key={header}
                                                 className={styles.cell}><strong>Tax</strong>
                                      </TableCell>
                                    ) : (
                                      header === 'remarks' ? (
                                        <TableCell key={header}
                                                   className={styles.cell}><strong>Particulars</strong>
                                        </TableCell>
                                      ) : (
                                        header === 'creator_name' ? (
                                        <TableCell key={header}
                                                   className={styles.cell}><strong>Prepared By</strong>
                                        </TableCell>
                                      ) : (
                                        header === 'total_receipt' ? (
                                        <TableCell key={header}
                                                   className={styles.cell}><strong>Payment</strong>
                                        </TableCell>
                                      ) : (
                                        header === 'journal_entries' ? (
                                          <TableCell key={header} className={styles.customHeader}>
                                            <TableCell className={styles.accounCode}>
                                              Account Number
                                            </TableCell>
                                            <TableCell className={styles.accounName}>
                                              Account Code
                                            </TableCell>
                                            <TableCell className={styles.debit}>
                                              Debit
                                            </TableCell>
                                            <TableCell className={styles.credit}>
                                              Credit
                                            </TableCell>
                                          </TableCell>
                                        ) : (
                                          header.toLowerCase().includes("total") ? (
                                            <TableCell key={header}
                                                        className={header.toLowerCase() !== 'total_amount' ? styles.Width30 : styles.cell} align="center"><b>Amount</b>
                                            </TableCell>
                                          ) : (
                                            header.toLowerCase().includes("date") ? (
                                              <TableCell key={header}
                                                         className={`${styles.cell} ${styles.Width10} `}><b>Date</b>
                                              </TableCell>
                                            ) : (
                                              header.toLowerCase() === "reference_no" && this.props.model === 'Collection Receipt' ? (
                                              <TableCell key={header}
                                                         className={`${styles.cell} ${styles.Width20}`}><b>Receipt Number</b>
                                              </TableCell>
                                            ) : (
                                              header.toLowerCase() === "reference_no" && this.props.model === 'Delivery Receipt' ? (
                                              <TableCell key={header}
                                                         className={`${styles.cell} `}><b>Delivery Number</b>
                                              </TableCell>
                                            ) : (
                                              header.toLowerCase() === "description" && this.props.model === 'Delivery Receipt' ? (
                                              <TableCell key={header}
                                                         className={`${styles.cell} `}><b>Invoice/<span className={styles.withS}>s </span></b>
                                              </TableCell>
                                            ) : (
                                              header === "Employee name" ? (
                                                <TableCell key={header}
                                                           className={styles.cell}><b>User</b>
                                                </TableCell>
                                              ) : (
                                                header === "logged_at" ? (
                                                  <TableCell key={header}
                                                      align='center' className={styles.cell}><b>Date Log</b>
                                                  </TableCell>
                                                ) : (
                                                <TableCell key={header}
                                                            className={`${styles.cell}`}><b>{header.replace('_', ' ').replace('_', ' ')}</b>
                                                </TableCell>
                                                )
                                              )
                                            )
                                          )
                                          )
                                        )
                                        )
                                        )
                                        )
                                        )
                                      )
                                    )
                                  )
                                )
                            )
                          )
                        ))
                      )
                    ) : (
                      <TableCell className={styles.actions} align="center"><strong>No Results Found</strong></TableCell>
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody key="simple-table-body" className={styles.tableBody}>
                {
                  this.props.items.map((item, idx) => (
                    <React.Fragment key={idx}>
                      <>
                        <TableRow key={this.setKeyValue("table_body_row", idx)} onClick={this.props.clickableTableRow ?() => this.redirectTo(item) : null}
                                  className={this.props.clickableTableRow ? styles.linkable : styles.notLinkable}>
                          {
                            this.props.headers.length > 0 ? (
                              this.props.headers.map((header) => (
                                  header.includes(".") ? (
                                    <TableCell key={this.setKeyValue(header, idx)} className={styles.cell}>
                                      {item[header.split(".")[0]] ? (item[header.split(".")[0]][header.split(".")[1]]) : ("n/a")}
                                    </TableCell>
                                  ) : (
                                    header.toLowerCase() === "total" || header.toLowerCase() === "total_amount" || header.toLowerCase() === "total_receipt" ? (
                                      <TableCell key={this.setKeyValue(header, idx)} className={styles.amount}>
                                        {item[header.toLowerCase()] ? (parseFloat(item[header.toLowerCase()])).toLocaleString(navigator.language, {minimumFractionDigits: 2}) : ("0.0")}
                                      </TableCell>
                                    ) : (
                                      header.toLowerCase() === "amount" ? (
                                        <TableCell key={this.setKeyValue(header, idx)} className={styles.amount}>
                                          {item[header.toLowerCase()] ? (parseFloat(item[header.toLowerCase()])).toLocaleString(navigator.language, {minimumFractionDigits: 2}) : ("0.0")}
                                        </TableCell>
                                      ) : (
                                        header.toLowerCase().includes("date") ? (
                                          <TableCell key={this.setKeyValue(header, idx)} className={styles.cell}>
                                            {  item[header.toLowerCase()] ? ( moment(item[header.toLowerCase()]).format("MM/DD/yyyy")) : ("n/a")}
                                          </TableCell>
                                        ) : (
                                          header.toLowerCase() === "prefix" ? (
                                            <TableCell key={this.setKeyValue(header, idx)} className={styles.cell}>
                                              {item.prefix || item.postfix ? (item.prefix) + (item.postfix) : (null)}
                                            </TableCell>
                                          ) : (
                                            header.toLowerCase() === "remarks" ? (
                                              <TableCell key={this.setKeyValue(header, idx)} className={styles.remarksEllipsis}>
                                                {item[header.toLowerCase()] ? (item[header.toLowerCase()].replace('_', ' ')) : ("n/a")}
                                              </TableCell>
                                            ) : (
                                              header.toLowerCase() === "description" && this.props.model === 'Delivery Receipt' ? (
                                              <TableCell key={header} className={styles.cell}>
                                                {item.invoice_nos[0]? item.invoice_nos[0] : 'n/a'}
                                              </TableCell>
                                            ) : (
                                              header.toLowerCase() === "tax_exempt" ? (
                                                <TableCell key={this.setKeyValue(header, idx)} className={styles.remarksEllipsis}>
                                                  {item.tax_exempt === true ? ("Exempted") : ("Not Exempted")}
                                                </TableCell>
                                              ) : (
                                                header.toLowerCase() === "reference_no" ? (
                                                  this.props.model === "Journal" ? (
                                                    <TableCell key={header} className={styles.linkable}>
                                                      JV{item.branch_name[0]}{item[header.toLowerCase()] ? (item[header.toLowerCase()].replace('_', ' ')) : ("n/a")}
                                                    </TableCell>
                                                  ) : (
                                                  <TableCell key={this.setKeyValue(header, idx)} className={styles.linkable}>
                                                    {
                                                      this.props.clickableTableRow ? (item.reference_no) : (
                                                        <a key={this.setKeyValue("reference_no_link", idx)} href={`/invoices/${item.resource_id}`} target="_blank"
                                                           rel="noopener noreferrer">
                                                          {item[header.toLowerCase()] ? (item[header.toLowerCase()].replace('_', ' ')) : ("n/a")}
                                                        </a>
                                                      )
                                                    }
                                                  </TableCell>)
                                                ) : (
                                                  header === "journal_entries" ? (
                                                    <Table key="journal_entries_table" size="small" className={styles.customRowBody}>
                                                      {item.journal_entries.length > 0 ? (
                                                        <>
                                                          {item.journal_entries.map((entry, index) => (
                                                            <TableRow key={this.setKeyValue("account_code", index)} className={styles.customRow}>
                                                              <TableCell key={this.setKeyValue("account_code", index)} className={styles.accounCode}>
                                                                {entry.account.code}
                                                              </TableCell>
                                                              <TableCell key={this.setKeyValue("account_name", index)} className={styles.accounName}>
                                                                {entry.account.name}
                                                              </TableCell>
                                                              <TableCell key={this.setKeyValue("debit", index)} className={styles.debit}>
                                                                {parseFloat(entry.amount_debit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                                                              </TableCell>
                                                              <TableCell key={this.setKeyValue("credit", index)} className={styles.credit}>
                                                                {parseFloat(entry.amount_credit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                                                              </TableCell>
                                                            </TableRow>
                                                          ))}
                                                          <TableRow>
                                                            <TableCell key="no-value">
                                                            </TableCell>
                                                            <TableCell key="total" align="right">
                                                              <b key="total-text">Total</b>
                                                            </TableCell>
                                                            <TableCell key="total-debit" align="right" className={styles.debit}>
                                                              <b key="total-debit-text">{this.getTotalDebit(item).toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b>
                                                            </TableCell>
                                                            <TableCell key="total-credit" align="right" className={styles.credit}>
                                                              <b key="total-credit-text">{this.getTotalCredit(item).toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b>
                                                            </TableCell>
                                                          </TableRow>
                                                        </>
                                                      ) : null}
                                                    </Table>
                                                  ) : (header.toLowerCase() === "type" ? (
                                                    item.type.includes("Asset") ? (
                                                        <TableCell key={header} className={styles.remarksEllipsis}>
                                                          Asset
                                                        </TableCell>
                                                      ) : (
                                                        item.type.includes("Expense") ? (
                                                          <TableCell key={header} className={styles.remarksEllipsis}>
                                                            Expense
                                                          </TableCell>
                                                        ) : ( item.type.includes("Liability") ? (
                                                            <TableCell key={header} className={styles.remarksEllipsis}>
                                                              Liability
                                                            </TableCell>
                                                          ):
                                                          (item.type.includes("Revenue") ? (
                                                            <TableCell key={header} className={styles.remarksEllipsis}>
                                                              Revenue
                                                            </TableCell>
                                                          ):(item.type.includes("Capital") ? (
                                                            <TableCell key={header} className={styles.remarksEllipsis}>
                                                              Capital
                                                            </TableCell>
                                                          ): null))))
                                                    ) : (
                                                      header === "Employee name" ? (
                                                        item.employee_name.length > 0 ? (
                                                          <TableCell key={header} className={styles.employeeNameWidth}>
                                                            {item.employee_name.map((name) => (
                                                                <li key={name.id}> {name.name_formal}</li>
                                                              )
                                                            )}
                                                          </TableCell>
                                                        ):<TableCell> <li>n/a</li></TableCell>
                                                      ) : (
                                                      header.toLowerCase() === "is_posted" ? (
                                                        <TableCell key={header} className={styles.cell}>
                                                          {item[header.toLowerCase()] ? ("Posted") : ("Not posted")}
                                                        </TableCell>

                                                      ) : (
                                                        header.toLowerCase() === "logged_at" ? (
                                                          <TableCell key={header} className={styles.cell} align='center'>{moment(item[header.toLowerCase()]).format('lll')}</TableCell>
                                                        ) : (
                                                      <TableCell key={this.setKeyValue(header, idx)}
                                                                 className={styles.cell}>
                                                        {item[header.toLowerCase()] ? (item[header.toLowerCase()].replaceAll('_', ' ')) : ("n/a")}
                                                      </TableCell>
                                                    )
                                                  )
                                                      )
                                                )
                                                )
                                                )
                                              )
                                              )
                                            )
                                          )
                                        )
                                      )
                                    )
                                  )
                                )
                              )
                            ) : null
                          }
                          <TableCell key={this.setKeyValue("action_buttons", item.id)} size="small"  className={this.props.model.toLowerCase() !== 'classification' && this.props.model.toLowerCase() !== 'product' && this.props.model.toLowerCase() !== 'term of payment'
                          && this.props.model.toLowerCase() !== 'bank' && this.props.model.toLowerCase() !== 'account' && this.props.model.toLowerCase() !== 'branche'  ? styles.actionButtonHoverable : styles.fullWitdActionHoverable}>
                            {
                              this.props.withShowPopUp ? (
                                <Tooltip title={`Show ${this.props.model? this.props.model: null}`} arrow>
                                  <IconButton
                                    key={this.setKeyValue("show_popup_button", idx)}
                                    id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_showpopup`}
                                    name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_showpopup`}
                                    size="small"
                                    color="primary"
                                    onClick={() => this.props.handleShowPopUp(item)}>
                                    <VisibilityIcon color="primary" fontSize="small"/>
                                  </IconButton>
                                </Tooltip>
                              ) : (null)
                            }
                            {item.can_delete === true ? (
                              this.props.hasDeleteButton  ? (
                                <Tooltip title="Delete" arrow>
                                  <IconButton
                                    key={this.setKeyValue("delete_button", idx)}
                                    id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_delete`}
                                    name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_delete`}
                                    size="small"
                                    color="secondary"
                                    onClick={() => this.props.onOpenDeletePopup(item, this.props.model)}
                                  >
                                    <DeleteIcon/>
                                  </IconButton>
                                </Tooltip>
                              ) : null
                            ) : null}
                            {item.can_edit === true ? (
                              this.props.withEdit ? (
                                <Tooltip title="Edit" arrow>
                                  <IconButton
                                    size="small"
                                    key={this.setKeyValue("edit_button", idx)}
                                    id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_edit`}
                                    name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_edit`}
                                    color="primary"
                                    onClick={() => this.props.handleClick(item, false, this.props.model)}>
                                    <EditIcon/>
                                  </IconButton>
                                </Tooltip>
                              ) : (null)) : null
                            }
                          </TableCell>
                        </TableRow>
                      </>
                    </React.Fragment>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </>
      </div>

    )
  }
}

export default SimpleTable

SimpleTable.propTypes = {
  model: PropTypes.string.isRequired,
  headers: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  journalEntries: PropTypes.array,
  onOpenDeletePopup: PropTypes.func,
  handleClick: PropTypes.func,
  initialItem: PropTypes.object,
  item: PropTypes.object,
  withShow: PropTypes.bool,
  icon: PropTypes.object,
  withEdit: PropTypes.bool,
  withShowPopUp: PropTypes.bool,
  withSearch: PropTypes.bool,
  customClassName: PropTypes.string,
  handleShowPopUp: PropTypes.func,
  handleAdvanced: PropTypes.func,
  handleChanges: PropTypes.func,
  _handleKeyPress: PropTypes.func,
  clearSearch: PropTypes.func,
  q: PropTypes.string,
  prevPath: PropTypes.string,
  withPagination: PropTypes.bool,
  handlePageClick: PropTypes.func,
  pageCount: PropTypes.number,
  displayedPages: PropTypes.number,
  displayedRange: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  changeIndex: PropTypes.func,
  showState: PropTypes.bool,
  withStateButton: PropTypes.bool,
  withCalendar: PropTypes.bool,
  handlePageChange: PropTypes.func,
  toggleView: PropTypes.func,
  checkVoucherPayee: PropTypes.bool,
  withPrint: PropTypes.bool,
  urlPrint: PropTypes.string,
  currentPage: PropTypes.number,
  activePage: PropTypes.number,
  totalResults: PropTypes.number,
  withResultsLabel: PropTypes.bool,
  payeeId: PropTypes.number,
  clickableTableRow: PropTypes.bool,
  headerTitle: PropTypes.string,
  defaultNewRoutesPath: PropTypes.string,
  defaultModelRoute: PropTypes.string,
  createUsingSimplePopup: PropTypes.bool,
  hasDeleteButton: PropTypes.bool,
  insideTab: PropTypes.bool,
  newThruClient: PropTypes.bool,
  noHeader: PropTypes.bool,
  handlePerPageChange: PropTypes.func,
  printPdf: PropTypes.object,
  perPage: PropTypes.string,
  forPrint: PropTypes.bool,
  noLabel: PropTypes.bool,
  withXlsxDownload: PropTypes.bool,
  handleXlsxDownloadClick: PropTypes.func,
  withClientImport: PropTypes.bool,
  handleClientImport: PropTypes.func,
}
