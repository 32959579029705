import React from 'react'
import PropTypes from 'prop-types'
import { TextField, IconButton, InputLabel } from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import styles from "../Clients.module.css";
import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
class ContactNumber extends React.Component {

  render() {
    const contentId = `client_contact_number_${this.props.idx}`
    const categoryId = `client_contact_category_${this.props.idx}`
    const deleteButton = `delete_client_contact_number_${this.props.idx}`
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              autoComplete="off"
              className={styles.formTextfield}
              fullWidth
              variant="outlined"
              required
              name={contentId}
              id={contentId}
              size="small"
              data-idx={this.props.idx}
              value={this.props.contactNumbers[this.props.idx].content}
              onChange={this.props.handleNumberChange}
              label="Contact Number"
              inputProps={{ 'data-id': this.props.idx, 'data-field-type': 'content' }}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth className={styles.formTextfield} size="small" variant="outlined">
              <InputLabel>Category</InputLabel>
              <Select
                native
                id={categoryId}
                fullWidth
                label="category"
                variant="outlined"
                size="small"
                data-idx={this.props.idx}
                name={categoryId}
                value={this.props.contactNumbers[this.props.idx].category || 0}
                onChange={this.props.handleNumberChange}
                inputProps={{
                  name: categoryId,
                  id: 'outlined-age-native-simple',
                  'data-id': this.props.idx,
                  'data-field-type': 'category'
                }}
              >
                <option value="" disabled selected>Select Category</option>
                <option value={0}>Primary</option>
                <option value={1}>Home Phone Number</option>
                <option value={2}>Phone Number</option>
                <option value={3}>Mobile Number</option>
                <option value={4}>Fax Number</option>
                <option value={5}>Others</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="secondary"
              name={deleteButton}
              onClick={() => this.props.removeContactNumber(this.props.idx)}>
              <DeleteForeverIcon />
            </IconButton>
          </Grid>
        </Grid>
      </>

    )
  }
}

export default ContactNumber

ContactNumber.propTypes = {
  idx: PropTypes.number,
  contactNumbers: PropTypes.array,
  handleContactNumberChange: PropTypes.func,
  removeContactNumber: PropTypes.func,
  handleNumberChange: PropTypes.func

}
